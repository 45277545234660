import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GlobalContext from 'context/globalContext';
import arrayUtility from 'utilities/arrayUtility';
import AddressRow from './AddressRow';
import styles from './chooseAddress.module.css';
import { useTranslation } from 'react-i18next';

function ChooseAddress(props) {
	const globalContext = useContext(GlobalContext);
	const [ selectedLocations, setSelectedLocations ] = useState([]);
	const [ saving, setSaving ] = useState(false);
	const [ t ] = useTranslation();


	useEffect(() => {
		const getLocations = async () => {
			globalContext.location.getAll();
			globalContext.location.getUserLocations();
		};
		getLocations();
	}, []);

	useEffect(
		() => {
			if (globalContext.location.response.userLocations) {
				var idList = globalContext.location.response.userLocations.map((location) => location.locationId);
				setSelectedLocations(idList);
			}
		},
		[ globalContext.location.response ]
	);

	if (!globalContext.location.response.locations) {
		return (
			<div className="text-center">
				<FontAwesomeIcon icon="spinner" spin />
			</div>
		);
	}

	const onSave = async () => {
		setSaving(true);
		await globalContext.location.saveLocations(selectedLocations);
		setSaving(false);
		if (props.onSaved){
			props.onSaved();
		}
	};

	const groupedLocations = arrayUtility.groupBy(globalContext.location.response.locations, 'state');

	const setSelected = (id, checked) => {
		var newSelectedLocations = [ ...selectedLocations ];
		if (checked) {
			newSelectedLocations.push(id);
		} else {
			newSelectedLocations = newSelectedLocations.filter((a) => a !== id);
		}
		setSelectedLocations(newSelectedLocations);
	};

	const renderLocations = () => {
		let rows = [];
		let index = 0;
		for (let state in groupedLocations) {
			rows.push(
				<AddressRow
					key={state}
					state={state}
					locations={groupedLocations[state]}
					onSelect={setSelected}
					selectedLocations={selectedLocations}
				/>
			);
		}
		return rows;
	};

	return (
		<div className=" mt-2">
			<h1>{t('Available Addresses')}</h1>
			<Row>
				<Alert variant="dark" className={styles.alert}>
				<b>Please note</b> - The PO Box has the fastest mail processing. Street address for courier services available by request after registration. 
				</Alert>
				<Col xs={12} className={styles.locations}>
					{renderLocations()}
				</Col>
			</Row>
			<Row className="mt-3">
				<Col xs={12}>
					<Button variant="dark" className={styles.backButton} onClick={props.onBack}>
						{t('Back')}
					</Button>
					{selectedLocations.length > 0 && (
						<Button className={styles.saveButton} onClick={onSave}>
							{saving ? <FontAwesomeIcon icon="spinner" spin /> : t('Save')}
						</Button>
					)}
				</Col>
			</Row>
		</div>
	);
}

export default ChooseAddress;
