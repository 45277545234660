import React, { useState, useContext } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import GlobalContext from 'context/globalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function ResetPassword(props) {
	const globalContext = useContext(GlobalContext);
	const [ currentPassword, setCurrentPassword ] = useState('');
	const [ password1, setPassword1 ] = useState('');
	const [ password2, setPassword2 ] = useState('');
	const [ errorMessage, setErrorMessage ] = useState('');
    const [ successMessage, setSuccessMessage] = useState('');
    const [ saving, setSaving ] = useState(false);
	const { t  } = useTranslation();

	const onConfirm = async () => {
		if (!saving) {
			setSaving(true);
			if (password1 !== password2) {
				setErrorMessage(t('Passwords do not match'));
			} else {
				var response = await globalContext.account.changePassword(currentPassword, password1);
				if (!response) {
					setErrorMessage(t('Unable to change password'));
				} else {
                    setSuccessMessage(t("Password changed"));
                    setPassword1("");
                    setPassword2("");
                    setCurrentPassword("");
                    setErrorMessage("");
                    setTimeout(() => {                        
                        setSuccessMessage("");
                        props.onClose();
                    },2000);
				}
			}
			setSaving(false);
		}
	};

	const onCancel = () => {
		props.onClose();
	};

	return (
		<Modal show={props.show}>
			<Modal.Header>
				<Modal.Title>{t('Reset Password')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form.Label>{t('Current password')}</Form.Label>
				<Form.Control
					type="password"
					value={currentPassword}
					onChange={(e) => setCurrentPassword(e.target.value)}
				/>
				<Form.Label className="mt-3">{t('New password')}</Form.Label>
				<Form.Control type="password" value={password1} onChange={(e) => setPassword1(e.target.value)} />
				<Form.Label className="mt-2">{t('New password re-entered')}</Form.Label>
				<Form.Control type="password" value={password2} onChange={(e) => setPassword2(e.target.value)} />

				{errorMessage && (
					<Alert variant="danger" className="mt-3">
						{errorMessage}
					</Alert>
				)}
				{successMessage && (
					<Alert variant="success" className="mt-3">
						{successMessage}
					</Alert>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={onConfirm}>
					{saving ? <FontAwesomeIcon icon="spinner" spin /> : t('Confirm')}
				</Button>
				<Button variant="primary" onClick={onCancel}>
					{t('Cancel')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default ResetPassword;
