import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from './emailModal.module.css';
import { useTranslation } from 'react-i18next';

function EmailModal(props) {
	const [ email, setEmail ] = useState('');
	const [ subject, setSubject ] = useState('');
	const [ body, setBody ] = useState('');
    const [ sending, setSending] = useState(false);
	const [ t ] = useTranslation();

    const onEmail = async () => {
        setSending(true);
        await props.onEmail(email, subject, body);
        setSending(false);
        setBody("");
        setSubject("");
        setEmail("");
        props.onClose();
    }

	return (
		<Modal show={props.show} size="lg">
			<Modal.Header>
				<Modal.Title>{t('Send Email')}</Modal.Title>
			</Modal.Header>
			<Modal.Body className={styles.modalBody}>
				<Form>
					<Form.Group controlId="modal-email">
						<Form.Label>{t('Email Address')}</Form.Label>
						<Form.Control
							type="email"
							placeholder={t("Enter email")}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</Form.Group>
					<Form.Group controlId="modal-subject">
						<Form.Label>{t('Subject')}</Form.Label>
						<Form.Control
							type="text"
							placeholder={t("Subject")}
							value={subject}
							onChange={(e) => setSubject(e.target.value)}
						/>
					</Form.Group>
					<Form.Group controlId="modal-subject">
						<Form.Label>{t('Body')}</Form.Label>
						<Form.Control
							as="textarea"
							placeholder={t("Body")}
							className={styles.emailBody}
							value={body}
							onChange={(e) => setBody(e.target.value)}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={onEmail}>
					{sending ? <FontAwesomeIcon icon="spinner" spin /> : t("Send")}
				</Button>
				<Button variant="danger" onClick={props.onClose}>
					{t('Cancel')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default EmailModal;
