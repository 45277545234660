import React from 'react';

const MailIcon = (props) => {

	let imgsrc = '';
	if (props.mailType === 0) {
		//"Letter"
		if (props.mailAction === 2) {
			imgsrc = `${process.env.PUBLIC_URL}/images/shred.png`;
		} 
		else if (props.mailAction === 3){
			imgsrc=  `${process.env.PUBLIC_URL}/images/returnToSender.png`;
		}
		else {
			switch (props.status) {
				case 2: 
					//"UnopenedScan:
					imgsrc = `${process.env.PUBLIC_URL}/images/envelope.png`;
					break;
				case 3: 
					//"OpenedAndScanned"
					imgsrc = `${process.env.PUBLIC_URL}/images/store.png`;
					break;
				case 4:
					//Forwarded
					imgsrc = `${process.env.PUBLIC_URL}/images/fowarded.png`;
					break;
				case 6:
					//Shredded
					imgsrc = `${process.env.PUBLIC_URL}/images/shred.png`;
					break;
				case 7:
					imgsrc=  `${process.env.PUBLIC_URL}/images/returnToSender.png`;
					break;
				default:
					imgsrc = `${process.env.PUBLIC_URL}/images/envelope.png`;

				// PendingScan,
				// PendingForward,
				// PendingShred,
				// Forwarded,
				// Shredded
			}
		}
	} else {
		switch (props.status) {
			case 2: 
				//"UnopenedScan:
				imgsrc = `${process.env.PUBLIC_URL}/images/parcel_store.png`;
				break;
			case 3: 
				//"OpenedAndScanned"
				imgsrc = `${process.env.PUBLIC_URL}/images/parcel_store.png`;
				break;
			case 4:
				//Forwarded
				imgsrc = `${process.env.PUBLIC_URL}/images/parcel_forward.png`;
				break;
			case 6:
				//Shredded
				imgsrc = `${process.env.PUBLIC_URL}/images/parcel_shred.png`;
				break;
			case 7:
				imgsrc=  `${process.env.PUBLIC_URL}/images/returnToSender.png`;
				break;
			default:
				imgsrc = `${process.env.PUBLIC_URL}/images/box.png`;
		}
	}
	return <img src={imgsrc} alt={props.status} />;
};

export default MailIcon;
