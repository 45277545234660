import {
  GET_SERVICES,
  SET_MAILIDS,
  GET_LOG,
  CANCEL_FORWARD
} from "./forwardActionTypes";

const forwardReducer = (state, action) => {
  switch (action.type) {
    case SET_MAILIDS:
      return { ...state, mailIds: action.payload };
    case GET_SERVICES:
      return {
        ...state,
        list: action.payload,
      };
    case GET_LOG:
      return { ...state, log: action.payload };
    case CANCEL_FORWARD:
      return _cancelForward(state, action);
    default:
      return state;
  }
};

const _cancelForward = (state, action) => {
  let log = { ...state.log };
  log.pending = log.pending.filter(
    a => action.payload.indexOf(a.forwardId) === -1
  );
  return { ...state, log };
};

export default forwardReducer;
