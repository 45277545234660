import { useReducer } from "react";
import alertActions from "./addressActions";
import addressReducer from "./addressReducer";

export default function useAddress() {
  const [addresses, addressDispatch] = useReducer(addressReducer, []);
  const { fetch, add, remove } = alertActions(addressDispatch)

  return {
      response: addresses,
      fetch,
      add,
      remove
  };
}
