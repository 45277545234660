import {
  GET_METRICS,
} from "./storageActionTypes";

const metricsReducer = (state, action) => {
  switch (action.type) {
    case GET_METRICS:
      return { metrics: action.payload }
    default:
      return state;
  }
};

export default metricsReducer;
