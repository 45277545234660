// import {
//   UPDATE_PAYMENT
// } from "./paymentActionTypes";

const paymentReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default paymentReducer;
