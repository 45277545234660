import {
    SET_NAV,
} from "./accountSettingsActionTypes";

const accountSettingsReducer = (state, action) => {
    switch (action.type) {
        case SET_NAV:
            return {...state, navigation: action.payload };
        default:
            return state;
    }
};

export default accountSettingsReducer;
