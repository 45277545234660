import { useReducer } from "react";
import locationActions from "./locationActions";
import locationReducer from "./locationReducer";

export default function useLocation() {
  const [location, locationDispatch] = useReducer(locationReducer, {});
  const { getAll, saveLocations, getUserLocations } = locationActions(locationDispatch);

  return {
    response: location,
    getAll,
    saveLocations,
    getUserLocations
  };
}
