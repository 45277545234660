import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfiniteScroll from 'react-infinite-scroller';
import Moment from "react-moment";
import {
    Table
} from "react-bootstrap";
import GlobalContext from 'context/globalContext';
import { useTranslation } from 'react-i18next';


const PAGE_SIZE = 20;

const Transactions = (props) => {
    const globalContext = useContext(GlobalContext);
    const [hasMore, setHasMore] = useState(true);
    const { t  } = useTranslation();

    const renderTransaction = () => {
        if (globalContext.transactions?.response?.transactions) {
            return globalContext.transactions.response?.transactions.map(transaction => (
                <tr key={transaction.transactionId}>
                    <td>
                        <Moment format="DD/MM/YYYY">{transaction.date}</Moment>
                    </td>
                    <td>
                        {transaction.description}
                    </td>
                    <td>
                        {t(transaction.planName)}
                    </td>
                    <td>
                        {transaction.amount <= 0 && transaction.amount.toFixed(2)}
                    </td>
                    <td>
                        {transaction.amount > 0 && transaction.amount.toFixed(2)}
                    </td>
                    <td>
                        {transaction.balance.toFixed(2)}
                    </td>

                </tr>
            ));
        } else {
            return (
                <tr>
                    <td colSpan="6"><FontAwesomeIcon icon="spinner" spin /></td>
                </tr>
            );
        }
    }

    const onLoadMore = (newPage) => {
        const getTransactions = async () => {
            setHasMore(false);
            var more = await globalContext.transactions.getTransactions(newPage, PAGE_SIZE);
            setHasMore(more);
        }
        getTransactions();
    }


    return (
        <div className="mt-2 mb-2">
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>{t('Date')}</th>
                        <th>{t('Description')}</th>
                        <th>{t('Plan')}</th>
                        <th>{t('Debit')}</th>
                        <th>{t('Credit')}</th>
                        <th>{t('Balance')}</th>
                    </tr>
                </thead>
                <InfiniteScroll
                    pageStart={-1}
                    loadMore={onLoadMore}
                    hasMore={hasMore}
                    element="tbody"
                    loader={<tr key="loader"><td colSpan="2">Loading...</td></tr>}
                    getScrollParent={()=> document.getElementById("setting-wrapper")}
                    useWindow={false}
                >
                    {renderTransaction()}
                </InfiniteScroll>
            </Table>
        </div>
    );
};

export default Transactions;
