import { useReducer } from "react";
import auditActions from "./auditActions";
import auditReducer from "./auditReducer";

export default function useAudit() {
    const [audit, auditDispatch] = useReducer(auditReducer, {});
    const { getAudit } = auditActions(auditDispatch);

    return {
        response: audit,
        getAudit
    };
}