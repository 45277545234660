import React, { useState, useContext, useCallback } from 'react';
import styles from './login.module.css';

function HomePage(props) {
	const onLogin = () => {
        props.history.push("/members");
    };

    const onSignup = () => {
        props.history.push("/Signup");
    };


	return (
		<div className={`${styles.wrapper} ${styles.fadeInDown} ${styles.login}`}>
			<div className={styles.formContent}>
				<div className={`${styles.fadeIn} ${styles.first}`}>
					<img src={window.webpageDetails.logo} id="icon" alt="Hotsnail Icon" className={styles.hotsnailIcon} />
				</div>

				<form>
					<button type="button" className={`${styles.fadeIn} ${styles.fourth} button-bgcolor`} onClick={onLogin}>
						Log In
					</button>
                    <button type="button" className={`${styles.fadeIn} ${styles.fourth} ${styles.signUpButton}`} onClick={onSignup}>
						Signup
					</button>
				</form>
			</div>
		</div>
	);
}

export default HomePage;
