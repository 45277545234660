import * as registrationStatus from 'enums/registrationStatus';
import {
	LOGIN,
	GET_ACCOUNT,
	CHOOSE_PLAN,
	UPDATE_LOGIN_GUARD,
	VERIFY_SMS,
	REGISTRATION_COMPLETE,
	UPDATE_AUTO_ACTION,
	UPDATE_AUTO_TOPUP,
	UPDATE_TERMS_AND_CONDITIONS,
	UPDATE_EMAIL_NOTIFICATION,
	UPDATE_INCLUDE_PDF,
	UPDATE_SEND_SEPERATE,
	UNPAUSE_ACCOUNT,
	PAUSE_ACCOUNT
} from './accountActionTypes';

const accountReducer = (state, action) => {
	switch (action.type) {
		case LOGIN:
			return { ...state, login: action.payload };
		case GET_ACCOUNT:
			return { ...state, account: action.payload };
		case UPDATE_LOGIN_GUARD:
			let account = state.account;
			account.enableLoginGuard = action.payload;
			return { ...state, account };
		case CHOOSE_PLAN:
			return _chosePlan(state, action);
		case VERIFY_SMS:
			return _verifySms(state, action);
		case REGISTRATION_COMPLETE:
			return _registrationComplete(state, action);
		case UPDATE_AUTO_ACTION:
			return _updateAutoAction(state, action);
		case UPDATE_AUTO_TOPUP:
			return _updateAutoTopUp(state, action);
		case UPDATE_TERMS_AND_CONDITIONS:
			return { ...state, account: { ...state.account, TermsAndConditionsSigned: true } };
		case UPDATE_EMAIL_NOTIFICATION:
			return { ...state, account: { ...state.account, emailActionNotification: action.payload } };
		case UPDATE_INCLUDE_PDF:
			return { ...state, account: { ...state.account, includePdf: action.payload } };
		case UPDATE_SEND_SEPERATE:
			return { ...state, account: { ...state.account, seperateAlerts: action.payload }};
		case UNPAUSE_ACCOUNT:
			return { ...state, account: { ...state.account, status: "Active"}};
		case PAUSE_ACCOUNT:
			return { ...state, account: { ...state.account, status: "On Hold"}};
		default:
			return state;
	}
};

const _updateAutoTopUp = (state, action) => {
	return { ...state, account: { ...state.account, autoTopUpAmount: action.payload } };
};

const _updateAutoAction = (state, action) => {
	let newState = {
		...state,
		account: { ...state.account, autoAction: action.payload }
	};
	return newState;
};

const _registrationComplete = (state, action) => {
	let newState = {
		...state,
		action: {
			...state.account,
			registrationStatus: registrationStatus.Registered
		}
	};
	return newState;
};

const _chosePlan = (state, action) => {
	let newState = {
		...state,
		account: { ...state.account, planId: action.payload }
	};
	return newState;
};

const _verifySms = (state, action) => {
	let newState = {
		...state,
		account: { ...state.account, smsVerified: action.payload }
	};
	return newState;
};

export default accountReducer;
