import { useReducer } from "react";
import loginActions from "./loginActions";
import loginReducer from "./loginReducer";

export default function useLogin() {
  const [login, loginDispatch] = useReducer(loginReducer, {});
  const {
    doLogin,
  } = loginActions(loginDispatch);

  return {
    response: login,
    doLogin,
  };
}
