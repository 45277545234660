import React from "react";
import PublicTopNav from "components/public/publicTopNav/PublicTopNav.jsx";
import { Container, Alert } from "react-bootstrap";

function EmailGuard(props) {
  return (
    <React.Fragment>
      <PublicTopNav history={props.history} />
      <Container>
        <Alert variant="secondary" className="mt-2">
          <p>Email Verification Required</p>
          <p>
            Your email address has not been verified, to verify your email address check your 
            inbox. If you do not find the email, also check your junk/spam folder.
          </p>
        </Alert>
      </Container>
    </React.Fragment>
  );
}

export default EmailGuard;
