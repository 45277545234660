import React, { useContext, useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Inbox from './components/members/inbox';
import Account from './components/members/accountSettings';
import PhotoVerificationWizard from './components/members/verifications/PhotoVerification';
import GlobalContext from 'context/globalContext';
import Confirm from './commonComponents/Confirm';
import Forward from './components/members/forward/';
import Support from 'components/members/support/support';
import PaymentRequired from './components/members/paymentRequired/PaymentRequired';
import LoginGuard from './components/public/LoginGuard';
import Signup from 'components/registration/Signup.jsx';
import RegistrationComplete from 'components/registration/RegistrationComplete';
import Homepage from 'components/public/Homepage';
import Login from 'components/public/Login';
import LoginChoice from 'components/public/LoginChoice';
import ForgotPassword from 'components/public/ForgotPassword';
import ResetPassword from 'components/public/ResetPassword';
import EmailVerification from './components/public/EmailVerification';
import EmailGuard from './components/public/EmailGuard.jsx';
import Authorise from './components/public/Authorise';
import PrivateRoute from 'auth0/PrivateRoute';
import { createBrowserHistory as createHistory } from 'history';
import './App.css';
import TermsAndConditions from 'components/public/TermsAndConditions';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const history = createHistory();

function App(props) {
	const globalContext = useContext(GlobalContext);
	const { t, i18n } = useTranslation();
	const [ language, setLanguage ] = useState('en');


	useEffect(
		() => {
			let lng = Cookies.get('locale') || 'en';

			if (!Cookies.get('locale')){
				lng = navigator.language;
			}
	
			if (language !== lng) {
				i18n.changeLanguage(lng);
				setLanguage(lng);
			}
		},
		[ language, i18n ]
	);

	const onLanguageChange = useCallback(
		(lng) => {
			Cookies.set('locale', lng);
			i18n.changeLanguage(lng);
			setLanguage(lng);
		},
		[ i18n ]
	);

	return (
		<React.Fragment>
			<Helmet>
				<title>{window.webpageDetails.webpageName}</title>
			</Helmet>
			<Router>
				<Switch>
					<Route path="/" exact component={Homepage} history={history} />
					<Route path="/LoginGuard" exact component={LoginGuard} />
					<Route path="/Signup-Complete" exact component={RegistrationComplete} />
					<Route path="/verify" component={EmailVerification} />
					<Route
						path="/Login"
						render={(props) => <Login {...props} language={language} setLanguage={onLanguageChange} />}
					/>
					<Route path="/LoginChoice" component={LoginChoice} history={history} />
					<Route path="/ForgotPassword" component={ForgotPassword} history={history} />
					<Route path="/ResetPassword" component={ResetPassword} history={history} />
					<Route
						path="/signup"
						exact
						render={(props) => <Signup {...props} language={language} setLanguage={onLanguageChange} />}
					/>
					<Route path="/authorise" exact component={Authorise} history={history} />
					<Route path="/EmailGuard" exact component={EmailGuard} history={history} />
					<PrivateRoute path="/TermsAndConditions" exact component={TermsAndConditions} history={history} />
					<PrivateRoute path="/Members" exact component={Inbox} history={history} />
					<PrivateRoute path="/Members/Support" exact component={Support} history={history} />
					<PrivateRoute
						path="/Members/PhotoVerification"
						exact
						component={PhotoVerificationWizard}
						history={history}
					/>
					<PrivateRoute path="/Members/Account" exact component={Account} history={history} />
					<PrivateRoute
						path="/Members/Account/Verification"
						exact
						component={Account}
						history={history}
						componentProps={{ page: 'verification' }}
					/>
					<PrivateRoute path="/Members/Forward" exact component={Forward} history={history} />
					<PrivateRoute path="/PaymentRequired" exact component={PaymentRequired} history={history} />
				</Switch>
			</Router>
			<Confirm
				show={globalContext.dialog.response.visible}
				title={globalContext.dialog.response.title}
				body={globalContext.dialog.response.body}
				onConfirm={globalContext.dialog.response.onConfirm}
				onCancel={globalContext.dialog.response.onCancel}
			/>
		</React.Fragment>
	);
}

export default App;
