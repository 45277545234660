import { useReducer } from 'react';
import verificationActions from './verificationActions';
import verificationReducer from './verificationReducer';

export default function useVerification() {
	const [ verification, verifiactionDispatch ] = useReducer(verificationReducer);
	const {
		uploadId,
		getVerificationCode,
		smsVerification,
		verifySms,
		getVerificationStatus,
		emailVerification,
		submitEmailVerification,
		deleteId,
		deleteIdVerification
	} = verificationActions(verifiactionDispatch);

	return {
		response: verification,
		uploadId,
		getVerificationCode,
		smsVerification,
		verifySms,
		getVerificationStatus,
		emailVerification,
		submitEmailVerification,
		deleteId,
		deleteIdVerification
	};
}
