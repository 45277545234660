import React, { useContext, useState } from 'react';
import { Button, Row, Col, Accordion, Card, Table, Form } from 'react-bootstrap';
import GlobalContext from 'context/globalContext';
import styles from './onceOffPayment.module.css';
import * as config from 'config.js';
import { PayPalButton } from 'react-paypal-button-v2';
import { useTranslation } from 'react-i18next';

const Security = (props) => {
	const globalContext = useContext(GlobalContext);
	const { t  } = useTranslation();
	const [ amount, setAmount ] = useState(50);

	return (
		<div>
			<Accordion className={styles.accordion}>
				<Card>
					<Accordion.Toggle as={Card.Header} eventKey="0">
						{t('Paypal')}
						<img src={`/members/images/paypal-icon.png`} className={styles.paymentIcon} />
					</Accordion.Toggle>
					<Accordion.Collapse eventKey="0">
						<Card.Body className={styles.paypalButton}>
							<Form.Group controlId="paypal-amount" style={{ maxWidth: '750px' }}>
								<Form.Label>{t('Amount')}</Form.Label>
								<Form.Control as="select" value={amount} onChange={(e) => setAmount(e.target.value)}>
									<option value="20">$20</option>
									<option value="50">$50</option>
									<option value="75">$75</option>
									<option value="100">$100</option>
									<option value="150">$150</option>
									<option value="200">$200</option>
								</Form.Control>
							</Form.Group>
							<PayPalButton
								amount={amount}
								shippingPreference="NO_SHIPPING"
								options={{
									disableFunding: 'credit,card',
									clientId: config.PaypalClientId,
									currency: 'AUD'
								}}
								onSuccess={(details, data) => {
									return globalContext.account.verifyPaypal(data.orderID);
								}}
							/>
						</Card.Body>
					</Accordion.Collapse>
				</Card>
				<Card>
					<Accordion.Toggle as={Card.Header} eventKey="1">
						{t('Bank Transfer')}
						<img src={`/members/images/bank-icon.png`} className={styles.paymentIcon} />
					</Accordion.Toggle>
					<Accordion.Collapse eventKey="1">
						<Card.Body>
							{t('Please make a direct deposit with the following details')} :-
							<br />
							<br />
							<Table>
								<tbody>
									<tr>
										<td>
											<strong>{t('Name')}:</strong>
										</td>
										<td> {window.webpageDetails.webpageName} </td>
									</tr>
									<tr>
										<td>
											<strong>BSB:</strong>
										</td>
										<td> 014 513 </td>
									</tr>
									<tr>
										<td>
											<strong>Account:</strong>
										</td>
										<td> 269 374 187 </td>
									</tr>
									<tr>
										<td>
											<strong>{t('Description')}:</strong>
										</td>
										<td>{globalContext.account.response.account.userCode}</td>
									</tr>
								</tbody>
							</Table>
							{t('Please remember to include your userID in the transfer description')}: {globalContext.account.response.account.userCode} <br></br>
							{t('Once your payment has been confirmed your balance will be updated.')}<br />
							<br />
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>{' '}
		</div>
	);
};
export default Security;
