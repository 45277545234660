import { useReducer } from "react";
import transactionActions from "./transactionActions";
import transactionReducer from "./transactionReducer";

export default function useTransaction() {
    const [transactions, transactionDispatch] = useReducer(transactionReducer, {});
    const { getTransactions } = transactionActions(transactionDispatch);

    return {
        response: transactions,
        getTransactions
    };
}