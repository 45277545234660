import axios from "axios";
import securityHelper from "security/securityHelper";
import {
  GET_ACCOUNT,
  CHOOSE_PLAN,
  UPDATE_LOGIN_GUARD,
  VERIFY_SMS,
  REGISTRATION_COMPLETE,
  UPDATE_AUTO_ACTION,
  UPDATE_AUTO_TOPUP,
  UPDATE_TERMS_AND_CONDITIONS,
  UPDATE_EMAIL_NOTIFICATION,
  UPDATE_INCLUDE_PDF,
  UPDATE_SEND_SEPERATE,
  UNPAUSE_ACCOUNT,
  PAUSE_ACCOUNT
} from "./accountActionTypes";

export default dispatch => {
  const load = async(account) => {
    dispatch({
      payload: account,
      type: GET_ACCOUNT
    });
  };

  const verifyPaypal = async(orderId) => {
      var account = await _verifyPayPal(orderId);
      load(account);
  }

  const getAccount = async() => {
    var account = await _getAccount();
    securityHelper.saveToken(account);
    load(account);
  }

  const updateAccount = async (accountUser) => {
    var jwtResponse = await _updateAccount(accountUser);
		securityHelper.saveToken(jwtResponse);
		load(jwtResponse);
  };

  const isEmailAvailable = async (email) => {
    return await _isEmailAvailable(email);
  }

  const accountSignup = async(accountUser) => {
    var jwtResponse = await _accountSignup(accountUser);
    securityHelper.saveToken(jwtResponse);
    load(jwtResponse);
  }

  const choosePlan = async (planId) => {
    await _choosePlan(planId);
    dispatch({
      payload: planId,
      type: CHOOSE_PLAN
    });
  };

  const updateLoginGuard = async (enabled) => {
    await _updateLoginGuard(enabled);
    dispatch({
      payload: enabled,
      type: UPDATE_LOGIN_GUARD
    });
  };

  const updateAutoAction = async(autoAction) => {
    await _updateAutoAction(autoAction);
    dispatch({
      payload: autoAction,
      type: UPDATE_AUTO_ACTION
    })
  }

  const verifySms = async (code) => {
    var mobileVerification = await _verifySms(code);
    dispatch({
      payload: mobileVerification,
      type: VERIFY_SMS
    });
    return mobileVerification;
  };

  const registrationComplete = async (creditCardExempt) => {
    await _registrationComplete(creditCardExempt);
    dispatch({
      type: REGISTRATION_COMPLETE
    })
  }

  const closeAccount = async() => {
    await _closeAccount(); 
  }

  
  const updateAutoTopup = async (amount) => {
    await _updateAutoTopup(amount);
    dispatch({
      type: UPDATE_AUTO_TOPUP,
      payload: amount
    })
  }

  const sendResetPassword = async (email) => {
    await _sendResetPassword(email);
  }

  const resetPassword = async(email, newPassword, resetCode) => {
    await _resetPassword(email, newPassword, resetCode);
  }

  const changePassword = async(oldPassword, newPassword) => {
    return await _changePassword(oldPassword, newPassword);
  }

  const updateIncludePdf = async (include) => {
    await _updateIncludePdf(include);
    dispatch({
      type: UPDATE_INCLUDE_PDF,
      payload: include
    })
  }

  const updateSendSeperate = async (sendSeperate) => {
    await _updateSendSeperate(sendSeperate);
    dispatch({
      type: UPDATE_SEND_SEPERATE,
      payload: sendSeperate
    })
  }

  const updateEmailActionNotificationChange = async (notify) => {
    await _updateEmailActionNotificationChange(notify);
    dispatch({
      type: UPDATE_EMAIL_NOTIFICATION,
      payload: notify
    });

  }

  const saveTermsAndConditions = async() => {
    await _saveTermsAndConditions();
    dispatch({
      type: UPDATE_TERMS_AND_CONDITIONS
    })
  }

  const unpause = async() => {
    await _unpause();
    dispatch({
      type: UNPAUSE_ACCOUNT
    })
  }

  const pause = async() => {
    await _pause();
    dispatch({
      type: PAUSE_ACCOUNT
    })
  }

  return { 
    isEmailAvailable,
    load, 
    updateAccount, 
    choosePlan, 
    updateLoginGuard, 
    verifySms, 
    registrationComplete,
    updateAutoAction,
    closeAccount,
    updateAutoTopup,
    accountSignup,
    getAccount,
    sendResetPassword,
    resetPassword, 
    changePassword,
    saveTermsAndConditions,
    verifyPaypal,
    updateEmailActionNotificationChange,
    updateIncludePdf,
    updateSendSeperate,
    unpause,
    pause
   };
};

const _pause = () => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/Account/pause`,
      method: "POST",
    })
      .then(response => {
        resolve();
      })
      .catch(response => {
        console.log("error pausing account", response);
        reject();
      });
  });
}


const _unpause = () => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/Account/Unpause`,
      method: "POST",
    })
      .then(response => {
        resolve();
      })
      .catch(response => {
        console.log("error unpausing account", response);
        reject();
      });
  });
}

const _updateIncludePdf = (include) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/Account/UpdateIncludePdf?include=${include}`,
      method: "POST",
    })
      .then(response => {
        resolve();
      })
      .catch(response => {
        console.log("error updating include pdf", response);
        reject();
      });
  });   
}

const _updateSendSeperate = (sendSeperate) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/Account/updateSeperateAlerts?sendSeperate=${sendSeperate}`,
      method: "POST",
    })
      .then(response => {
        resolve();
      })
      .catch(response => {
        console.log("error updating setting send seperate", response);
        reject();
      });
  });   
}

const _updateEmailActionNotificationChange = (notify) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/Account/UpdateEmailActionNotificaion?notify=${notify}`,
      method: "POST",
    })
      .then(response => {
        resolve();
      })
      .catch(response => {
        console.log("error updating email notificatino", response);
        reject();
      });
  }); 
}


const _verifyPayPal = (orderId) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/paypal/verify?orderId=${orderId}`,
      method: "POST",
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(response => {
        console.log("error verifying paypal transaction", response);
        reject();
      });
  })
}

const _saveTermsAndConditions = () => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/TermsAndConditions/SaveTermsAndConditions`,
      data: {Html:  new XMLSerializer().serializeToString(document)},
      method: "POST",
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(response => {
        console.log("error saving terms and conditions", response);
        reject();
      });
  })
}

const _changePassword = (oldPassword, newPassword) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/Account/ChangePassword?oldPassword=${encodeURIComponent(oldPassword)}&newPassword=${encodeURIComponent(newPassword)}`,
      method: "POST",
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(response => {
        console.log("error changing password", response);
        reject();
      });
  })
}

const _resetPassword = (email, newPassword, resetCode) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/Account/ResetPassword?email=${encodeURIComponent(email)}&newPassword=${encodeURIComponent(newPassword)}&resetCode=${encodeURIComponent(resetCode)}&webpage=${window.webpageDetails.webpageName}`,
      method: "POST",
    })
      .then(response => {
        resolve();
      })
      .catch(response => {
        console.log("error getting reseting password", response);
        reject();
      });
  });

}

const _sendResetPassword = (email) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/Account/SendResetPassword?email=${email}&webpage=${window.webpageDetails.webpageName}`,
      method: "POST",
    })
      .then(response => {
        resolve();
      })
      .catch(response => {
        console.log("error getting reseting password", response);
        reject();
      });
  });
}

const _isEmailAvailable = (email) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/Registration/IsEmailAvailable?email=${email}&webpage=${window.webpageDetails.webpageName}`,
      method: "POST",
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(response => {
        console.log("error getting isEmailAvailable", response);
        reject();
      });
  });
}

const _updateAutoTopup = async (amount) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/Account/UpdateAutoTopup?amount=${amount}`,
      method: "POST",
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(response => {
        console.log("Error updating topup amount", response);
        reject();
      });
  });
}


const _closeAccount = async() => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/Account/CloseAccount`,
      method: "post",
    }).then(response => {
      resolve();
    });
  });

}

const _registrationComplete = async (creditExempt) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/Registration/RegistrationComplete?x=${!!creditExempt}&webpage=${window.webpageDetails.webpageName}`,
      method: "post",
    }).then(response => {
      resolve();
    });
  });
};

const _getAccount = async () => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/account/GetAccount`,
      method: "post",
    }).then(response => {
      resolve(response.data);
    });
  });
}


const _verifySms = async (code) => {

  return new Promise((resolve, reject) => {
    axios({
      url: `/api/verification/verifysms?code=${code}`,
      method: "post",
    }).then(response => {
      resolve(response?.data?.verificationStatus === 1);
    });
  });
};

const _updateLoginGuard = async (enabled) => {

  return new Promise((resolve, reject) => {
    axios({
      url: `/api/Account/UpdateLoginGuard?enabled=${enabled}`,
      method: "POST",
    })
      .then(response => {
        resolve(response);
      })
      .catch(response => {
        console.log("Error getting account", response);
        reject();
      });
  });
};

const _choosePlan = async (planId) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/Account/ChoosePlan?planId=${planId}`,
      method: "POST",
    })
      .then(response => {
        resolve(response);
      })
      .catch(response => {
        console.log("Error getting account", response);
        reject();
      });
  });
};

const _accountSignup = (accountUser) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/Registration/Signup?webpage=${window.webpageDetails.webpageName}`,
      method: "POST",
      data: accountUser,
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(response => {
        console.log("Error getting account", response);
        reject();
      });
  });
}

const _updateAccount = async (accountUser) => {

  
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/Account/UpdateAccount?webpage=${window.webpageDetails.webpageName}`,
      method: "POST",
      data: accountUser,
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(response => {
        console.log("Error getting account", response);
        reject();
      });
  });
};

const _updateAutoAction = async (autoAction) => {

  return new Promise((resolve, reject) => {
    axios({
      url: `/api/Account/UpdateAutoAction?autoAction=${autoAction}`,
      method: "POST",
    })
      .then(response => {
        resolve();
      })
      .catch(response => {
        console.log("Error setting AutoAction", response);
        reject();
      });
  });
}

