import React, { useState, useContext } from 'react';
import GlobalContext from 'context/globalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './login.module.css';

function ForgotPassword(props) {
	const globalContext = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
	const [ login, setLogin ] = useState('');
    const [ passwordSent, setPasswordSent] = useState(false);

    const onSend = async () => {
        if (!loading){
            setLoading(true);
            await globalContext.account.sendResetPassword(login);
            setLoading(false);
            setPasswordSent(true);
        }

    }

	return (
		<div className={`${styles.wrapper} ${styles.fadeInDown} ${styles.login}`}>
			<div className={styles.formContent}>
				<div className={`${styles.fadeIn} ${styles.first}`}>
					<img src={window.webpageDetails.logo} id="icon" alt="Hotsnail Icon" className={styles.hotsnailIcon} />
				</div>
                {!passwordSent ? (
				<form>
					<input
						type="text"
						className={`${styles.fadeIn} ${styles.second}`}
						placeholder="Email"
						value={login}
						onChange={(e) => setLogin(e.target.value) }
					/>
					<button type="button" className={`${styles.fadeIn} ${styles.fourth} btn-primary`} onClick={onSend}>
						{!loading ? 'Reset Password' : <FontAwesomeIcon icon="spinner" spin />}
					</button>
				</form>
                )
                : (
                    <div className="p-5">
                        A reset link has been sent to {login}. If you do not receive it in 5 minutes please check your junk folder
                    </div>
                )}
			</div>
		</div>
	);
}

export default ForgotPassword;
