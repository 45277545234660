import React, { useState, useContext } from 'react';
import Stepper from 'react-stepper-horizontal-sppro';
import Settings from 'components/members/accountSettings/elements/Settings';
import ChooseAddress from './ChooseAddress.jsx';
import PaymentWrapper from './PaymentWrapper.jsx';
import ChoosePlan from './ChoosePlan';
import styles from 'styles/dropdownContainer.module.css';
import { useEffect } from 'react';
import useWindowSize from 'hooks/useWindowSize';
import GlobalContext from 'context/globalContext';
import qs from 'qs';
import { useTranslation } from 'react-i18next';

import { REGISTER_STEP, CHOOSE_ADDRESS_STEP, CHOOSE_PLAN, CHOOSE_PAYMENT_STEP } from './SignupSteps';

function Signup(props) {
	const [ activeStep, setActiveStep ] = useState(REGISTER_STEP);
	const [ stepTitles, setStepTitles ] = useState([]);
	const [ firstName, setFirstName ] = useState('');
	const [ lastName, setLastName ] = useState('');
	const [ email, setEmail ] = useState('');
	const globalContext = useContext(GlobalContext);
	const size = useWindowSize();
	const [ t ] = useTranslation();

	useEffect(() => {
		var queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true });
		if (queryString.firstName) {
			setFirstName(queryString.firstName);
			setLastName(queryString.lastName);
			setEmail(queryString.email);
		}
	}, []);

	useEffect(
		() => {
			if (window.outerWidth >= 768) {
				setStepTitles(window.webpageDetails.signupSettings.fullWidth);
			} else {
				setStepTitles(window.webpageDetails.signupSettings.mobileWidth);
			}
		},
		[ size ]
	);

	const onSignupComplete = async () => {
		window.location.href = '/Signup-Complete';
	};

	const onPlanSaved = async () => {
		if (window.location.search.indexOf('noCreditCard') > -1) {
			await globalContext.account.registrationComplete(true);
			window.location.href = '/Signup-Complete';
		} else {
			setActiveStep(window.webpageDetails.signupSettings.getNextStep(CHOOSE_PLAN));
		}
	};

	const renderSteps = () => {
		return stepTitles.map((a) => {
			return { title: t(a) };
		});
	};

	return (
		<div className={styles.outterWrapper}>
			<div className={`${styles.wrapper} ${styles.fadeInDown} ${styles.wizardContainer}`}>
				<div className={styles.formContent}>
					<div className={`${styles.fadeIn} ${styles.first} text-center`}>
						<img
							src={window.webpageDetails.logo}
							id="icon"
							alt="Hotsnail Icon"
							className={styles.hotsnailIcon}
						/>
					</div>
					<Stepper steps={renderSteps()} activeStep={activeStep} />

					{activeStep === REGISTER_STEP && (
						<Settings
							history={props.history}
							language={props.language}
							setLanguage={props.setLanguage}
							onSaved={() => {								
								let nextStep = window.webpageDetails.signupSettings.getNextStep(REGISTER_STEP);
								setActiveStep(nextStep);
							}}
							signup={true}
							user={{ firstName, lastName, email }}
						/>
					)}

					{activeStep === CHOOSE_ADDRESS_STEP && (
						<ChooseAddress
							history={props.history}
							onBack={() =>
								setActiveStep(REGISTER_STEP)}
							onSaved={() => setActiveStep(CHOOSE_PLAN)}
							signup={true}
						/>
					)}

					{activeStep === CHOOSE_PLAN && <ChoosePlan onSaved={() => onPlanSaved()} signup={true} />}

					{activeStep === CHOOSE_PAYMENT_STEP && (
						<PaymentWrapper
							onBack={() => setActiveStep(CHOOSE_ADDRESS_STEP)}
							onSaved={() => onSignupComplete()}
							signup={true}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default Signup;
