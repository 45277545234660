import axios from 'axios';
import securityHelper from "security/securityHelper";

export default (dispatch) => {
	const doLogin = async (username, password, loginGuard, keepLoggedIn) => {
		var account = await _login(username, password, loginGuard, keepLoggedIn);

		if (!account) {
			return null;
		}
		if (account === 'loginGuardRequired'){
			return 'loginGuardRequired';
		}

		securityHelper.saveToken(account);

		return account;
	};

	return { doLogin };
};

const _login = (email, password, loginGuard, keepLoggedIn) => {
	return new Promise((resolve, reject) => {
		let jwtUser = { EmailAddress: email, Password: password, KeepLoggedIn: keepLoggedIn, loginGuard: loginGuard };
		axios({
			url: `/api/Login?webpage=${window.webpageDetails.webpageName}`,
			method: 'POST',
			data: jwtUser
		})
			.then((response) => {
				if (response.data === 'loginGuardRequired') {
					resolve('loginGuardRequired');
				}
				else {
					resolve(response?.data.account);
				}
			})
			.catch((error) => {
				console.log("error", error);
				resolve(null)
			});
	});
};
