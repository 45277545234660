import React, { useState } from 'react';
import { Form } from 'react-bootstrap';


function GrowingTextArea(props) {
    const [rows, setRows] = useState(props.rows);

    const onChange = (event) => {
        const textareaLineHeight = 24;

        const previousRows = event.target.rows;
        event.target.rows = props.rows; // reset number of rows in textarea 

        const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) {
            event.target.rows = currentRows;
        }

        setRows(currentRows);
        props.onChange(event)
    };

    return (
        <Form.Control as="textarea" rows={rows} onChange={onChange} value={props.value || ""} />
    )

}

export default GrowingTextArea;