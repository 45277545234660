import React, { useContext, useEffect } from "react";
import { Dropdown, Navbar, Alert } from "react-bootstrap";
import GlobalContext from "context/globalContext";
import securityHelper from "security/securityHelper";
import IosMenu from "react-ionicons/lib/IosMenu";
import Styles from "./TopNav.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const MAX_NAME_LENGTH = 20;

const TopNav = props => {
  const globalContext = useContext(GlobalContext);
  const [ t ] = useTranslation();
  
  /* Use Effects */
  useEffect(() => {
    const fetchAlerts = async () => {
      globalContext.alerts.fetch();
    };

    fetchAlerts();
    // eslint-disable-next-line
  }, []);

  /* Events */

  const onMarkRead = async (event, id) => {
    if (event) { 
      event.stopPropagation();
    }
    globalContext.alerts.markRead(id);
  };

  const onMobileMenuButtonClick = () => {
    globalContext.events.publish("mobileMenuClick");
  };

  const onGoto = page => {
    switch (page) {
      case "Inbox":
        props.history.push("/Members/");
        break;
      case "Account":
        props.history.push("/Members/Account");
        break;

        default:
        console.log("On Goto, Nothing Happens");
        break;
    }
  };

  const onLogout = () => {
    securityHelper.logout();
    window.location.href = `/api/account/logout`
  }

  const onClickAlert = (event, alert) => {
    if (alert.url) {
      props.history.push(alert.url);
    }
    onMarkRead(null, alert.alertId);
  };

  const onSupport = () => {
    props.history.push("/Members/Support")
  }

  /* Render Methods */

  const renderName = () => {
    const truncate = (input) => input.length > MAX_NAME_LENGTH ? `${input.substring(0, MAX_NAME_LENGTH)}..` : input;
    return truncate(`${globalContext?.account?.response?.account?.firstName} ${globalContext?.account?.response?.account?.lastName}`); 
  }

  const renderAlerts = () => {
    var unreadAlerts = globalContext.alerts.response.filter(
      alert => !alert.read
    );
    if (unreadAlerts.length > 0) {
      let currentAlert = unreadAlerts[0];
      return (
        <Alert
          variant="danger"
          className={
            Styles.alert + " alert-unverified alert-dismissible fade show"
          }
          onClick={(e) => onClickAlert(e, currentAlert)}
        >
          {t(currentAlert.message)}
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={(e) => onMarkRead(e, currentAlert.alertId)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Alert>
      );
    }
  };

  return (
    <Navbar
      bg="nav"
      expand={false}
      className={Styles.navbar + " fixed-top navbar-light pos-r"}
    >
      <a 
      className={Styles.navbarBrand} 
      href="/" 
      onClick={(e) => {
        e.preventDefault(); 
        onGoto("Inbox");
      }
       } >
        <img
          src={`${process.env.PUBLIC_URL}/images/${window.webpageDetails.webpageName}-secondary.png`}
          className="d-inline-block align-top"
          alt={window.webpageDetails.webpageName}
        />
      </a>
      <IosMenu
        className={Styles.mobileMenuButton}
        onClick={() => onMobileMenuButtonClick()}
      />
      {renderAlerts()}

      <div>
      <FontAwesomeIcon 
        icon="question-circle" 
        className={`${Styles.supportIcon} support-icon-color`}
        onClick={onSupport}
        />

        <Dropdown className={Styles.dropdown}>
          <Dropdown.Toggle as="div" className={Styles.clientIcon + " td-n"}>
            <div className={Styles.peer + " mr-10"}>
              <img src={`/members/images/dummy-person.jpg`} alt="Name" />
            </div>
            <div className={`${Styles.peer} pt-3`}>
              <span className="fs-small top-account-button">
                <span className={Styles.hideOnMobile}>{renderName()}</span> {`(${globalContext?.account?.response?.account?.balance})`}
              </span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={e => onGoto("Inbox")}>{t('Inbox')}</Dropdown.Item>
            <Dropdown.Item onClick={e => onGoto("Account")}>
              {t('Account')}
            </Dropdown.Item>
            <Dropdown.Item onClick={e => onLogout()}>
              {t('Logout')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Navbar>
  );
};
export default TopNav;
