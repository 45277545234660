import axios from 'axios';

export default {
	fetchMail: async (mailId, markRead, cancelToken) => {
		return new Promise((resolve, reject) => {
			axios({
				url: `/api/mail/download?mailId=${mailId}&markRead=${markRead}`,
				method: 'GET',
				cancelToken: cancelToken
			})
				.then((response) => {
					if (response?.data) {
						resolve(response.data);
					}
				})
				.catch((response) => {
					console.log('Error downloading mail', response);
					reject();
				});
		});
	},
	forwardMail: async (addressDto) => {
		return new Promise((resolve, reject) => {
			axios({
				url: `/api/forward/create`,
				data: addressDto,
				method: 'POST'
			})
				.then((response) => {
					resolve(response.data);
				})
				.catch((response) => {
					console.log('Error downloading mail', response);
					reject();
				});
		});
	}
};
