import axios from 'axios';
import {
	UPLOAD_ID,
	GET_VERIFICATION_CODE,
	GET_VERIFICATION_STATUS,
	VERIFY_SMS,
	DELETE_ID,
	DELETE_VERIFICATION
} from './verificationActionTypes';
import imageUtility from 'utilities/imageUtility';

export default (dispatch) => {
	const verifySms = () => {
		dispatch({
			type: VERIFY_SMS
		});
	};

	const uploadId = async (file, photoSide, randomCode, uploadProgress) => {
		await _uploadId(file, photoSide, randomCode, uploadProgress);
		var base64 = await imageUtility.toBase64(file);
		dispatch({
			type: UPLOAD_ID,
			payload: { photoSide, base64 }
		});
	};

	const deleteId = (photoSide) => {
		dispatch({
			type: DELETE_ID,
			payload: photoSide
		});
	};

	const deleteIdVerification = async () => {
		await _deleteIdVerification();
		dispatch({
			type: DELETE_VERIFICATION
		});
	};

	const getVerificationCode = async () => {
		var verificationCode = await _getVerificationCode();
		dispatch({
			type: GET_VERIFICATION_CODE,
			payload: verificationCode
		});
	};

	const smsVerification = async (mobileNumber) => {
		return await _smsVerification(encodeURIComponent(mobileNumber));

	};

	const getVerificationStatus = async () => {
		var response = await _getVerificationStatus();
		dispatch({
			type: GET_VERIFICATION_STATUS,
			payload: response
		});
	};

	const emailVerification = async () => {
		await _emailVerification();
	};

	const submitEmailVerification = async (code) => {
		return await _submitEmailVerification(code);
	};

	return {
		uploadId,
		getVerificationCode,
		smsVerification,
		getVerificationStatus,
		emailVerification,
		submitEmailVerification,
		verifySms,
		deleteId,
		deleteIdVerification
	};
};

const _submitEmailVerification = async (code) => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/EmailVerification/SubmitVerification?q=${code}`,
			method: 'post'
		}).then((response) => {
			resolve(response.data);
		});
	});
};

const _emailVerification = async () => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/EmailVerification/SendVerification?webpage=${window.webpageDetails.webpageName}`,
			method: 'post'
		}).then((response) => {
			resolve(response.data);
		});
	});
};

const _getVerificationStatus = async () => {
	return new Promise((resolve, reject) => {
		axios({
			url: '/api/verification/getverification',
			method: 'get'
		}).then((response) => {
			resolve(response.data);
		});
	});
};

const _deleteIdVerification = async () => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/verification/deleteVerification`,
			method: 'delete'
		})
			.then((response) => {
				resolve();
			})
			.catch((error) => {
				console.log('error', error);
				reject();
			});
	});
};

const _smsVerification = async (mobileNumber) => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/verification/Sms?mobileNumber=${mobileNumber}`,
			method: 'post'
		}).then((response) => {
			resolve(response.data);
		});
	});
};

const _getVerificationCode = async () => {
	return new Promise((resolve, reject) => {
		axios({
			url: '/api/verification/generaterandomcode',
			method: 'get'
		}).then((response) => {
			resolve(response.data);
		});
	});
};

const _uploadId = async (file, photoSide, uploadProgress) => {
	return new Promise((resolve, reject) => {
		const formData = new FormData();
		formData.append('file', file);
		axios({
			method: 'post',
			url: `/api/verification/upload?photoSide=${photoSide}`,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			onUploadProgress: uploadProgress
		})
			.then((res) => {
				resolve(res);
			})
			.catch(function(error) {
				console.log('error', error);
			});
	});
};
