const relativeUrl = "/members/images";

export default {
    getLogo: (forwardingCompany) => {
        switch(forwardingCompany){
            case "Australia Post":
                return `${relativeUrl}/AusPost.png`;
            case "DHL":
                return `${relativeUrl}/DHL.png`;
            case "Custom":
                    return `${relativeUrl}/custom.jpg`;
            default:
                return null;
        }
    }
}
