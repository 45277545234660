import {
    SET_NAV,
} from "./accountSettingsActionTypes";


export default dispatch => {
  const setNav = (nav) => {
    dispatch({ type: SET_NAV, payload: nav });
  }
    return { setNav };
};
