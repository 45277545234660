import React, { useState, useEffect, useContext } from 'react';
import { Col } from 'react-bootstrap';
import PendingForwardItem from './elements/PendingForwardItem';
import ForwardedItem from './elements/ForwardedItem';
import GlobalContext from 'context/globalContext';
import Styles from './forwardLog.module.css';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function ForwardLog(props) {
	const [ selectedItems, setSelectedItems ] = useState([]);
	const globalContext = useContext(GlobalContext);
	const [ t ] = useTranslation();

	useEffect(() => {
		const getLog = async () => {
			globalContext.forward.getLog();
		};

		getLog();
		// eslint-disable-next-line
	}, []);

	const renderHeading = (headingName) => {
		return (
			<Col xs={12} className={Styles.sectionHeading}>
				{headingName}
			</Col>
		);
	};

	const onChecked = (item, checked) => {
		let newSelectedItems = [ ...selectedItems ];

		if (checked) {
			newSelectedItems.push(item);
		} else {
			newSelectedItems = newSelectedItems.filter((a) => a !== item);
		}
		setSelectedItems(newSelectedItems);
	};

	const onCancelForward = async () => {
		var selectedId = selectedItems.map((a) => a.forwardId);
		globalContext.forward.cancelForward(selectedId);
	};

	const renderPendingForward = () => {
		return (
			<React.Fragment>
				{globalContext.forward.response.log &&
				globalContext.forward.response.log.pending &&
				globalContext.forward.response.log.pending.length > 0 && (
					<React.Fragment>
						<div className={`${Styles.toolbar} toolbar-background`}>
							<button
								title="Delete"
								type="button"
								disabled={selectedItems.length === 0}
								className={Styles.toolbarButton + '  btn btn-mail-action'}
								onClick={onCancelForward}
							>
								<FontAwesomeIcon icon='trash-alt' />
							</button>
						</div>

						{renderHeading(t('Pending Forward'))}
						{globalContext.forward.response.log.pending.map((item) => (
							<PendingForwardItem
								key={item.forwardId}
								forward={item}
								onChange={(checked) => onChecked(item, checked)}
							/>
						))}
					</React.Fragment>
				)}
				{renderHeading(t('Forward Log'))}
				{globalContext.forward.response.log.log.map((item) => (
					<ForwardedItem key={item.forwardId} forward={item} />
				))}
			</React.Fragment>
		);
	};

	if (!globalContext.forward.response.log) {
		return <div className={Styles.forwardList}>{t('loading')}..</div>;
	} else {
		return (
			<div className={Styles.forwardList}>
				<div className={Styles.forwardWrapper}>{renderPendingForward()}</div>
			</div>
		);
	}
}

export default ForwardLog;
