import React from "react";
import { useTranslation } from 'react-i18next';

const WizardNav = props => {
	const { t  } = useTranslation();

  return (
    <ul className="nav nav-pills mt-4">
      {props.navs.map(nav => (
        <li key={nav.key} style={{width: `${1/ props.navs.length * 100}%`}} className={nav.key === props.activeIndex ? "active" : ""} onClick={(e) => props.setActiveNav(nav.key)}>
          <div href="#" data-toggle="tab" >
            {t(nav.title)}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default WizardNav;
