import React, { useState, useContext } from 'react';
import { Form, Button } from 'react-bootstrap';
import styles from 'styles/dropdownContainer.module.css';
import GlobalContext from 'context/globalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function TermsAndConditions(props) {
	const [ checked, setChecked ] = useState(false);
	const [ saving, setSaving] = useState(false);
	const globalContext = useContext(GlobalContext);

	const onSave = async () => {
		setSaving(true);
		await globalContext.account.saveTermsAndConditions();
		setSaving(false);
		window.location.href = "/members";
	}

	return (
		<div className={styles.outterWrapper}>
			<div className={`${styles.wrapper} ${styles.fadeInDown} ${styles.wizardContainer}`}>
				<div className={styles.formContent}>
					<div className={`${styles.fadeIn} ${styles.first} text-center`}>
						<img src={window.webpageDetails.logo} id="icon" alt={window.webpageDetails.webpageName + "Icon"} className={styles.hotsnailIcon} />
					</div>
					<p>
						<b>Terms and Conditions </b>
					</p>
					<p>
						Please read the terms and conditions carefully before using {window.webpageDetails.webpageName}. By applying for services
						at {window.webpageDetails.webpageName}, you are accepting the terms and conditions of this contract.
					</p>

					<p>Definitions</p>
					<p>By “You”, we mean the person who submitted the application.</p>
					<p>{window.webpageDetails.webpageName} is a business of Digital Blitz Pty Ltd ACN 111 323 445</p>
					<p>Applicable rates are identified on {window.webpageDetails.webpageName} website under “Plans and Pricing”.</p>
					<p>
						{window.webpageDetails.webpageName} online mail management interface is located at www.{window.webpageDetails.webpageName}.com.au after a successful
						login.
					</p>

					<p>
						<b>General</b>
					</p>
					<p>
						{window.webpageDetails.webpageName} is a mail processing service and online mail management tool. {window.webpageDetails.webpageName} will not
						knowingly accept, forward or be held responsible for any illegal, damaged or hazardous mail
						belonging to or being sent to you. {window.webpageDetails.webpageName} reserves the right to refuse acceptance of such
						items and cancel the contract immediately without refund. It is your responsibility to ensure
						that this type of mail is not posted to {window.webpageDetails.webpageName}.
					</p>

					<p>
						<b>Plans and Options</b>
					</p>
					<p>
						It is your responsibility to choose the appropriate plan for your service and your pre-paid
						credit will be charged accordingly.
					</p>
					<p>Online plan changed take effect immediately.</p>
					<p>
						<b>Payment Terms</b>
					</p>
					<p>
						You are required to pay for all services in advance by pre-paid credit, when the pre-paid credit
						balance becomes less than zero further mail items will be locked until payment is received. If
						payment is not received within 14 days {window.webpageDetails.webpageName} will discontinue scanning items until the
						pre-paid credit balance is paid.
					</p>

					<p>
						<b>Pre-paid credit</b>
					</p>
					<p>
						Your pre-paid credit is your running balance which should always be kept above zero. All charges
						incurred will be deducted from the pre-paid credit’s balance. Your pre-paid credit can be
						reviewed and topped up on the {window.webpageDetails.webpageName} online management website. Pre-Paid credit is
						non-refundable and expires 12 months after purchase. The minimum amount you can top up your
						pre-paid credit is $20
					</p>
					<p>Automatic Top up of pre-paid credit</p>
					<p>
						{window.webpageDetails.webpageName} utilises stripe for credit card payments. When your balance falls below the amount
						required to process your request(s) or maintain the service(s) provided to you, this will
						trigger an automatic top up of your account of the amount you have specified in your account
						settings in the {window.webpageDetails.webpageName} online management interface.
					</p>

					<p>
						<b>Forwarding to Us</b>
					</p>
					<p>
						It is your responsibility to ensure that all mail is forwarded to {window.webpageDetails.webpageName} and we take
						absolutely no responsibility for mail which does not reach our PO Box. Once mail is received by
						{window.webpageDetails.webpageName} we will process according to options you have selected on our website, which may
						include opening, scanning, forwarding, storing or shredding.
					</p>

					<p>
						<b>Forwarding to you</b>
					</p>
					<p>
						Mail which you request to have forwarded will be sent via Australia Post regular service. Mail
						{window.webpageDetails.webpageName} sends on your behalf is not sent by express or registered mail, unless specifically
						requested. Mail lost in transit is not the responsibility of {window.webpageDetails.webpageName}, however {window.webpageDetails.webpageName}
						endeavours to located lost items through Australia Post. Please refer to Australia Posts website
						at http://auspost.com.au for postage guidelines and restricted items.
					</p>

					<p>
						<b>Scanning</b>
					</p>
					<p>
						{window.webpageDetails.webpageName} only opens your mail if you select the Scan everything plan or request a specific item
						scanned on the “selective scanning” plan as preconfigured on the {window.webpageDetails.webpageName} online mail management
						interface or during your account setup.
					</p>
					<p>
						If you choose “forward only” mail items will be stored or forwarded according to the options you
						have selected during account setup or on the {window.webpageDetails.webpageName} website and your pre-paid credit will be
						charged according to the applicable rates.
					</p>

					<p>
						If you select the “selective scanning” option on the {window.webpageDetails.webpageName} website, both sides of your
						envelope will be scanned and uploaded to your account and we will charge your pre-paid credit
						according to the applicable rates.
					</p>

					<p>
						If you select the “Scan Everything” option on the {window.webpageDetails.webpageName} website, {window.webpageDetails.webpageName} will open your mail
						and scan both sides of each page inside the mail item and your pre-paid credit will be charged
						according to the applicable rates.
					</p>

					<p>
						Non-Scannable items will be photographed by Digital Camera and the image will be uploaded to
						your {window.webpageDetails.webpageName} online account.
					</p>

					<p>
						<b>Storing</b>
					</p>
					<p>
						All mail is stored securely for 30 days for free and after that time storage fees will apply.
						The Storage fees are listed under “Plans and Pricing” on the {window.webpageDetails.webpageName} website. Long term secure
						storage fees are deducted monthly from your pre-paid credit as per applicable rates.
					</p>

					<p>
						<b>Shredding</b>
					</p>
					<p>
						{window.webpageDetails.webpageName} will shred mail which you request to be shredded either manually or automatically from
						the {window.webpageDetails.webpageName} online mail management interface or during your initial account setup.
					</p>

					<p>
						<b>Consent to open mail </b>
					</p>
					<p>
						If you choose either “selective scanning” or “scan everything” plans, then you consent to
						{window.webpageDetails.webpageName} opening your mail.
					</p>

					<p>
						<b>Consent to shred mail</b>
					</p>
					<p>
						If you request for your mail to be shredded either manually or automatically from either the
						{window.webpageDetails.webpageName} web interface or during your account setup, you consent to {window.webpageDetails.webpageName} shredding your
						mail. You consent to {window.webpageDetails.webpageName} shredding your mail if your account is automatically terminated
						due to insufficient fund for more than 90 days.
					</p>

					<p>
						<b>Indemnity</b>
					</p>
					<p>
						You indemnify and hold {window.webpageDetails.webpageName} void from and against any and all claims, demands, actions,
						proceedings/or causes of action, and all damages, liabilities, costs or expenses relating to or
						arising from the provision of the services by {window.webpageDetails.webpageName}.
					</p>
					<p>
					<b>Identification</b>
					</p>
					<p>
						{window.webpageDetails.webpageName} reserves the right require proof of account holder identity at any time. 
					</p>
					<p>
						<b>Law and Jurisdiction</b>
					</p>
					<p>
						The law of Queensland, Australia governs this contract. The parties submit to the non-exclusive
						jurisdiction of the courts of Queensland governing this contract and any courts, which may hear
						appeals from those courts in respect of any proceedings in connection with this contract.
					</p>

					<p>
						<b>International Mail </b>
					</p>
					<p>
						{window.webpageDetails.webpageName} reserves the right to open and inspect the contents of any incoming or outgoing
						international mail.
					</p>


					<p>
						<b>Account Termination</b>
					</p>
					<p>
						{window.webpageDetails.webpageName} reserves the right to cancel or suspend accounts at any time. Cancelled accounts will
						have their mail returned to sender.
					</p>
					<p>
						<b>Cancellation</b>
					</p>
					<p>
						At the end of your contract all mail being stored will be returned to an address nominated by
						you at your expense. If you do not provide {window.webpageDetails.webpageName} with an address, {window.webpageDetails.webpageName} will send the mail
						back to the address listed on the contract and future items will be marked 'Return to Sender'.
						If you do not have credit in your pre-paid credit, the items will be sent via receiver pays COD.
					</p>
					<p>
						You may cancel this service at any time however you must do so in writing and give {window.webpageDetails.webpageName} 30
						days’ notice of your intention to do so. Prepaid credit is non-refundable.
					</p>
					<p>
						<b>Automatic Account Termination</b>
					</p>
					<p>
						If you leave your account in negative balance for more than 90 days, and the credit card applied
						to your {window.webpageDetails.webpageName} account is unavailable or unable to top up the account we will automatically
						terminate your account and shred all your mail. All future mail we receive on your behalf will
						be returned to sender.
					</p>
					<p>
						<a href="/terms/Hotsnail Terms And Conditions.pdf">Download terms and conditions</a>
					</p>
					<Form.Group controlId="agree-to-terms">
						<Form.Check
							type="checkbox"
							checked={checked}
							onChange={(e) => setChecked(!checked)}
							label="I agree to the above terms and conditions"
						/>
					</Form.Group>
					{checked && (
						<Button variant="primary" type="button" onClick={onSave} className="button-bgcolor">
							{saving ? <FontAwesomeIcon icon="spinner" spin /> : "Continue"}
						</Button>
					)}
				</div>
			</div>
		</div>
	);
}

export default TermsAndConditions;
