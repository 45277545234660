import React from "react";
import { Col, Row } from "react-bootstrap";
import SfaAnimation from "sppro-sfa-animations";
import { useTranslation } from 'react-i18next';

const PhotoVerificationComplete = props => {
  const { t  } = useTranslation();
  return (
    <Row className="mt-1">
      <Col xs={12}>
        <SfaAnimation alertType="success"></SfaAnimation>
        <h4 className="info-text">
          {t('Thank you, your account has been temporarily verified.')} <br></br>
          <small>{t('Your account will be permentantly verified once it has been checked by a human')}</small>
        </h4>
      </Col>
    </Row>
  );
};

export default PhotoVerificationComplete;