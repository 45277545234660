import React, { useState, useContext, useEffect, useRef } from 'react';
import GlobalContext from 'context/globalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, Row, Col, Button, Form, Alert } from 'react-bootstrap';
import Styles from './addresses.module.css';

const Addresses = (props) => {
	const globalContext = useContext(GlobalContext);

	useEffect(() => {
		const getLocations = async () => {
			globalContext.location.getUserLocations();
		};
		getLocations();
	}, []);

	const renderAddresses = () => {
		console.log("globalContext?.location?.response?.userLocations", globalContext?.location?.response?.userLocations);
		if (globalContext?.location?.response?.userLocations) {
			return globalContext.location.response.userLocations.map(address =>
				<><address key={address.locationId}>
					<strong>{globalContext.account.response.account.accountName}</strong>
					<br />
					Suite {globalContext.account.response.account.userCode}, {address.street}<br />
					{address.suburb}, {address.state}, {address.postcode}<br />
					{address.country}
					
					<div className={Styles.addressNote}><b>Note: </b>{address.note}</div>
				</address>
					<hr></hr>
				</>
			);

		}
		return <div></div>
	}

	return (
		<div>
			{renderAddresses()}
		</div>
	);
};
export default Addresses;
