import { useReducer } from "react";
import accountSettingsActions from "./accountSettingsActions";
import accountSettingsReducer from "./accountSettingsReducer";

export default function useAccountSettings() {
  const [accountSettings, accountSettingsDispatch] = useReducer(accountSettingsReducer, {navigation: "account"});
  const { setNav } = accountSettingsActions(accountSettingsDispatch);

  return {
      response: accountSettings,
      setNav
  };
}
