import {
    FETCH_ALERTS,
    MARK_READ
} from "./alertActionTypes";

const loginReducer = (alerts, action) => {
    switch (action.type) {
        case FETCH_ALERTS:
            return action.payload;
        case MARK_READ:
            return markRead(alerts, action.payload);
        default:
            return alerts;
    }
};

const markRead = (alerts, id) => {
    return alerts.map(alert => {
        if (alert.alertId !== id){ return alert }
        return { ...alert, read: true };
    })
};

export default loginReducer;
