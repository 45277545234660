import React, { useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import GlobalContext from "context/globalContext";

const Confirm = props => {
    const globalContext = useContext(GlobalContext);

    const title = props.title || "Are you sure?";
    const confirmButton = props.confirm || "Confirm";
    const cancelButton = props.cancel || "Cancel";
    const defaultOnHide = () => {

    }

    const onHide = props.onHide || defaultOnHide;
    const onCancel = () => {
        globalContext.dialog.setDialog({...globalContext.dialog.response, visible: false});
        if (props.onCancel) { props.onCancel(); }
    }

    return (
        <Modal show={props.show} onHide={onHide}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{props.body}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onConfirm}>{confirmButton}</Button>
                <Button variant="primary" onClick={onCancel}>{cancelButton}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default Confirm;