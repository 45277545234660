import React, { useContext, useState, useEffect } from 'react';
import ChoosePlan from "components/registration/ChoosePlan";
import GlobalContext from 'context/globalContext';


const ChoosePlanWrapper = (props) => {
    const globalContext = useContext(GlobalContext);

    return (
        <div className="mt-2 mb-2">
            <ChoosePlan
                activePlan={globalContext.account.response.account.planId}
                onSaved={(e) => { console.log("e", e) }}
                showWarning={true}
            ></ChoosePlan>
        </div>
    );
};

export default ChoosePlanWrapper;
