import { useReducer } from 'react';
import mailActions from './mailActions';
import mailReducer from './mailReducer';

export default function useMail() {
	const [ mail, mailDispatch ] = useReducer(mailReducer, {});
  const { 
    getFolder,
    downloadBulk,
    markPendingShred, 
    markPendingScan, 
	cancelPending,
	returnToSender,
	moveToFolder,
	updateName, 
	recycle,
	search,
	markPendingRescan,
	email,
	mailHeld,
	markPendingForward } = mailActions(mailDispatch);

	return {
		response: mail,
		getFolder,
		downloadBulk,
		markPendingShred,
		markPendingScan,
		cancelPending,
		returnToSender,
		moveToFolder,
		updateName,
		recycle,
		search,
		markPendingRescan,
		email,
		mailHeld,
		markPendingForward
	};
}
