import React, { useEffect, useContext, useState } from "react";
import GlobalContext from 'context/globalContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  Button,
  Row,
  Col,
  Table
} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import Styles from "./additionalNames.module.css";

const AdditionalNames = props => {
  const globalContext = useContext(GlobalContext);
  const [newName, setNewName] = useState("");
  const { t  } = useTranslation();

  useEffect(() => {
    const getNames = async () => {
      globalContext.additionalNames.getNames();
    }

    getNames();
  }, []);

  const onSaveName = async () => {
    globalContext.additionalNames.saveName(newName);
    setNewName("");
  }

  const onDelete = async (id) => {
    globalContext.additionalNames.deleteName(id);
  }

  const renderNames = () => {
    if (!globalContext.additionalNames.response?.names) {
      return (<tr>
        <td colSpan="2" styles={{ textAlign: "center" }}>
          <FontAwesomeIcon icon="spinner" spin />
        </td>
      </tr>)
    } else if (globalContext.additionalNames.response?.names.length === 0) {
      return (<tr>
        <td colSpan="2" styles={{ textAlign: "center" }}>
          No additional names yet
        </td>
      </tr>)
    }
    else {
      return globalContext.additionalNames.response?.names.map(name =>
        (<tr key={name.additionalNameId}>
          <td>{name.name}</td>
          <td>
            <Button variant="danger" onClick={() => onDelete(name.additionalNameId)}>{t('Delete')}</Button>
          </td>
        </tr>)
      )
    }
  }

  return (
    <div className="mt-2 mb-2">
      <Row>
        <Col xs={12}>
          <h2>{t('Additional names of mail that may be sent to your address')}</h2>
          <small>{t('A maximum of 5 names may be entered')}</small>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>
          <FormControl placeholder={t('New Name')} value={newName} onChange={(e) => setNewName(e.target.value)} />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>
          <Button onClick={onSaveName}>{t('Save')}</Button>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>
          <Table striped bordered hover className={Styles.nameTable}>
            <thead>
              <tr>
                <th>{t('Name')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {renderNames()}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};
export default AdditionalNames;
