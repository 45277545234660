import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import SfaAnimation from "sppro-sfa-animations";
import Styles from "./forward.module.css";

const CustomForm = props => {
  return (
    <React.Fragment>
      <Container className={Styles.sentWrapper}>
        <div className={Styles.sentContainer}>
          <div className={Styles.sentWrapper}>
            <img className={Styles.sentImg} src={`/members/images/send.png`} alt="Sent" />
            <Container>
              <Row>
                <Col xs={12}>
                <SfaAnimation alertType='success' />
                  <p>
                    Your mail will be posted within 1 business day
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Button
                  className="mt-3"
                    variant="red"
                    onClick={props.onNext}
                  >
                   Back to inbox
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default CustomForm;
