import React, { useState, useContext, useCallback, useEffect } from 'react';
import GlobalContext from 'context/globalContext';
import { Alert, Form } from 'react-bootstrap';
import qs from 'qs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './login.module.css';
import useEventListener from 'hooks/useEventListener';
import { useTranslation } from 'react-i18next';

const DEFAULT_REDIRECT = '/Members';

function Login(props) {
	const globalContext = useContext(GlobalContext);
	const [login, setLogin] = useState('');
	const [password, setPassword] = useState('');
	const [loginFailed, setLoginFailed] = useState(false);
	const [validationFailed, setValidationFailed] = useState(true);
	const [loading, setLoading] = useState(false);
	const [keepMeSignedIn, setKeepMeSignedIn] = useState(false);
	const [t] = useTranslation();
	const [showMessage, setShowMessage] = useState("");
	const [showLoginGuard, setShowLoginGuard] = useState(false);
	const [showIncorrectLoginGuard, setShowIncorrectLoginGuard] = useState(false);
	const [loginGuard, setLoginGuard] = useState("");
	const handleClick = useCallback(
		(e) => {
			if (e.key === 'Enter') {
				onLogin();
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[login, password]
	);

	useEffect(() => {
		setShowMessage(qs.parse(props?.location?.search, { ignoreQueryPrefix: true }).message);

	}, []);

	useEventListener('keypress', handleClick);

	const onLoginChange = (e) => {
		setLogin(e.target.value);
		setValidationFailed(true);
	};

	const onPasswordChange = (e) => {
		setPassword(e.target.value);
		setValidationFailed(true);
	};

	const onLogin = async () => {
		if (!loading) {
			setLoading(true);
			if (!login || !password) {
				setValidationFailed(false);
			} else {
				let response = await globalContext.login.doLogin(login, password, loginGuard, keepMeSignedIn);
				if (response === null) {
					setLoginFailed(true);
					setLoading(false);
				} 
				else if (response === 'loginGuardRequired') {
					if (showLoginGuard || showIncorrectLoginGuard) {
						setShowIncorrectLoginGuard(true)
						setShowLoginGuard(false);
						setLoading(false);
					} else {
						setShowLoginGuard(true);
						setLoading(false);
					}
				} else {
					globalContext.account.load(response);

					if (response?.language) {
						props.setLanguage(response.language);
					}

					setLoginFailed(false);
					var query = qs.parse(props?.location?.search, { ignoreQueryPrefix: true });

					var redirect = query.redirect;

					if (redirect) {
						window.location.href = redirect;
					} else {
						props.history.push(DEFAULT_REDIRECT);
					}
				}
			}
		}
	};

	return (
		<div className={`${styles.wrapper} ${styles.fadeInDown} ${styles.login}`}>
			<div className={styles.localeSelect}>
				<select className="form-control" value={props.language} onChange={(e) => props.setLanguage(e.target.value)}>
					<option value="en">English</option>
					<option value="jp">日本語 / Japanese</option>
					<option value="zh-CN">普通话 / Chinese (Simplified)</option>
					<option value="zh-TW">國語 / Chinese (Traditional)</option>
				</select>
			</div>
			<div className={styles.formContent}>
				<div className={`${styles.fadeIn} ${styles.first}`}>
					<img src={window.webpageDetails.logo} id="icon" alt="Hotsnail Icon" className={styles.hotsnailIcon} />
				</div>

				<form>
					<input
						type="email"
						name="userName"
						className={`${styles.fadeIn} ${styles.second}`}
						placeholder={t('Email')}
						value={login}
						onChange={onLoginChange}
					/>
					<input
						type="password"
						name="password"
						className={`${styles.fadeIn} ${styles.third}`}
						placeholder={t("Password")}
						value={password}
						onChange={onPasswordChange}
					/>
					{(showLoginGuard || showIncorrectLoginGuard) &&
						<input
							type="text"
							name="loginGuard"
							className={`${styles.third}`}
							placeholder={t("Login guard code")}
							value={loginGuard}
							onChange={(e) => setLoginGuard(e.target.value)}
						/>
					}
					<button type="button" className={`${styles.fadeIn} ${styles.fourth} button-bgcolor`} onClick={onLogin}>
						{!loading ? t('Log In') : <FontAwesomeIcon icon="spinner" spin />}
					</button>
					<Form.Group controlId="formBasicCheckbox">
						<Form.Check type="checkbox" label="Keep me signed in" value={keepMeSignedIn} onChange={(e) => setKeepMeSignedIn(e.target.checked)} />
					</Form.Group>
					{loginFailed && (
						<Alert variant="danger" className={styles.alert}>
							{t("Incorrect email or password")}
						</Alert>
					)}
					{!validationFailed && (
						<Alert variant="danger" className={styles.alert}>
							{t('Email and password can not be blank')}
						</Alert>
					)}
					{showMessage &&
						<Alert variant="success" className={styles.alert}>
							{t(showMessage)}
						</Alert>
					}
					{showLoginGuard &&
						<Alert variant="warning" className={styles.alert}>
							<div>
								Unrecognized device<br />A code has been emailed to you
					</div>
						</Alert>
					}
					{showIncorrectLoginGuard &&
						<Alert variant="danger" className={styles.alert}>
							<div>
								Incorrect login guard code <br /> Please check your email and try again
					</div>
						</Alert>
					}
				</form>

				<div className={`${styles.formFooter}`}>
					<a className={`${styles.underlineHover}`} href="/ForgotPassword">
						{t('Forgot Password?')}
					</a>
				</div>
			</div>
		</div>
	);
}

export default Login;
