import React, { useContext } from "react";
import { Navbar } from "react-bootstrap";
import GlobalContext from "context/globalContext";
import IosMenu from "react-ionicons/lib/IosMenu";
import Styles from "./publicTopNav.module.css";

const TopNav = props => {
    const globalContext = useContext(GlobalContext);

    /* Events */
    const onMobileMenuButtonClick = () => {
        globalContext.events.publish("mobileMenuClick");
    };

    const onGoto = page => {
        switch (page) {
            case "Account":
                props.history.push("/Members/Account");
                break;
            default:
                console.log("On Goto, Nothing Happens");
                break;
        }
    };

    /* Render Methods */


    return (
        <Navbar
            bg="nav"
            expand={false}
            className={Styles.navbar + " fixed-top navbar-light pos-r"}
        >
            <a className={Styles.navbarBrand} href="#1">
                <img
                    src={`${process.env.PUBLIC_URL}/images/${window.webpageDetails.webpageName}-secondary.png`}
                    className="d-inline-block align-top"
                    alt="HotSnail"
                />
            </a>
            <IosMenu
                className={Styles.mobileMenuButton}
            />
            <div>
            </div>
        </Navbar>
    );
};
export default TopNav;
