import {
    GET_NAMES,
    ADD_NAME,
    DELETE_NAME
} from "./additionalNamesActionTypes";

const additionalNamesReducer = (state, action) => {
    switch (action.type) {
        case GET_NAMES:
            return {...state, names: action.payload };
        case ADD_NAME:
            return _addName(state, action);
        case DELETE_NAME:
            return _deleteName(state, action);
        default:
            return state;
    }
};

const _deleteName = (state, action) => {
    let newState = {...state };
    newState.names = newState.names.filter(name => name.additionalNameId !== action.payload);
    return newState;
}

const _addName = (state, action) => {
    let newState = {...state };
    newState.names.push(action.payload);
    return newState;
}
export default additionalNamesReducer;