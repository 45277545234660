import { useReducer } from "react";
import forwardActions from "./forwardActions";
import forwardReducer from "./forwardReducer";

export default function useForward() {
  const [forward, forwardDispatch] = useReducer(forwardReducer, {});
  const { calculate, setMailIds, getLog, cancelForward } = forwardActions(forwardDispatch);

  return {
    response: forward,
    calculate,
    setMailIds,
    getLog, 
    cancelForward
  };
}
