import { useReducer } from "react";
import accountActions from "./accountActions";
import accountReducer from "./accountReducer";

export default function useAccount() {
  const [account, accountDispatch] = useReducer(accountReducer, {});
  const {
    login,
    isEmailAvailable,
    load,
    updateAccount,
    choosePlan,
    updateLoginGuard,
    verifySms,
    registrationComplete,
    updateAutoAction,
    closeAccount,
    updateAutoTopup,
    accountSignup,
    getAccount,
    sendResetPassword,
    resetPassword,
    changePassword,
    saveTermsAndConditions,
    verifyPaypal,
    updateEmailActionNotificationChange,
    updateIncludePdf,
    updateSendSeperate,
    unpause,
    pause
  } = accountActions(accountDispatch);

  return {
    response: account,
    login,
    isEmailAvailable,
    load,
    updateAccount,
    choosePlan,
    updateLoginGuard,
    verifySms,
    registrationComplete,
    updateAutoAction,
    closeAccount,
    updateAutoTopup,
    accountSignup,
    getAccount, 
    sendResetPassword,
    resetPassword,
    changePassword,
    saveTermsAndConditions,
    verifyPaypal,
    updateEmailActionNotificationChange,
    updateIncludePdf,
    updateSendSeperate,
    unpause,
    pause
  };
}
