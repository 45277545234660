import React, { useState } from 'react';
import { Form, Button, Modal, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function AddToForwardModal(props) {
	const [selectedForward, setSelectedForward] = useState(props.forwards[0].forwardId)
	const [onAppendLoading, setOnAppendLoading] = useState(false);
	const [onCreatingNew, setOnCreatingNew] = useState(false);

	const onAppendForward = (selectedForward) => {
		setOnAppendLoading(true);
		props.onAppendForward(selectedForward)
	}

	const onCreatePendingForward = (selectedForward) => {
		setOnCreatingNew(true);
		props.onCreatePendingForward(selectedForward)
	}


	return (
		<Modal show={props.show}>
			<Modal.Header>
				<Modal.Title>Add to pending forward?</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>You already have a pending forward, would you like to combine shipping with these items?</p>
				<Form.Label>Choose pending forward:</Form.Label>
				<Form.Control as="select" value={selectedForward} onChange={(e) => setSelectedForward(e.target.value)} >
					{props.forwards.map(a => <option key={a.forwardId} value={a.forwardId}>{a.address}</option>)}
				</Form.Control>
				<Col xs={12}>
					<ul>
						<li><small style={{ color: "red" }}>Shipping cost may vary</small></li>
						<li><small style={{ color: "green" }}>Hint: You can select more than one item before hitting forward</small></li>
					</ul>
				</Col>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="success" onClick={() => onAppendForward(selectedForward)}>
					{onAppendLoading ? <FontAwesomeIcon icon="spinner" spin /> : "Yes, add these item(s) to my current pending forward"}
				</Button>
				<Button variant="primary" onClick={onCreatePendingForward} >
					{onCreatingNew ? <FontAwesomeIcon icon="spinner" spin /> : "No, create a new pending forward"}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default AddToForwardModal;
