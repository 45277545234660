import React, { useState, useContext, useEffect, useRef } from 'react';
import GlobalContext from 'context/globalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, Row, Col, Button, Form, Alert } from 'react-bootstrap';
import Styles from './settings.module.css';
import { useTranslation } from 'react-i18next';

const DEFAULT_LANGUAGE = "en";

const Settings = (props) => {
	const globalContext = useContext(GlobalContext);
	const [saving, setSaving] = useState(false);
	const [saved, setSaved] = useState(false);
	const [password, setPassword] = useState('');
	const [repeatPassword, setRepeatPassword] = useState('');
	const [repeatPasswordInvalid, setRepeatPasswordInvalid] = useState(false);
	const [email, setEmail] = useState(props?.user?.email || '');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [firstName, setFirstName] = useState(props?.user?.firstName || '');
	const [lastName, setLastName] = useState(props?.user?.lastName || '');
	const [companyName, setCompanyName] = useState('');
	const [accountName, setAccountName] = useState('');
	const [address1, setAddress1] = useState('');
	const [address2, setAddress2] = useState('');
	const [city, setCity] = useState('');
	const [postcode, setPostcode] = useState('');
	const [state, setState] = useState('');
	const [country, setCountry] = useState('');
	const [language, setLanguage] = useState('');
	const [validated, setValidated] = useState(false);
	const [valid, setValid] = useState(true);
	const [emailAvailable, setEmailAvailable] = useState(null);
	const [t] = useTranslation();
	const formRef = useRef(null);

	useEffect(() => {
		setFirstName(props?.user?.firstName || "");
		setLastName(props?.user?.lastName || "");
		if (props?.user?.email) {
			setEmail(props?.user?.email || "");
			if (props?.user?.email) {
				onEmailBlur();
			}
		}
	}, [props.user])

	useEffect(
		() => {
			let account = globalContext.account.response.account;
			if (account) {
				setEmail(account.email || '');
				setPhoneNumber(account.phoneNumber || '');
				setCompanyName(account.companyName || '');
				setFirstName(account.firstName || '');
				setLastName(account.lastName || '');
				setAccountName(account.accountName || '');
				setAddress1(account.address1 || '');
				setAddress2(account.address2 || '');
				setCity(account.city || '');
				setPostcode(account.postcode || '');
				setState(account.state || '');
				setCountry(account.country || '');
				setLanguage(account.language || DEFAULT_LANGUAGE)
			}
		},
		[globalContext.account.response.account]
	);

	const onRepeatPasswordChange = (e) => {
		setRepeatPassword(e.target.value);
		setRepeatPasswordInvalid(false);
	};

	console.log("emailAvailable", emailAvailable);

	const onEmailBlur = async (e) => {
		setEmailAvailable("loading");
		var available = await globalContext.account.isEmailAvailable(email);
		console.log("available", available);
		setEmailAvailable(available);
	}

	const onSave = async () => {
		setSaving(true);
		let accountUser = {
			email,
			phoneNumber,
			companyName,
			firstName,
			lastName,
			accountName,
			address1,
			address2,
			city,
			postcode,
			state,
			country,
			language
		};

		if (props.signup) {
			accountUser.password = password;
			//New User
			await globalContext.account.accountSignup(accountUser);
		} else {
			await globalContext.account.updateAccount(accountUser);
		}

		setSaving(false);

		//Add tick to button
		setSaved(true);

		if (props.onSaved) {
			props.onSaved();
		} else {
			setTimeout(() => {
				//remove tick from button
				setSaved(false);
			}, 2000);
		}
	};

	const renderEmailAvailable = () => {
		if (emailAvailable === null) {
			return "";
		}
		else if (emailAvailable === "loading") {
			return <FontAwesomeIcon icon="spinner" spin />
		}
		else if (emailAvailable === true) {
			return <FontAwesomeIcon icon="check" color="green" />;
		} else {
			return <FontAwesomeIcon icon="times" color="red" />;
		}
	}

	const customValidate = () => {
		let valid = true;
		if (props.signup) {
			if (password !== repeatPassword) {
				setRepeatPasswordInvalid(true);
				valid = false;
			}
		}
		return valid;
	};

	const onChangeLanguage = (lng) => {
		props.setLanguage(lng)
		setLanguage(lng);
	}

	const onSubmit = async (event) => {
		const form = formRef.current;

		if (((form.checkValidity() === false) |
			(customValidate() === false)) ||
			(props.signup && !emailAvailable) 
		) {
			event.preventDefault();
			event.stopPropagation();
			setValid(false);
		} else {
			setValid(true);
			setValidated(true);
			await onSave();
		}

	};

	return (
		<Form noValidate validated={validated} className={`mt-2 ${Styles.settingWrapper}`} ref={formRef}>
			{!props.signup && <h1>{t('Contact Information')}</h1>}
			<div>
				{props.signup ? (
					//From Sign Up Page
					<React.Fragment>
						<Row>
							<Form.Group as={Col} xs="12">
								<Form.Label>{t('Language')}</Form.Label>
								<br />
								<select
									className={`form-control ${Styles.languageSelect}`}
									value={props.language}
									onChange={(e) => onChangeLanguage(e.target.value)}
								>
									<option value="en">English</option>
									<option value="jp">日本語 / Japanese</option>
									<option value="zh-CN">普通话 / Chinese (Simplified)</option>
									<option value="zh-TW">國語 / Chinese (Traditional)</option>
								</select>
							</Form.Group>
						</Row>

						<Row>
							<Form.Group as={Col} xs="12">
								<Form.Label>{t('Email Address')} {renderEmailAvailable()}</Form.Label>
								<br />
								<FormControl type="email" value={email} onChange={(e) => setEmail(e.target.value)} onBlur={onEmailBlur} required />
								<Form.Control.Feedback type="invalid">{t('Email is invalid or not available')}</Form.Control.Feedback>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} xs="12" md="6">
								<Form.Label>{t('Password')}</Form.Label>
								<Form.Control
									type="password"
									placeholder={t('Password')}
									required
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								<Form.Control.Feedback type="invalid">{t('Can not be blank')}</Form.Control.Feedback>
							</Form.Group>

							<Form.Group as={Col} xs="12" md="6">
								<Form.Label>{t('Re-enter Password')}</Form.Label>
								<Form.Control
									type="password"
									placeholder={t("Password")}
									value={repeatPassword}
									onChange={onRepeatPasswordChange}
									isInvalid={repeatPasswordInvalid}
								/>
								<Form.Control.Feedback type="invalid">{t('Must match password')}</Form.Control.Feedback>
							</Form.Group>
						</Row>
					</React.Fragment>
				) : (
					// From My Account

					<React.Fragment>
						<Form.Label>{t('Language')}</Form.Label>
						<br />
						<select
							className={`form-control`}
							value={language}
							onChange={(e) => onChangeLanguage(e.target.value)}

						>
							<option value="en">English</option>
							<option value="jp">日本語 / Japanese</option>
							<option value="zh-CN">普通话 / Chinese (Simplified)</option>
							<option value="zh-TW">國語 / Chinese (Traditional)</option>

						</select>

						<Form.Label>{t('Email Address')}</Form.Label>
						<br />
						<FormControl type="email" value={email} readOnly />
					</React.Fragment>
				)}
				<Form.Label>{t('Phone Number')}</Form.Label>
				<FormControl type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
				<Row>
					<Form.Group as={Col} xs="12" md="6">
						<Form.Label>{t('First name')}</Form.Label>
						<Form.Control
							type="text"
							placeholder={t("First name")}
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
							required
						/>
						<Form.Control.Feedback type="invalid">{t('Can not be blank')}</Form.Control.Feedback>
					</Form.Group>

					<Form.Group as={Col} xs="12" md="6">
						<Form.Label>{t('Last name')}</Form.Label>
						<Form.Control
							type="text"
							placeholder={t("Last name")}
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
							required
						/>
						<Form.Control.Feedback type="invalid">{t('Can not be blank')}</Form.Control.Feedback>
					</Form.Group>
				</Row>
				<Row>
					<Form.Group as={Col} xs="12" md="6">
						<Form.Label>{t('Company Name')}</Form.Label>
						<FormControl type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
					</Form.Group>
				</Row>
			</div>
			<hr />
			<h1>{t('Billing Information')}</h1>
			<small>
				{t('Used for billing. This address is not used as your forwarding address and must match your credit card')}
			</small>
			<div>

				<Row>
					<Form.Group as={Col} xs="12">
						<Form.Label>{t('Name')}</Form.Label>
						<Form.Control
							type="text"
							placeholder={t("Name")}
							value={accountName}
							onChange={(e) => setAccountName(e.target.value)}
							required
						/>
						<Form.Control.Feedback type="invalid">{t('Can not be blank')}</Form.Control.Feedback>
					</Form.Group>
				</Row>

				<h2>{t('Address')}</h2>
				<FormControl
					type="text"
					placeholder={t("Address line 1")}
					value={address1}
					onChange={(e) => setAddress1(e.target.value)}
				/>
				<FormControl
					type="text"
					className="mt-1"
					placeholder={t("Address line 2")}
					value={address2}
					onChange={(e) => setAddress2(e.target.value)}
				/>
				<Row className={props.signup ? "" : "mt-1"}>
					<Col xs={12} md={6}>
						<FormControl
							type="text"
							placeholder={t("City")}
							value={city}
							onChange={(e) => setCity(e.target.value)}
						/>
					</Col>
					<Col xs={12} md={6}>
						<FormControl
							type="text"
							placeholder={t("Post Code")}
							value={postcode}
							onChange={(e) => setPostcode(e.target.value)}
						/>
					</Col>
				</Row>
				<Row className={props.signup ? "" : "mt-1"}>
					<Col xs={12} md={6}>
						<FormControl
							type="text"
							placeholder={t("State")}
							value={state}
							onChange={(e) => setState(e.target.value)}
						/>
					</Col>
					<Col xs={12} md={6}>
						<FormControl
							type="text"
							placeholder={t("Country")}
							value={country}
							onChange={(e) => setCountry(e.target.value)}
						/>
					</Col>
				</Row>
			</div>
			{!props.signup && (
				<div>
					<Row>
						<Form.Group as={Col} xs="12">
							<hr></hr>

							<Form.Label>{t('Language')}</Form.Label>
							<br />
							<select
								className={`form-control`}
							>
								<option>English</option>
								<option>日本語 / Japanese</option>
							</select>
						</Form.Group>
					</Row>
				</div>)
			}
			{!valid && (
				<Row className="mt-2">
					<Col xs={12}>
						<Alert variant="danger">{t('Some fields were invalid')}</Alert>
					</Col>
				</Row>
			)}
			<Row className="mt-2">
				<Col xs={12}>
					<Button className={Styles.saveButton} onClick={onSubmit}>
						{saving ? (
							<FontAwesomeIcon icon="spinner" spin />
						) : saved ? (
							<FontAwesomeIcon icon="check" />
						) : (
							t('Save')
						)}
					</Button>
				</Col>
			</Row>
		</Form>
	);
};
export default Settings;
