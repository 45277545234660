import React, { useState, useContext, useEffect } from "react";
import { FormControl, Button, Row, Col, Alert } from "react-bootstrap";
import GlobalContext from "context/globalContext";
import SweetAlert from "sweetalert2-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as verificationStatus from "enums/verificationEnum";
import styles from "./verification.module.css";
import { useTranslation } from 'react-i18next';

const DEFAULT_MOBILE = "+61";
const DEFAULT_SEND_LABEL = "Email Verification";

const Verification = props => {
  const [showSmsCode, setShowSmsCode] = useState(false);
  const [smsCode, setSmsCode] = useState("");
  const [sendingMsg, setSendingMsg] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(DEFAULT_MOBILE);
  const [showIncorrectCode, setShowIncorrectCode] = useState(false);
  const [warningMsg, setWarningMsg] = useState("");
  const { t  } = useTranslation();

  const [sendingVerificationEmail, setSendingVerificationEmail] = useState(
    false
  );
  const [sendVerificationLabel, setSendVerificationLabel] = useState(
    DEFAULT_SEND_LABEL
  );
  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    const getVerification = async () => {
      globalContext.verification.getVerificationStatus();
    };
    getVerification();
    // eslint-disable-next-line
  }, []);

  const gotoVerificationWizard = () => {
    props.history.push("/Members/PhotoVerification");
  };

  const onSendSms = async () => {
    if (!sendingMsg) {
      setSendingMsg(true);
      var success = await globalContext.verification.smsVerification(
        mobileNumber
      );
      setSendingMsg(false);
      if (success.success) {
        setWarningMsg("");
        setShowSmsCode(true);
      }else{
        setWarningMsg(success.message);
      }
    }
  };

  const onMobileChange = e => {
    var newValue = e.target.value;
    if (newValue.substring(0, 3) === DEFAULT_MOBILE) {
      setMobileNumber(e.target.value);
    }
  };

  const onCodeChange = e => {
    setSmsCode(e.target.value);
  };

  const onCancelClick = e => {
    setShowSmsCode(false);
    setMobileNumber(DEFAULT_MOBILE);
  };

  const onVerify = async e => {
    let verified = await globalContext.account.verifySms(smsCode);
    if (!verified) {
      setShowIncorrectCode(true);
    }else {
      globalContext.verification.verifySms();
    }
  };
  
  const onRedoIdVerification = async () => {
    await globalContext.verification.deleteIdVerification();
  }

  const isMobileVerified = globalContext
  ?.verification
  ?.response
  ?.verifications
  ?.smsVerification === verificationStatus.Accepted;

  const isIdVerified = globalContext
  ?.verification
  ?.response
  ?.verifications
  ?.idVerification;

  if (!globalContext
    ?.verification
    ?.response
    ?.verifications){
      return <div></div>
    }

  return (
    <div className="mt-2 mb-2">
      <Row>
        <Col xs={12}>
          <h2>{t('Australian Mobile Verification')}</h2>
          <small>
            {t("If you're currently in Australia, we'll SMS you a security code")}
          </small>
          {isMobileVerified && (
            <div className="text-success mt-2">
              <FontAwesomeIcon icon="clipboard-check" /> {t('Mobile verified')}
            </div>
          )}

          {!isMobileVerified && (
            <React.Fragment>
              <FormControl
                placeholder={t("Mobile Number")}
                className="mt-2"
                disabled={showSmsCode}
                value={mobileNumber}
                onChange={onMobileChange}
              />
              {showSmsCode && (
                <React.Fragment>
                  <FormControl
                    placeholder={t("Enter Code")}
                    className="mt-2"
                    value={smsCode}
                    onChange={onCodeChange}
                  />
                  <Button className="mt-2" onClick={onVerify}>
                    {t('Verify')}
                  </Button>
                </React.Fragment>
              )}

              {!showSmsCode && (
                <Button className="mt-2" onClick={onSendSms}>
                  {sendingMsg ? t("Sending") + "..." : t("Send")}
                </Button>
              )}
              {showSmsCode && (
                <Button
                  className="mt-2 ml-2"
                  variant="danger"
                  onClick={onCancelClick}
                >
                  {t('Cancel')}
                </Button>
              )}

            </React.Fragment>
          )}
        </Col>
      </Row>

      {warningMsg &&
        <Alert variant="danger" className="mt-2">{warningMsg}</Alert>
      }

      <Row className="mt-2">
        <Col xs={12}>
          <hr></hr>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h2>
            {t('Photo Identity Verification')} &nbsp;{" "}
            <small>({t('Required for international forwarding')})</small>
          </h2>
          <small>
            {t('Upload a scanned copy of your government issued identification & a selfie with code')} {globalContext.account.response.account.verificationCode}
          </small>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
        {isIdVerified === verificationStatus.NotSubmitted && (
          <React.Fragment>
          <Button
            onClick={() => gotoVerificationWizard()}
            className="mt-2"
          >
                      <img src={`/members/images/idVerification.png`} alt="selfie with code"></img>
                      <div className="mt-2">{t('Verify identity')}</div>          </Button>
          </React.Fragment>)
        }
                {isIdVerified === verificationStatus.Pending && (
                      <div className="text-warning mt-2">
                      <FontAwesomeIcon icon="clock" /> {t('Pending Verification')} 
                      <a href="#" className={styles.deleteLink} onClick={onRedoIdVerification}>({t('Click here to redo')})</a>
                    </div>
        
        )}

        {isIdVerified === verificationStatus.Accepted && (
                      <div className="text-success mt-2">
                      <FontAwesomeIcon icon="clipboard-check" /> {t('ID verified')}
                    </div>
        
        )}
        </Col>
      </Row>
      <SweetAlert
        show={showIncorrectCode}
        onConfirm={() => setShowIncorrectCode(false)}
        type="error"
        text="Incorrect code"
      />
    </div>
  );
};
export default Verification;
