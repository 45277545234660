import React, { useState } from 'react';
import { Row, Dropdown } from 'react-bootstrap';
import Moment from 'react-moment';
import MailIcon from './MailIcon';
import mailStatusUtility from 'utilities/mailStatusUtility';
import { Checkbox } from 'pretty-checkbox-react';
import Styles from './mailItem.module.css';
import mailActionUtility from 'utilities/mailActionUtility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const MailItem = (props) => {
	const [ mailName, setMailName ] = useState(props.item.name);
	const [ saving, setSaving ] = useState(false);
	const [ editable, setEditable ] = useState(false);
	const [ t ] = useTranslation();

	const onNameChangeBlured = async () => {
		setSaving(true);
		await props.onUpdateName(props.item.mailId, mailName);
		setSaving(false);
		setEditable(false);
	};

	const onEditableClick = (e) => {
		e.stopPropagation();
		setEditable(!editable);
	};

	const onClick = (e) => {
		if (e.target.type === 'checkbox' || e.target.className === Styles.nameSpan) {
			props.onClick(props.item);
		}else{
			props.setActive(props.item)
		}
	};

	const onCancel = () => {
		props.onCancel(props.item.mailId);
	};

	const onCheckedChanged = (e) => {
		//This is handled by onclick above.
		//Only put here to prevent warning1
	};

	const mailOnFile = () => {
		return props.item.status === 2 || props.item.status === 3;
	};

	const pendingAction = () => {
		return !(props.item.mailActions === null);
	};

	//Ellipsis Button  ***************THIS IS CAUSING BUG ******************8
	const CustomDropdownButton = React.forwardRef(({ children, onClick }, ref) => (
		// eslint-disable-next-line
		<a
			href="#"
			ref={ref}
			className={Styles.editNameButton}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			<FontAwesomeIcon icon="ellipsis-v" />
		</a>
	));

	return (
		<li className={`${Styles.mailListItem} ${props.active ? Styles.mailActive : ''}`}>
			{/* <ContextMenuTrigger id="mail-context-menu" collect={collect}> */}
			<Row className="p-2">
				<div onClick={onClick} className={[ Styles.peers, Styles.checkbox, Styles.mailWrapper ].join(' ')}>
					<div className={`col-12 ${Styles.mailItem}`}>
						<div className={Styles.peers}>
							<div className={[ Styles.peer, Styles.peerGreed ].join(' ')}>
								<div className={Styles.peers}>
									<div className={[ Styles.peer, Styles.peerGreed, Styles.nameLine ].join(' ')}>
										<Checkbox
											shape="round"
											color="primary"
											className={Styles.mailItemCheck}
											icon={<i className="mdi mdi-check" />}
											animation="jelly"
											checked={props.isChecked}
											onChange={onCheckedChanged}
										/>
										{!editable ? (
											<input
												type="text"
												className={`${Styles.nameSpan} ${props.item.read
													? Styles.read
													: Styles.unread}`}
												disabled
												value={mailName}
											/>
										) : (
											<input
												type="text"
												className={`${Styles.nameInput} ${saving ? Styles.saving : ''}`}
												value={mailName}
												onChange={(e) => setMailName(e.target.value)}
												onBlur={(e) => onNameChangeBlured()}
											/>
										)}
										{!editable ? (
											<span className={Styles.editNameButton}>
												<Dropdown className={Styles.dropdown}>
													<Dropdown.Toggle variant="default" className={Styles.contextButton} /> 
													{/* <Dropdown.Toggle as={CustomDropdownButton} />  */}
													<Dropdown.Menu>
														<Dropdown.Item onClick={onEditableClick}>
															<FontAwesomeIcon icon="tag" className="mr-2" />{t('Rename')}
														</Dropdown.Item>
														{mailOnFile() && (
															<Dropdown.Item onClick={props.onRescan}>
																<FontAwesomeIcon icon="redo" className="mr-2" />{t('Re-scan')}
															</Dropdown.Item>
														)}
														{pendingAction() && (
															<Dropdown.Item onClick={onCancel}>
																<FontAwesomeIcon icon="minus-circle" className="mr-2" />{t('Cancel')}{" "}
																{t('Action')}
															</Dropdown.Item>
														)}
													</Dropdown.Menu>
												</Dropdown>
											</span>
										) : (
											<span>
												<FontAwesomeIcon
													icon="check"
													className={Styles.saveNameButton}
													onClick={() => onNameChangeBlured()}
												/>
											</span>
										)}
									</div>
									<div className={[ Styles.peer, Styles.mailStatus ].join(' ')}>
										<small className={Styles.emailDdate}>
											{' '}
											<Moment format="DD/MM/YY">{props.item.dateScanned}</Moment>
										</small>{' '}
										|<small>{props.item.weight}g</small>
										<span className={Styles.mailIdSmallScreen}>| <small>Mail Id: {props.item.mailId}</small></span>
									</div>
								</div>
								<span className={Styles.mailMessage + ' w-100'}>{props.item.blurb}</span>
							</div>
						</div>
						<div className={Styles.mailId}>
							Mail Id: {props.item.mailId}
						</div>
						<div className={Styles.mailStatusLabel}>
							{t(mailStatusUtility.getFriendlyName(props.item.status, props.item.mailActions))}
							{props.item.mailActions !== null &&
							props.item.mailActions !== 2 &&
							props.item.mailActions !== 3 ? (
								<span>
									{' '}
									|{' '}
									<span className="red">
										{t(mailActionUtility.getFriendlyName(props.item.mailActions))}
									</span>
								</span>
							) : (
								''
							)}
							<small
								className={Styles.StatusIcon}
								data-toggle="tooltip"
								data-placement="bottom"
								title={t(mailStatusUtility.getFriendlyName(props.item.status, props.item.mailActions))}
							>
								<MailIcon
									mailType={props.item.mailType}
									status={props.item.status}
									mailAction={props.item.mailActions}
								/>
							</small>
						</div>
					</div>
				</div>
			</Row>
			{/* </ContextMenuTrigger> */}
		</li>
	);
};

export default MailItem;
