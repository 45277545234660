import axios from "axios";
import {
  GET_METRICS,
} from "./storageActionTypes";

export default dispatch => {
  const getMetrics = async ()  => {
    var metrics = await _getMetrics();
    dispatch({
      type: GET_METRICS,
      payload: metrics
    })
  };
  return { getMetrics };
};

const _getMetrics = async () => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/StorageMetrics/GetMetrics`,
      method: "GET",
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(response => {
        console.log("Error getting metrics", response);
        reject();
      });
  });
};

