import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import 'react-credit-cards/es/styles-compiled.css';
import Styles from './paymentForm.module.css';
import Cards from 'react-credit-cards';
import GlobalContext from 'context/globalContext';
import useStripe from 'hooks/useStripe';
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from 'utilities/paymentUtility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import currencyUtility from 'utilities/currencyUtility';
import * as config from 'config.js';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const DEFAULT_AUTOTOPUP_AMOUNT = 20;

const PaymentForm = (props) => {
	const globalContext = useContext(GlobalContext);
	const [ nameOnCard, setNameOnCard ] = useState('');
	const [ cardNumber, setCardNumber ] = useState('•••• •••• •••• ••••');
	const [ expiry, setExpiry ] = useState('');
	const [ autoTopUpSaving, setAutoTopUpSaving ] = useState(false);
	const [ autoTopUpAmount, setAutoTopUpAmountState ] = useState(
		globalContext.account.response.account.autoTopUpAmount || 
		globalContext?.plan?.response?.plans?.filter((a) => a.planId ===  globalContext?.account?.response?.account?.planId)[0]?.minimumMailDeposit || 
		window.webpageDetails.autoTopUp
	);
	const [ t ] = useTranslation();

	// eslint-disable-next-line
	const [ cvc, setCvc ] = useState('');
	const [ focus, setFocus ] = useState('number');
	const [ issuer, setIssuer ] = useState();
	const [ stripeElements, createToken ] = useStripe(config.StripeApiKey);
	const [ error, setError ] = useState();
	const [ saving, setSaving ] = useState(false);

	useEffect(
		() => {
			setFocus(stripeElements.focused);
			if (stripeElements.cardNumberChange) {
				setIssuer(stripeElements.cardNumberChange.brand);
			}
		},
		[ stripeElements ]
	);

	const setAutoTopUpAmount = async (amount) => {
		setAutoTopUpSaving(true);
		setAutoTopUpAmountState(amount);
		await globalContext.account.updateAutoTopup(amount);
		setAutoTopUpSaving(false);
	};

	const onInputChange = ({ target }) => {
		if (target.name === 'number') {
			target.value = formatCreditCardNumber(target.value);
			setCardNumber(target.value);
		} else if (target.name === 'expiry') {
			target.value = formatExpirationDate(target.value);
			setExpiry(target.value);
		} else if (target.name === 'cvc') {
			target.value = formatCVC(target.value);
			setCvc(target.value);
		} else if (target.name === 'name') {
			setNameOnCard(target.value);
		}
	};

	const getPlan = () => {
		if (!globalContext?.plan?.response?.plans){
			return null;
		}
		
		const planId = globalContext?.account?.response?.account?.planId;
		
		if (!planId)
			return null;
			
		return globalContext.plan.response.plans.filter((a) => a.planId === planId)[0];
	};

	const getTodaysCharge = () => {
		var plan = getPlan();
		if (plan){
			return plan.setupFee > autoTopUpAmount ? plan.setupFee : autoTopUpAmount;
		}
		return 0;
	};

	const getMonthlyCharge = () => {
		var plan = getPlan();
		if (plan){
			return plan.monthlyFee;
		}
		return 0;
	};

	const getSetupFee = () => {
		var plan = getPlan();
		if (plan) {
			return getPlan().setupFee;
		}
		return 0;
	};

	const onClick = async (e) => {
		setError("");
		setSaving(true);
		try {
			let result = null;
			if (issuer) {
				result = await createToken();
			}
			let tokenId = null;
			if (result) {
				tokenId = result.token.id;
			}

			try {
				var updatePayment = await globalContext.payment.updatePayment(nameOnCard, autoTopUpAmount, tokenId, props.signup);
				if (!updatePayment){
					setError(t('Unable to verify credit card. Please try again.'));
				}
				else {
				if (props.afterSave && result) {
					setNameOnCard("");
					stripeElements.cardNumber.clear();
					stripeElements.cardExpiry.clear();
					stripeElements.cardCvc.clear();
					props.afterSave(updatePayment);
				}
			}
			} catch (ex) {
				setError('Error updating credit card');
			}
		} catch (ex) {
			setError(ex.error.message);
			console.log('ex', ex);
		}
		setSaving(false);
	};

	return (
		<div className="mt-2 mb-2">
			<div className="row">
				<Col lg={6} className={Styles.paymentWrapper}>
					<div className="tab-content">
						{false && <p className="alert alert-success">success message</p>}
						{error && <Alert variant="danger">{error}</Alert>}
						<div role="form">
							{/* Not all business types will have a auto top up amount */}
							{props.signup && window.webpageDetails.includeAutoTopup && 
								(
								<div className="form-group">
									<label htmlFor="username">{t('Auto top-up amount')}</label>

									<select
										className={`form-control`}
										value={'$' + autoTopUpAmount}
										disabled={autoTopUpSaving}
										onChange={(e) =>
											setAutoTopUpAmount(parseFloat(e.target.value.replace('$', '')))}
									>
										{getPlan().minimumMailDeposit < 50 && <option>${getPlan().minimumMailDeposit}</option>}
										<option>$50</option>
										<option>$75</option>
										<option>$100</option>
										<option>$150</option>
										<option>$200</option>
									</select>
								</div>
							)}

							<div className="form-group">
								<label htmlFor="username">{t('Full name (on the card)')}</label>
								<input
									type="text"
									name="name"
									placeholder={t("Name on card")}
									required
									className="form-control not-wizard"
									value={nameOnCard}
									onChange={onInputChange}
									onFocus={(e) => setFocus('name')}
								/>
							</div>
							<div className="form-group">
								<label htmlFor="cardNumber">{t('Card number')}</label>
								<div id="card-numbers" className="form-control" />
							</div>
							<div className="row">
								<div className="col-sm-8">
									<div className="form-group">
										<label>
											<span className="hidden-xs">{t('Expiration')}</span>
										</label>
										<div className="input-group">
											<div id="card-expiry" className="form-control" />
										</div>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="form-group mb-4">
										<label data-toggle="tooltip" title={t("Three-digits code on the back of your card")}>
											{t('CVC')}
											<i className="fa fa-question-circle" />
										</label>
										<div id="card-cvc" className="form-control" />
									</div>
								</div>
							</div>
							<button
								type="button"
								className="subscribe btn btn-primary btn-block rounded-pill shadow-sm"
								onClick={onClick}
							>
								{saving ? <FontAwesomeIcon icon="spinner" spin /> : t('Confirm')}
							</button>
						</div>
					</div>
				</Col>
				<Col lg={6}>
					<Row className="d-none d-lg-block">
						<Col xs={12}>
							<Cards
								cvc="•••"
								expiry={expiry}
								focused={focus}
								name={nameOnCard}
								number={cardNumber}
								issuer={issuer}
								preview={true}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12}>
							<table className={Styles.paymentDetailsTable + ' table'}>
								<tbody>
									{props.signup && (
										<React.Fragment>
											<tr>
												<td>{t("Setup Fee")}</td>
												<td>{currencyUtility.formatCurrency(getSetupFee())}</td>
											</tr>

											<tr>
												<td>{t("Today's charge")} <small>({(t('Inc. Setup Fee and Prepaid Credit'))})</small></td>
												<td>{currencyUtility.formatCurrency(getTodaysCharge())}</td>
											</tr>
										</React.Fragment>
									)}
									{!props.signup && window.webpageDetails.includeAutoTopup && (
										<tr>
											<td>{t('Auto top-up amount')}</td>
											<td>
												<select
													className={`form-control ${Styles.autoTopUpSelect}`}
													value={'$' + autoTopUpAmount}
													disabled={autoTopUpSaving}
													onChange={(e) =>
														setAutoTopUpAmount(parseFloat(e.target.value.replace('$', '')))}
												>
													<option>$20</option>
													<option>$30</option>
													<option>$50</option>
													<option>$75</option>
													<option>$100</option>
													<option>$150</option>
													<option>$200</option>
												</select>
												{autoTopUpSaving && <FontAwesomeIcon icon="spinner" spin />}
											</td>
										</tr>
									)}
									{
									window.webpageDetails.showMonthlyCharge &&
									<tr>
										<td>{t('Minimum monthly charge')}</td>
										<td>{currencyUtility.formatCurrency(getMonthlyCharge())}</td>
									</tr>
									}
									{!props.signup && (
										<tr>
											<td>{t('Billing Period')}</td>
											<td>
												{globalContext.account.response.account.billingDate ? (
													moment(globalContext.account.response.account.billingDate).format(
														'Do'
													) + ' ' + t('of each month')
												) : (
													new moment().format(
														'Do'
													) + ' ' + t('of each month')
												)}
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</Col>
					</Row>
				</Col>
			</div>
		</div>
	);
};
export default PaymentForm;
