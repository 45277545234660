import React, { useState, useContext, useCallback } from 'react';
import loginStyles from './login.module.css';
import styles from './loginChoice.module.css';

function LoginChoice(props) {

	const onLogin = () => {
		props.history.push('/Login');
	};

	return (
		<div className={`${loginStyles.wrapper} ${loginStyles.fadeInDown} ${loginStyles.login}`}>
			<div className={loginStyles.formContent}>
				<form>
					<button
						type="button"
						className={`${loginStyles.fadeIn} ${loginStyles.fourth} ${styles.v1Button} button-bgcolor`}
						onClick={() => window.location.href = "https://secure.hotsnail.com.au/" }
					>
						<img
							src={`/members/images/HotSnailV1.png`}
							id="icon"
							alt="Hotsnail Icon"
							className={`${loginStyles.hotsnailIcon} button-bgcolor`}
						/>
						HotSnail Classic<br /> <small> (Read only)</small>
					</button>

					<button
						type="button"
						className={`${loginStyles.fadeIn} ${loginStyles.fourth} ${loginStyles.signUpButton} ${styles.v2Button} button-bgcolor`}
						onClick={onLogin}
					>
						<div className={`${loginStyles.fadeIn} ${loginStyles.first}`}>
							<img
								src={window.webpageDetails.logo}
								id="icon"
								alt="Hotsnail Icon"
								className={loginStyles.hotsnailIcon}
							/>
                             Version 2 
						</div>
					</button>
				</form>
			</div>
		</div>
	);
}

export default LoginChoice;
