import React, { useState, useContext } from 'react';
import TopNav from 'components/members/topnav';
import SelectAddress from './SelectAddress';
import ServiceSelect from './ServiceSelect';
import CustomForm from './CustomForm';
import Sent from './Sent';
import mailService from 'services/mailService';
import Sending from './Sending';
import Error from './Error';
import GlobalContext from 'context/globalContext';

const PAGES = {
	selectAddress: 'selectAddress',
	serviceSelect: 'serviceSelect',
	customForm: 'customForm',
	sent: 'sent',
	error: 'error',
	sending: 'sending'
};

const Forward = (props) => {
	const [ currentPage, setCurrentPage ] = useState(PAGES.selectAddress);
	const [ forwardDetails, setForwardDetails ] = useState({});
	const globalContext = useContext(GlobalContext);
	const [ forwardFormSaved, setForwardFormSaved ] = useState();

	if (!globalContext.forward.response.mailIds || globalContext.forward.response.mailIds.length === 0) {
		props.history.push('/Members');
	}

	const saveForward = async (forwardForm) => {
		setCurrentPage(PAGES.sending);
		var success = await mailService.forwardMail(forwardForm);
		if (success) {
			setCurrentPage(PAGES.sent);
		} else {
			//incase they click try again
			setForwardFormSaved(forwardForm);
			setCurrentPage(PAGES.error);
		}
	};

	const onSelectAddressNext = (selectedAddress) => {
		setForwardDetails({ addressId: parseInt(selectedAddress) });
		setCurrentPage(PAGES.serviceSelect);
	};

	const onSelectAddressBack = () => {
		props.history.push('/Members');
	};

	const onServiceSelectNext = (service) => {
		setForwardDetails({ ...forwardDetails, ...service });
		
		if (!globalContext.forward?.response?.list.service[0]?.isDomestic || globalContext.forward?.response?.list.service[0]?.isParcel) {
			setCurrentPage(PAGES.customForm);
		} else {
			saveForward({
				...forwardDetails,
				mailIds: globalContext.forward.response.mailIds.map((mail) => mail.mailId),
				...service
			});
		}
	};

	const onServiceSelectBack = () => {
		setCurrentPage(PAGES.selectAddress);
	};

	const onCustomFormNext = (customForm) => {
		// setForwardDetails({...forwardDetails, customForm});
		saveForward({
			...forwardDetails,
			mailIds: globalContext.forward.response.mailIds.map((mail) => mail.mailId),
			customForm
		});
	};

	const onCustomFormBack = () => {
		setCurrentPage(PAGES.serviceSelect);
	};

	return (
		<React.Fragment>
			<TopNav history={props.history} />
			{currentPage === PAGES.selectAddress && (
				<SelectAddress onNext={onSelectAddressNext} onBack={onSelectAddressBack} />
			)}

			{currentPage === PAGES.serviceSelect && (
				<ServiceSelect
					onNext={onServiceSelectNext}
					onBack={onServiceSelectBack}
					addressId={forwardDetails.addressId}
				/>
			)}

			{currentPage === PAGES.customForm && <CustomForm 
			onNext={onCustomFormNext} 
			onBack={onCustomFormBack}
			domestic={globalContext.forward?.response?.list.service[0]?.isDomestic}
			/>}

			{currentPage === PAGES.sending && <Sending />}

			{currentPage === PAGES.error && (
				<Error history={props.history} onTryAgain={() => saveForward(forwardFormSaved)} />
			)}

			{currentPage === PAGES.sent && <Sent onNext={() => props.history.push('/Members')} />}
		</React.Fragment>
	);
};
export default Forward;
