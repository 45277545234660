import {
  GET_LOCATIONS,
  GET_USER_LOCATIONS
} from "./locationActionTypes";

const locationReducer = (state, action) => {
  switch (action.type) {
    case GET_LOCATIONS:
      return { ...state, locations : action.payload }
    case GET_USER_LOCATIONS:
      return { ...state, userLocations: action.payload }
    default:
      return state;
  }
};

export default locationReducer;
