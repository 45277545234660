import { useReducer } from "react";
import additionalNamesActions from "./additionalNamesActions";
import additionalNamesReducer from "./additionalNamesReducer";

export default function useAdditionalNamesSettings() {
    const [names, additionalNamesDispatch] = useReducer(additionalNamesReducer, {});
    const { getNames, saveName, deleteName } = additionalNamesActions(additionalNamesDispatch);

    return {
        response: names,
        getNames,
        saveName,
        deleteName
    };
}