import { FETCH_ADDRESSES, ADD_ADDRESS, REMOVE_ADDRESS } from './addressActionTypes';

const addressReducer = (state, action) => {
	switch (action.type) {
		case FETCH_ADDRESSES:
			return {
				...state,
				domestic: action.payload.filter((a) => a.domestic),
				international: action.payload.filter((a) => !a.domestic),
				loaded: true
			};
		case ADD_ADDRESS:
			let domesticList = [ ...state.domestic ];
			let internationalList = [ ...state.international ];

			if (action.payload.domestic) {
				domesticList = domesticList.filter((a) => a.addressId !== action.payload.addressId);
				domesticList.push(action.payload);
			} else {
				internationalList = internationalList.filter((a) => a.addressId !== action.payload.addressId);
				internationalList.push(action.payload);
			}
			return {
				...state,
				domestic: domesticList,
				international: internationalList
			};
		case REMOVE_ADDRESS:
			return _removeAddress(state, action);
		default:
			return state;
	}
};

const _removeAddress = (state, action) => {
	let domesticList = [ ...state.domestic ];
	let internationalList = [ ...state.international ];
	domesticList = domesticList.filter((a) => parseInt(a.addressId) !== parseInt(action.payload));
	internationalList = internationalList.filter((a) => parseInt(a.addressId) !== parseInt(action.payload));
	return {
		...state,
		domestic: domesticList,
		international: internationalList
	};
};

export default addressReducer;
