import { useReducer } from "react";
import planActions from "./planActions";
import planReducer from "./planReducer";

export default function usePlan() {
  const [plan, planDispatch] = useReducer(planReducer, {response: []});
  const { getAll } = planActions(planDispatch);

  return {
    response: plan,
    getAll
  };
}
