import {
  GET_ALL,
} from "./planActionTypes";

const planReducer = (state, action) => {
  switch (action.type) {
    case GET_ALL:
      return { plans: action.payload }
    default:
      return state;
  }
};

export default planReducer;
