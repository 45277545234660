import {
    GET_AUDIT
} from "./auditActionTypes";

const auditReducer = (state, action) => {
    switch (action.type) {
        case GET_AUDIT:
            let newAuditLog = state?.auditLog || [];
            //Reset audit log if getting from page 0
            if (action.payload.page === 0) {
                newAuditLog = [];
            }
            newAuditLog = newAuditLog.concat(action.payload.log);
            return {...state, auditLog: newAuditLog };
        default:
            return state;
    }
};

export default auditReducer;