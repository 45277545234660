import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Button } from "react-bootstrap";
import logoUtility from 'utilities/logoUtility';
import Styles from "./service.module.css";
import GrowingTextArea from 'components/elements/GrowingTextArea';

const Service = props => {
  const [showFeatures, setShowFeatures] = useState(false);
  const [notes, setNotes] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  let price = parseFloat(props.price);

  const onOpenFeatureClick = () => {
    setShowFeatures(!showFeatures);
  };

  const onOptionsChanged = (e, code) => {
    let newSelectedOptions = [...selectedOptions];
    if (e.target.checked && selectedOptions.indexOf(code) === -1) {
      newSelectedOptions.push(code);
    } else if (!e.target.checked && selectedOptions.indexOf(code) > -1) {
      newSelectedOptions = newSelectedOptions.filter(a => a !== code);
    }
    setSelectedOptions(newSelectedOptions);
  };

  const onSend = () => {
    console.log("notes");
    props.onNext({
      service: props.name,
      serviceCode: props.code,
      options: selectedOptions,
      hotsnailFee: parseFloat(props.hotsnailFee.toFixed(2)),
      ausPostFee: parseFloat(price.toFixed(2)),
      forwardLog: notes
    });
  };

  const renderOptions = () => {
    if (!props.options) return;
    var nonStandardOptions = props.options.filter(
      a => a.code !== "AUS_SERVICE_OPTION_STANDARD" && a.code !== null
    );
    if (nonStandardOptions.length === 0) return;
    return (
      <React.Fragment>
        <strong>Feature <small>(Features may incur additional fees)</small></strong>
        {nonStandardOptions.map(option => {
          return (
            <div key={option.code} className="form-check">
                <input
                  className="form-check-input express"
                  type="checkbox"
                  id={`${props.code}_${option.code}`}
                  checked={selectedOptions.indexOf(option) > -1}
                  onChange={e => onOptionsChanged(e, option)}
                />
              <label
                className="form-check-label"
                htmlFor={`${props.code}_${option.code}`}
              >
                {(option.price > 0) ? option.name +  " $" + option.price : option.name}
              </label>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  const renderBorderColor = () => {
      switch (props.forwardingCompany){
        case "DHL":
          return Styles.dhlBorder;
        default: 
        return Styles.australiaPostBorder;
      }
  }

  return (
    <React.Fragment>
      <Row
        className={Styles.serviceContainer + " " + renderBorderColor()}
      >
        <Col xs={12} lg={3} className="light-border vertical-center">
          <Row>
            <Col xs={12} className="text-center">
              <img src={logoUtility.getLogo(props.forwardingCompany)} alt="DHL Logo" className={Styles.forwardingLogo}></img>
              <h2>{props.name}</h2>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={7} className="light-border">
          <Row>
            <Col xs={12} md={window.webpageDetails.showOurFee ? 6 : 10} className={Styles.estimateTime}>
              <p>Delivery time estimate</p>
              <b dangerouslySetInnerHTML={{__html: props.deliveryTimes}}></b>
            </Col>
             {window.webpageDetails.showOurFee && (
             <>
            <Col xs={4} md={2} className={Styles.subPrice}>
              <p>
                {window.webpageDetails.webpageName} Fee
              </p>
              <h3 className={Styles.priceContainer}>${props.hotsnailFee.toFixed(2)}</h3>
            </Col>
            <Col xs={4} md={2} className={Styles.subPrice}>
              <p>
                Forward Cost
              </p>
              <h3 className={Styles.priceContainer}>${price.toFixed(2)}</h3>
            </Col>
             </>
             )}
            <Col xs={4} md={2} className={Styles.price}>
              <p>
                Total Price
              </p>
              <h3 className={Styles.priceContainer}>${(parseFloat(props.hotsnailFee) + parseFloat(price)).toFixed(2)}</h3>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={2} className="light-border vertical-center no-wrap">
          <button
            className="btn btn-transparent red fw-500 choose-option"
            onClick={onOpenFeatureClick}
            href="#"
          >
            Choose this option
            <span className="size-14 ml-5">
              <FontAwesomeIcon icon="chevron-down" />
            </span>
          </button>
        </Col>
      </Row>
      {showFeatures && (
        <div className={Styles.featureSection}>
          <div className="card card-body brr-0 bt-0">
            {renderOptions()}
            <Row>
              <Col xs={12}>
                <label className="mt-2">Forward Notes</label>
                <GrowingTextArea value={notes} onChange={(e) => setNotes(e.target.value)}></GrowingTextArea>
                <Button className={`${Styles.sendButton} float-right mt-2`} variant="red" onClick={onSend}>
                  Send
                  <FontAwesomeIcon
                    icon="paper-plane"
                    className="ml-2"
                  ></FontAwesomeIcon>
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Service;
