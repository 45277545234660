import * as verificationEnum from 'enums/verificationEnum';
import {
	UPLOAD_ID,
	GET_VERIFICATION_CODE,
	VERIFY_SMS,
	GET_MOBILE_VERIFICATION,
	GET_VERIFICATION_STATUS,
	DELETE_ID,
	DELETE_VERIFICATION
} from './verificationActionTypes';

const verificationReducer = (state, action) => {
	switch (action.type) {
		case UPLOAD_ID:
			return { ...state, [action.payload.photoSide]: action.payload.base64 };
		case DELETE_ID:
			return {...state, [action.payload]: null};
		case GET_VERIFICATION_CODE:
			return { ...state, verificationCode: action.payload };
		case GET_MOBILE_VERIFICATION:
			return { ...state, mobileVerified: action.payload };
		case GET_VERIFICATION_STATUS:
				return { ...state, verifications: action.payload };	
		case DELETE_VERIFICATION:
			return { ...state, front: null, back: null, selfie: null, verifications: {...state.verifications, idVerification: 0 } };
		case VERIFY_SMS:
			return { ...state, verifications: { ...state.verifications, smsVerification: verificationEnum.Accepted } };
		default:
			return state;
	}
};

export default verificationReducer;
