import axios from 'axios';
import fileUtility from 'utilities/fileUtility';
import {
	GET_FOLDER,
	MARK_PENDING_SHRED,
	MARK_PENDING_SCAN,
	CANCEL_PENDING,
	MARK_RETURN_TO_SENDER,
	MAIL_MOVED_FOLDER,
	RECYCLE,
	MARK_PENDING_RESCAN,
	DOWNLOAD_BULK,
	MAIL_HELD,
	MARK_PENDING_FORWARD
} from './mailActionTypes';

export default (dispatch) => {
	const getFolder = async (folder) => {
		let response = await _getFolder(folder);
		dispatch({
			type: GET_FOLDER,
			payload: response.data
		});
	};

	const search = async (query) => {
		let response = await _search(query);
		dispatch({
			type: GET_FOLDER,
			payload: response.data
		});
	};

	const email = async (email, subject, body, mailIdList) => {
		await _email(email, subject, body, mailIdList);
	};

	const downloadBulk = async (mailIdList) => {
		await _downloadBulk(mailIdList);
		dispatch({
			type: DOWNLOAD_BULK,
			payload: mailIdList
		})
	};

	const markPendingShred = async (mailIdList) => {
		var shredded = await _markPendingShred(mailIdList);
		dispatch({
			type: MARK_PENDING_SHRED,
			payload: shredded
		});
	};

	const markPendingRescan = async (mailId, reason) => {
		var rescanned = await _markPendingRescan(mailId, reason);
		dispatch({
			type: MARK_PENDING_RESCAN,
			payload: rescanned
		});
	};

	const recycle = async (mailIdList) => {
		var recycled = await _recycle(mailIdList);

		dispatch({
			type: RECYCLE,
			payload: recycled
		});
	};
	
	const markPendingScan = async (mailIdList) => {
		let pendingScanList = await _markPendingScan(mailIdList);
		dispatch({
			type: MARK_PENDING_SCAN,
			payload: pendingScanList
		});
	};

	const markPendingForward = async (mailIdList) => {
		dispatch({
			type: MARK_PENDING_FORWARD,
			payload: mailIdList
		});
	};

	const cancelPending = async (mailIdList) => {
		let cancelPendingList = await _cancelPending(mailIdList);
		dispatch({
			type: CANCEL_PENDING,
			payload: cancelPendingList
		});
	};

	const returnToSender = async (mailIdList, reason) => {
		let returnedToSenderList = await _returnToSender(mailIdList, reason);
		dispatch({
			type: MARK_RETURN_TO_SENDER,
			payload: returnedToSenderList
		});
	};

	const moveToFolder = async (mailIdList, folderId) => {
		let movedIds = await _moveToFolder(mailIdList, folderId);
		dispatch({
			type: MAIL_MOVED_FOLDER,
			payload: movedIds
		});
	};

	const updateName = async (mailId, name) => {
		await _updateName(mailId, name);
	};

	const mailHeld = async() => {
		var mailHeld = await _mailHeld();

		dispatch({
			type: MAIL_HELD,
			payload: mailHeld
		})
	}

	return {
		getFolder,
		downloadBulk,
		markPendingShred,
		markPendingScan,
		cancelPending,
		returnToSender,
		moveToFolder,
		updateName,
		recycle,
		search,
		markPendingRescan,
		email,
		mailHeld,
		markPendingForward
	};
};

const _email = async (email, subject, body, mailIdList) => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/Email/Send?webpage=${window.webpageDetails.webpageName}`,
			method: 'POST',
			data: {
				email: email,
				subject: subject,
				body: body,
				mailIds: mailIdList
			}
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((response) => {
				console.log('Error emailing', response);
				reject();
			});
	});
};

const _markPendingRescan = async (mailId, reason) => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/mail/Rescan?mailId=${mailId}&reason=${reason}`,
			method: 'POST'
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((response) => {
				console.log('Error requesting rescan', response);
				reject();
			});
	});
};

const _updateName = async (mailId, name) => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/mail/RenameMail?mailId=${mailId}&mailName=${name}`,
			method: 'POST'
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((response) => {
				console.log('Error marking as shred', response);
				reject();
			});
	});
};

const _moveToFolder = async (mailIdList, folderId) => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/mail/MoveToFolder?folderId=${folderId}`,
			method: 'POST',
			data: mailIdList
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((response) => {
				console.log('Error marking as shred', response);
				reject();
			});
	});
};

const _returnToSender = async (mailIdList, reason) => {
	let returnToSenderDto = { MailIds: mailIdList, Reason: reason };
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/ReturnToSender/MarkReturnToSender`,
			method: 'POST',
			data: returnToSenderDto
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((response) => {
				console.log('Error marking return to sender', response);
				reject();
			});
	});
};

const _cancelPending = async (mailIdList) => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/mail/CancelPending`,
			method: 'POST',

			data: mailIdList
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((response) => {
				console.log('Error marking as shred', response);
				reject();
			});
	});
};

const _markPendingScan = async (mailIdList) => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/mail/MarkPendingScan`,
			method: 'POST',

			data: mailIdList
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((response) => {
				console.log('Error marking as shred', response);
				reject();
			});
	});
};

const _markPendingShred = async (mailIdList) => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/mail/MarkPendingShred`,
			method: 'POST',

			data: mailIdList
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((response) => {
				console.log('Error marking as shred', response);
				reject();
			});
	});
};

const _recycle = async (mailIdList) => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/mail/Recycle`,
			method: 'POST',

			data: mailIdList
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((response) => {
				console.log('Error recycling', response);
				reject();
			});
	});
};

const _search = async (query) => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/mail/search?query=${query}`,
			method: 'GET'
		})
			.then((response) => {
				resolve(response);
			})
			.catch((response) => {
				console.log('Error getting mail list', response);
				reject();
			});
	});
};

const _getFolder = async (folder) => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/Mail?folder=${folder}`,
			method: 'GET'
		})
			.then((response) => {
				resolve(response);
			})
			.catch((response) => {
				console.log('Error getting mail list', response);
				reject();
			});
	});
};

const _downloadBulk = async (mailIdList) => {
	var queryString = mailIdList.map((a) => `mailId=${a}`).join('&');
	window.open(`/api/mail/DownloadBulk?${queryString}`, '_blank');
};

const _mailHeld = () => {
	return new Promise((resolve, reject) => {
	  axios({
		url: `/api/mail/MailHeld`,
		method: "GET",
	  })
		.then(response => {
		  resolve(response.data);
		})
		.catch(response => {
		  console.log("error getting mailheld", response);
		  reject();
		});
	});  
  }
  