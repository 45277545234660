/* Will Be redireced to this page from email, it will send verificaion code ot api and then 
redirect automatically  */

import React, { useEffect, useContext, useState } from 'react';
import PublicTopNav from 'components/public/publicTopNav/PublicTopNav.jsx';
import { Container, Spinner } from 'react-bootstrap';
import GlobalContext from 'context/globalContext';
import qs from 'qs';

function EmailVerification(props) {
	const globalContext = useContext(GlobalContext);
	const [ message, setMessage ] = useState('');

	useEffect(() => {
		const submitVerification = async () => {
			var code = encodeURIComponent(qs.parse(props.location.search, { ignoreQueryPrefix: true }).q);
			var approved = await globalContext.verification.submitEmailVerification(code);
			if (approved) {
				window.location.href = '/Members/';
			} else {
				setMessage('Invalid code. Email has not been approved');
			}
		};
		submitVerification();
	}, []);

	return (
		<React.Fragment>
			<PublicTopNav history={props.history} />
			<Container className="text-center">
				<div style={{ marginTop: 'calc(50% - 14rem)' }}>
					{!message && <Spinner animation="border" />}
					{message}
				</div>
			</Container>
		</React.Fragment>
	);
}

export default EmailVerification;
