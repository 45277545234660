import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { library } from '@fortawesome/fontawesome-svg-core';
import GlobalState from './context/GlobalState';
import axios from 'axios';
import * as serviceWorker from './serviceWorker';
import 'pretty-checkbox/dist/pretty-checkbox.min.css';
import 'sweetalert2/dist/sweetalert2.css';
import './i18n';
import {
	faSpinner,
	faCaretSquareRight,
	faCaretSquareLeft,
	faQuestionCircle,
	faChevronDown,
	faCheck,
	faPaperPlane,
	faArrowLeft,
	faUser,
	faLock,
	faCamera,
	faUsers,
	faCreditCard,
	faFileInvoiceDollar,
	faListAlt,
	faClipboardCheck,
	faMinusCircle,
	faClipboardList,
	faEnvelopeOpenText,
	faMagic,
	faArrowsAlt,
	faTimesCircle,
	faCheckDouble,
	faTimes,
	faCloudDownloadAlt,
	faClock,
	faMapMarkedAlt,
	faPen,
	faEllipsisV,
	faTag,
	faRedo,
	faBell,
	faMoneyCheckAlt,
	faChartPie,
	faMoneyBillWave,
	faCheckCircle,
	faArchive,
	faPauseCircle,
	faExclamationCircle,
	faMailBulk,
	faShare,
	faTrashAlt,
	faUndoAlt
} from '@fortawesome/free-solid-svg-icons';
library.add([
	faSpinner,
	faCaretSquareRight,
	faCaretSquareLeft,
	faQuestionCircle,
	faChevronDown,
	faCheck,
	faPaperPlane,
	faArrowLeft,
	faUser,
	faLock,
	faCamera,
	faUsers,
	faCreditCard,
	faFileInvoiceDollar,
	faListAlt,
	faClipboardCheck,
	faMinusCircle,
	faClipboardList,
	faEnvelopeOpenText,
	faMagic,
	faArrowsAlt,
	faTimesCircle,
	faCheckDouble,
	faTimes,
	faCloudDownloadAlt,
	faClock,
	faMapMarkedAlt,
	faPen,
	faEllipsisV,
	faTag,
	faRedo,
	faBell,
	faMoneyCheckAlt,
	faChartPie,
	faMoneyBillWave,
	faCheckCircle,
	faArchive,
	faPauseCircle,
	faExclamationCircle,
	faMailBulk,
	faShare,
	faTrashAlt,
	faUndoAlt
]);

const HotsnailTheme = React.lazy(() => import('./themes/hotsnail'));
const ParcelforwardingTheme = React.lazy(() => import('./themes/parcelforwarding'));

const ThemeSelector = ({ children }) => {
	return (
		<React.Fragment>
			<React.Suspense fallback={<React.Fragment />}>
				{window.location.hostname.indexOf('parcel') > -1 && (
					<ParcelforwardingTheme>{children}</ParcelforwardingTheme>
				)}
				{window.location.hostname.indexOf('parcel') === -1 && <HotsnailTheme>{children}</HotsnailTheme>}
			</React.Suspense>
		</React.Fragment>
	);
};

axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		console.log('error', error);
		if (!error.response) {
			return null;
		} else if (error.response.status === 401) {
			if (error.response.config.url.indexOf('/api/Login') === -1) {
				window.location.href = '/login';
			} else {
				return null;
			}
		}
		return error;
	}
);

ReactDOM.render(
	<ThemeSelector>
		<GlobalState>
				<App />
		</GlobalState>
	</ThemeSelector>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
