import { GET_TRANSACTION } from "./transactionActionTypes";

const transactionReducer = (state, action) => {
  switch (action.type) {
    case GET_TRANSACTION:
      let transactions = state?.transactions || [];
      //Reset audit log if getting from page 0
      if (action.payload.page === 0) {
        transactions = [];
      }
      transactions = transactions.concat(action.payload.log);
      return { ...state, transactions: transactions };
    default:
      return state;
  }
};

export default transactionReducer;
