import React from 'react';
import IosFolderOpenOutline from 'react-ionicons/lib/IosFolderOpenOutline';
import styles from './selectFolderModal.module.css';

function SelectFolderItem(props) {
	return (
		<div onClick={props.onClick}>
			<IosFolderOpenOutline />
			<label className={styles.folderLabel}>{props.name}</label>
		</div>
	);
}

export default SelectFolderItem;
