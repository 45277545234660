import React, { useState, useContext, useEffect, useRef } from 'react';
import { Nav, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GlobalContext from 'context/globalContext';
import IosFolderOpenOutline from 'react-ionicons/lib/IosFolderOpenOutline';
import IosClose from 'react-ionicons/lib/IosClose';
import systemFolders from 'utilities/systemFolderUtility';
import Styles from './folders.module.css';
import SideBarStyles from 'styles/sidebar.module.css';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';

import './folders.css';

const Folders = (props) => {
	const globalContext = useContext(GlobalContext);
	const [ editMode, setEditMode ] = useState(false);
	const [ newFolder, setNewFolder ] = useState('');
	const [ saving, setSaving ] = useState(false);
	const wrapperDivRef = useRef(null);
	const [ t ] = useTranslation();

	//On mobile view, the left nav is only shown when the mobile menu button in topnav is clicked
	const [ showLeftNavInMobileView, setShowLeftNavInMobileView ] = useState(false);

	useEffect(
		() => {
			if (editMode) {
				wrapperDivRef.current.scrollTop = wrapperDivRef.current.scrollHeight;
			}
		},
		[ editMode ]
	);

	globalContext.events.subscribe('mobileMenuClick', (e) => setShowLeftNavInMobileView(!showLeftNavInMobileView));

	/* Events */
	const onDeleteFolder = (e, folderId) => {
		const onDeleteConfirmed = async () => {
			await globalContext.folders.deleteFolder(folderId);
			setNewFolder('');
			setEditMode(false);
			globalContext.dialog.setDialog({ visible: false });
			props.setSelectedFolder(systemFolders[0]);
		};

		globalContext.dialog.setDialog({
			visible: true,
			body: t('Are you sure you want to delete this folder?'),
			onConfirm: onDeleteConfirmed
		});
	};

	const onNewFolderKeyUp = async (e) => {
		if (e.keyCode === 13) {
			onSaveFolder();
		}
	};

	const onSaveFolder = async () => {
		if (!saving) {
			setSaving(true);
			if (newFolder) {
				await globalContext.folders.addFolder(newFolder);
			}
			setNewFolder('');
			setEditMode(false);
			setSaving(false);
		}
	};

	const onSetEditMode = (show) => {
		setNewFolder('');
		setEditMode(!editMode);
	};

	const onFolderClicked = (folder) => {
		props.setSelectedFolder(folder);
		setShowLeftNavInMobileView(false);
	};

	/* Render Methods */

	const renderFolders = (folderArray) => {
		if (!folderArray) return;
		return folderArray.map((folder) => {
			const FolderIcon = folder.icon || IosFolderOpenOutline;
			const isSystemFolder = !!folder.icon;
			return (
				<Nav.Item key={folder.folderId} onClick={() => onFolderClicked(folder)}>
					<div
						className={
							props.selectedFolder.name === folder.name ? (
								`active ${Styles.folderWrapper}`
							) : (
								Styles.folderWrapper
							)
						}
					>
						<FolderIcon color={props.selectedFolder.name === folder.name ? window.webpageDetails.highlightColor : '#000000'} />
						{!isSystemFolder && editMode ? (
							<IosClose
								fontsize="12px"
								color="#FF0000"
								className={Styles.folderDelete}
								onClick={(e) => {
									onDeleteFolder(e, folder.folderId);
								}}
							/>
						) : (
							''
						)}
						{!isSystemFolder ? folder.name : t(folder.name)}
					</div>
				</Nav.Item>
			);
		});
	};

	const renderNewFolder = () => {
		if (editMode) {
			return (
				<div className="ml-3 mr-3" key="_new">
					<input
						type="text"
						className="form-control"
						placeholder="New Folder"
						value={newFolder}
						onChange={(e) => setNewFolder(e.target.value)}
						onKeyUp={onNewFolderKeyUp}
					/>
				</div>
			);
		}
	};

	return (
		<div className={`${SideBarStyles.sidebarMenu + (showLeftNavInMobileView ? ' ' + SideBarStyles.mobileOpen : '')} sidebarMenu-bg`}>
			<Scrollbars style={{ height: 'calc(100VH - 66px)' }} ref={wrapperDivRef} autoHide autoHideTimeout={1000}>
				<Nav className={`flex-column left-nav-column ${SideBarStyles.leftNav}`}>
					{renderFolders(systemFolders)}
					{globalContext.folders.response.loading ? (
						<div className="text-center">
							<FontAwesomeIcon icon="spinner" spin />
						</div>
					) : (
						renderFolders(globalContext.folders.response.list)
					)}
				</Nav>
				{renderNewFolder()}
				<Button
					variant="light"
					className={editMode ? `${Styles.folderButton} ${Styles.cancelButton}` : Styles.folderButton}
					onClick={() => onSetEditMode(!editMode)}
				>
					{editMode ? t('Cancel') : t('Add/Delete Folder')}
				</Button>
				{editMode && (
					<Button
						variant="primary"
						className={`${Styles.folderButton} ${Styles.addButton}`}
						onClick={onSaveFolder}
					>
						{saving ? <FontAwesomeIcon icon="spinner" spin /> : t('Save')}
					</Button>
				)}
			</Scrollbars>
		</div>
	);
};
export default Folders;
