import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Container, Row } from 'react-bootstrap';
import PlanElement from './PlanElement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GlobalContext from 'context/globalContext';
import currencyUtility from 'utilities/currencyUtility';
import Confirm from 'commonComponents/Confirm';
import { useTranslation } from 'react-i18next';

function ChoosePlan(props) {
	const globalContext = useContext(GlobalContext);
	const [showConfirm, setShowConfirm] = useState(false);
	const [activePlan, setActivePlan] = useState(props.activePlan);
	const [t] = useTranslation();

	const plans = useMemo(() => {
		if (!globalContext?.plan?.response?.plans)
			return null;

		return globalContext.plan.response.plans.filter(a=> a.public).sort((a, b) => (a.order > b.order) ? 1 : -1);
	}, [globalContext.plan.response.plans])


	useEffect(() => {
		if (!globalContext?.plan?.response?.plans){
			globalContext.plan.getAll();
		}
	}, []);

	const save = async (planId) => {
		setActivePlan(planId);
		await globalContext.account.choosePlan(planId);
		props.onSaved();
	};

	//showConfirm stores planId or false
	const onConfirm = () => {
		setShowConfirm(false);
		save(showConfirm);
	};

	const onClick = async (planId) => {
		if (props.showWarning) {
			setShowConfirm(planId);
		} else {
			save(planId);
		}
	};

	const getContentScan = (plan) => {
		if (plan.perLetter === 0 && plan.perPage === 0){
			return "N/A";
		}
		return `${currencyUtility.formatCurrency(
			plan.perLetter
		)} ${t('per letter')}  , ${currencyUtility.formatCurrency(plan.perPage)} ${t('per page')}`
	}

	const getForwardParcel = (plan) => {
		if (plan.parcelForwardPercent > 0)
		{
			return `${plan.parcelForwardPercent}% of forward parcel cost`;
		}
		return `${currencyUtility.formatCurrency(plan.parcelForward)} ${t('forward parcel fee')}`
	}

	if (!plans) {
		return (
			<div className="text-center">
				<FontAwesomeIcon icon="spinner" spin />
			</div>
		);
	}

	const numberOfCols = 12 / plans.length;
	return (
		<div className="mt-2">
			{showConfirm && (
				<Confirm
					show={true}
					title={t('Are you sure?')}
					body={t("Your account will be charged or credited at a pro-rata rate")}
					onConfirm={onConfirm}
					onCancel={() => setShowConfirm(false)}
				/>
			)}
			<Container>
				<Row>
					{plans.map((plan) => {

						return (<PlanElement
							key={plan.planId}
							onClick={() => onClick(plan.planId)}
							col={numberOfCols}
							variant="primary"
							title={plan.planName}
							monthlyFee={
								plan.monthlyFee ? (
									`${currencyUtility.formatCurrency(plan.monthlyFee)} ${t('per month')}`
								) : (
										t('No monthly fee')
									)
							}
							setupFee={`${currencyUtility.formatCurrency(plan.setupFee)} ${t('setup fee')}`}
							envelopeScan={`${currencyUtility.formatCurrency(plan.envelopeScan)} ${t('per envelope scan')}`}
							contentScan={getContentScan(plan)}
							forwardMail={`${currencyUtility.formatCurrency(plan.letterForward)} ${t('forward mail fee')}`}
							forwardParcel={getForwardParcel(plan)}
							mostPopular={plan.mostPopular}
							active={activePlan === plan.planId}
						/>)
					})}
				</Row>
			</Container>
		</div>
	);
}

export default ChoosePlan;
