import axios from 'axios';
import { FETCH_ADDRESSES, ADD_ADDRESS, REMOVE_ADDRESS } from './addressActionTypes';

export default (dispatch) => {
	const fetch = async () => {
		let addressList = await _fetch();
		dispatch({
			type: FETCH_ADDRESSES,
			payload: addressList.data
		});
	};

	const add = async (address) => {
		address = await _add(address);
		dispatch({
			type: ADD_ADDRESS,
			payload: address
		});
		return address;
	};

	const remove = async(addressId) => {
		await _remove(addressId);
		dispatch({
			type: REMOVE_ADDRESS,
			payload: addressId
		})
	}

	return { fetch, add, remove };
};

const _remove = async(addressId) => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/Address?addressId=${addressId}`,
			method: 'DELETE',
		})
			.then((response) => {
				resolve(response);
			})
			.catch((response) => {
				console.log('Error deleting address', response);
				reject();
			});
	});
}

const _fetch = async () => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/Address`,
			method: 'GET',
		})
			.then((response) => {
				resolve(response);
			})
			.catch((response) => {
				console.log('Error getting address', response);
				reject();
			});
	});
};

const _add = async (address) => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/Address`,
			method: 'POST',
			data: address
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((response) => {
				console.log('Error getting address', response);
				reject();
			});
	});
};
