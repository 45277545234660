import React, { useContext } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import GlobalContext from 'context/globalContext';
import styles from './actions.module.css';
import { useTranslation } from 'react-i18next';

const Notifications = (props) => {
	const globalContext = useContext(GlobalContext);
	const { t  } = useTranslation();

    const onEmailActionNotificationChange = (e) => {
        globalContext.account.updateEmailActionNotificationChange(e.target.checked);
    }

    const onIncludePdfChange = (e) => {
        globalContext.account.updateIncludePdf(e.target.checked);
    }

    const onSeperateAlertChange = (e) => {
        globalContext.account.updateSendSeperate(e.target.checked);        
    }

    return (    
		<div className="mt-2">
			<Row>
				<Col xs={12}>
					<h1>{t('Mail Action Notifications')}</h1>
					<small>{t('Email notification are sent when a mail item is scanned or forwarded')}</small>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<Form.Group controlId="Notifications">
                        <Form.Check 
                        type="checkbox" 
                        label={t("Turn on notifications")} 
                        className={styles.checkboxLabel} 
                        checked={globalContext.account.response.account.emailActionNotification}
                        onChange={onEmailActionNotificationChange}
                        />
					</Form.Group>
				</Col>
			</Row>
            <Row>
				<Col xs={12}>
					<Form.Group controlId="PDFS">
                        <Form.Check 
                            type="checkbox" 
                            label={t("Include email scan PDF in email as an attachment")} 
                            className={styles.checkboxLabel}
                            checked={globalContext.account.response.account.includePdf}
                            onChange={onIncludePdfChange}
                            />
					</Form.Group>
				</Col>
			</Row>
            <Row> 
				<Col xs={12}>
					<Form.Group controlId="seperate-notification">
                        <Form.Check 
                            type="checkbox" 
                            label={t("Send notifications in separate emails")} 
                            className={styles.checkboxLabel}
                            checked={globalContext.account.response.account.seperateAlerts}
                            onChange={onSeperateAlertChange}
                            />
					</Form.Group>
				</Col>
			</Row>
		</div>
	);
};
export default Notifications;
