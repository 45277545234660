import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Container, Button, Alert } from "react-bootstrap";
import Styles from "./customForm.module.css";

const CustomForm = props => {
  const [whatIsIt, setWhatIsIt] = useState("");
  const [unitValue, setUnitValue] = useState(0);
  const [whyAreYouSending, setWhyAreYouSending] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [showError, setShowError] = useState(false);
  const [doesParcelContainDangerous, setDoesParcelContainDangerous] = useState(
    false
  );

  const [
    whatIfParcelCantBeDelivered,
    setWhatIfParcelCantBeDelivered
  ] = useState("Return to Sender");

  const [accept, setAccept] = useState(false);

  const onNext = e => {
    if (whatIsIt !== ""){
      setShowError(false);
      let unitValueDecimal = parseFloat(unitValue);
      props.onNext({
        whatIsIt,
        unitValue: unitValueDecimal,
        whyAreYouSending,
        otherReason,
        doesParcelContainDangerous,
        whatIfParcelCantBeDelivered,
        forwardId: 0
      });
    } 
    else{
      setShowError(true);
    }
  };

  const onUpdateUnitValue = e => {
    const amount = e.target.value;
    //only allow one decimal place
    if (amount.split(".").length - 1 > 1) {
      return false;
    } else {
      if (!amount || amount.match(/^\d{1,}(\.\d{0,4})?$/)) {
        setUnitValue(amount);
      }
    }
  };

  return (
    <React.Fragment>
      <section className={Styles.mailSection}>
        <Container>
          <Row className="mx-15">
            <h2>
              <FontAwesomeIcon
                icon="arrow-left"
                onClick={props.onBack}
                className="pointer"
              />{" "}
            </h2>
          </Row>
          <Row className={Styles.customForm}>
            <div className="col light-border vertical-center">
              <Container className={Styles.customContainer}>
                <Row>
                  <Col xs={12}>
                    <h2>{props.domestic ? "" : "Custom"} Decleration Form</h2>
                    <br></br>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    What is it, and what’s it made of? No need to describe the
                    colour or style.
                </Col>
                  <Col xs={12}>
                    <textarea
                      className="form-control"
                      value={whatIsIt}
                      onChange={e => setWhatIsIt(e.target.value)}
                    ></textarea>
                  </Col>
                </Row>
                {!props.domestic && 
                <>
                <Row className="mt-3">
                  <Col xs={12}>Unit Value</Col>
                  <Col xs={12}>
                    <input
                      type="text"
                      className="form-control"
                      value={unitValue}
                      onChange={onUpdateUnitValue}
                    ></input>
                  </Col>
                </Row>
                  <Row className="mt-3">
                  <Col xs={12}>Why are you sending this parcel?</Col>
                  <Col xs={12}>
                    <select
                      className="form-control"
                      value={whyAreYouSending}
                      onChange={e => setWhyAreYouSending(e.target.value)}
                    >
                      <option>Gift</option>
                      <option>Documents</option>
                      <option>Commerical Sample</option>
                      <option>Merchandise</option>
                      <option>Returned Goods</option>
                      <option>Other</option>
                    </select>
                  </Col>
                </Row>

                {whyAreYouSending === "Other" && (
                  <Row className="mt-3">
                    <Col xs={12}>Other reason</Col>
                    <Col xs={12}>
                      <input
                        type="text"
                        className="form-control"
                        value={otherReason}
                        onChange={e => setOtherReason(e.target.value)}
                      ></input>
                    </Col>
                  </Row>
                )}
                </>
}
                <Row className="mt-3">
                  <Col xs={12}>
                    Does this parcel contain dangerous or prohibited items?
                </Col>
                  <Col xs={12}>
                    <input
                      type="radio"
                      id="containDangerousYes"
                      checked={doesParcelContainDangerous}
                      onChange={e =>
                        setDoesParcelContainDangerous(e.target.checked)
                      }
                    ></input>
                    {"   "} <label htmlFor="containDangerousYes">Yes</label>
                    <input
                      type="radio"
                      className="ml-2"
                      id="containDangerousNo"
                      checked={!doesParcelContainDangerous}
                      onChange={e =>
                        setDoesParcelContainDangerous(!e.target.checked)
                      }
                    ></input>
                    {"   "}
                    <label htmlFor="containDangerousNo">No</label>
                    <p>
                      <small>
                        Explosives, flammables (e.g. perfume, nail polish),
                        corrosives and aerosols are examples of items considered
                        dangerous and unsuitable for sending from Australia.
                    </small>
                    </p>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={12}>What if this parcel can't be delivered?</Col>
                  <Col xs={12}>
                    <input
                      type="radio"
                      id="whatIfYes"
                      checked={whatIfParcelCantBeDelivered === "Return to Sender"}
                      onChange={e =>
                        setWhatIfParcelCantBeDelivered("Return to Sender")
                      }
                    ></input>
                    {"   "} <label htmlFor="whatIfYes">Return to sender</label>
                    <input
                      type="radio"
                      className="ml-2"
                      id="whatIfNo"
                      checked={
                        whatIfParcelCantBeDelivered === "Treat as abandoned"
                      }
                      onChange={e =>
                        setWhatIfParcelCantBeDelivered("Treat as abandoned")
                      }
                    ></input>
                    {"   "}
                    <label htmlFor="whatIfNo">Treat as abandoned</label>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={12}>
                    I confirm that the information given in this form is true,
                    complete and accurate?
                  <input
                      type="checkbox"
                      className="ml-3"
                      checked={accept}
                      onChange={e => setAccept(e.target.checked)}
                    ></input>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    {accept && (
                      <Button
                        className={`${Styles.sendButton} float-right`}
                        variant="red"
                        onClick={onNext}
                      >
                        Send
                      <FontAwesomeIcon
                          icon="paper-plane"
                          className="ml-2"
                        ></FontAwesomeIcon>
                      </Button>
                    )}
                  </Col>
                </Row>
                {showError &&
                <Row className="mt-3">
                  <Col xs={12}>
                    <Alert variant="danger">You must include a description</Alert>
                  </Col>
                </Row>
}
              </Container>
            </div>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CustomForm;
