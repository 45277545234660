import React, { useContext, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import GlobalContext from 'context/globalContext';
import { useTranslation } from 'react-i18next';


const StorageMetrics = (props) => {
	const globalContext = useContext(GlobalContext);
    const { t  } = useTranslation();

    useEffect(() => {
        globalContext.storage.getMetrics();
    }, []);

    const renderLabel= (count, letterType) => {
        if (count === 0){
            return `0`;
        }
        else if (count === 1){
            return `1 ${t(letterType)}`;
        }
        else{
            return `${count} ${t(letterType + 's')}`;
        }

    }

	return (
		<div>
            {globalContext?.storage?.response?.metrics &&
			<Table striped bordered hover>
				<tbody>
					<tr>
						<td>{t('Stored envelopes (within free storage period)')}</td>
						<td>{renderLabel(globalContext.storage.response.metrics.storedEnvelopesWithinFreePeriod, t("envelope"))}</td>
					</tr>
					<tr>
						<td>{t('Stored envelopes (outside of free storage period)')}</td>
						<td>{renderLabel(globalContext.storage.response.metrics.storedEnvelopesOutsideFreePeriod, t("envelope"))}</td>
					</tr>
					<tr>
						<td>{t('Forwarded envelopes')}</td>
						<td>{renderLabel(globalContext.storage.response.metrics.forwardedEnvelopes, t("envelope"))}</td>
					</tr>
					<tr>
						<td>{t('Shredded envelopes')}</td>
						<td>{renderLabel(globalContext.storage.response.metrics.shreddedEnvelopes, "envelope")}</td>
					</tr>
                    <tr>
						<td>{t('Stored parcels (within free storage period)')}</td>
						<td>{renderLabel(globalContext.storage.response.metrics.storedParcelsWithinFreePeriod, "parcel")}</td>
					</tr>
                    <tr>
						<td>{t('Stored parcels (outside of free storage period)')}</td>
						<td>{renderLabel(globalContext.storage.response.metrics.storedParcelsOutsideFreePeriod, "parcel")}</td>
					</tr>

					<tr>
						<td>{t('Forwarded parcels')}</td>
						<td>{renderLabel(globalContext.storage.response.metrics.forwardedParcels, "parcel")}</td>
					</tr>
					<tr>
						<td>{t('Destroyed parcels')}</td>
						<td>{renderLabel(globalContext.storage.response.metrics.destroyedParcels, "parcel")}</td>
					</tr>                    
				</tbody>
			</Table>
}
		</div>
	);
};
export default StorageMetrics;
