import axios from 'axios';

export default {
	email: async (email, subject, message) => {
		return new Promise((resolve, reject) => {
			axios({
				url: `/api/Support/Email?webpage=${window.webpageDetails.webpageName}`,
				data: {email, subject, message},
				method: 'POST'
			})
				.then((response) => {
					resolve(response.data);
				})
				.catch((response) => {
					console.log('Error emailing support', response);
					reject();
				});
		});
	}
};
