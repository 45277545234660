import React, { useState, useContext } from 'react';
import GlobalContext from 'context/globalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'qs';
import styles from './login.module.css';

function ResetPassword(props) {
	const globalContext = useContext(GlobalContext);
	const [ loading, setLoading ] = useState(false);
	const [ password, setPassword ] = useState('');
	const [ password2, setPassword2 ] = useState('');
	const [ passwordSent, setPasswordSent ] = useState(false);

	const onReset = async () => {
		if (!loading) {
			if (password === password2) {
//				try {
					setLoading(true);
					var resetCode = qs.parse(window.location.search, { ignoreQueryPrefix: true }).reset;
					var email = qs.parse(window.location.search, { ignoreQueryPrefix: true }).email;
					await globalContext.account.resetPassword(email, password, resetCode);
					setLoading(false);
					setPasswordSent(true);
//				} catch (ex) {
//                    alert("Unable to reset password");
//                }
			} else {
				alert('Passwords do not match');
			}
		}
	};

	return (
		<div className={`${styles.wrapper} ${styles.fadeInDown} ${styles.login}`}>
			<div className={styles.formContent}>
				<div className={`${styles.fadeIn} ${styles.first}`}>
					<img src={window.webpageDetails.logo} id="icon" alt="Hotsnail Icon" className={styles.hotsnailIcon} />
				</div>
				{!passwordSent ? (
					<form>
						<input
							type="password"
							className={`${styles.fadeIn} ${styles.second}`}
							placeholder="New Password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<input
							type="password"
							className={`${styles.fadeIn} ${styles.second}`}
							placeholder="Repeat New Password"
							value={password2}
							onChange={(e) => setPassword2(e.target.value)}
						/>
						<button type="button" className={`${styles.fadeIn} ${styles.fourth} btn-primary `} style={{backgroundColor: "red"}} onClick={onReset}>
							{!loading ? 'Update' : <FontAwesomeIcon icon="spinner" spin />}
						</button>
					</form>
				) : (
					<div className="p-5">
						Your password has been reset, click <a href="/login">here</a> to login
					</div>
				)}
			</div>
		</div>
	);
}

export default ResetPassword;
