import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Styles from "./forward.module.css";

function Sending(props) {
    return (
        <React.Fragment>
            <Container className={Styles.sentWrapper}>
                <div className={Styles.sentContainer}>
                    <div className={Styles.sentWrapper}>
                        <img className={Styles.sentImg} src={`/members/images/send.png`} alt="Sent" />
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <p>
                                        Creating transaction...
                                    </p>
                                    <p>please do not close or refresh the page..</p>
                                    <FontAwesomeIcon icon="spinner" spin />

                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </Container>
        </React.Fragment>

    )
}

export default Sending;