import axios from "axios";

export default dispatch => {
  const updatePayment = async (cardName, autoTopUpAmount, sourceToken, chargeNow)  => {
    return await _updatePayment(cardName, autoTopUpAmount, sourceToken, chargeNow);
    
  };

  return { updatePayment };
};


const _updatePayment = async (cardName, autoTopUpAmount, sourceToken, chargeNow) => {

  return new Promise((resolve, reject) => {
    axios({
      url: `/api/Payment/UpdateCard`,
      method: "POST",
      data: {cardName, autoTopUpAmount, sourceToken, chargeNow},
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(response => {
        console.log("Error updating payment", response);
        reject();
      });
  });
};

