import axios from 'axios';
import { GET_AUDIT } from './auditActionTypes';

export default (dispatch) => {

    const getAudit = async(page, pageSize) => {
        var auditLog = await _getAudit(page, pageSize);
        dispatch({
            payload: { log: auditLog, page: page },
            type: GET_AUDIT
        });
        return auditLog.length > 0;
    };
    return { getAudit };
};

const _getAudit = async (page, pageSize) => {
    return new Promise((resolve, reject) => {
        axios({
                url: `/api/Audit?page=${page}&pageSize=${pageSize}`,
                method: 'GET',
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((response) => {
                console.log('Error getting audit', response);
                reject();
            });
    });
}