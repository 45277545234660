import { FETCH_ALERTS, MARK_READ } from "./alertActionTypes";
import axios from "axios";

export default dispatch => {
  const fetch = async ()  => {
    var alerts = await _fetch();
    dispatch({ type: FETCH_ALERTS, payload: alerts });
  };

  const markRead = async (id) => {
    if (id > 0) {
      await _markRead(id);
    }

    dispatch({ type: MARK_READ, payload: id });
  };
  return { fetch, markRead };
};

const _fetch = async () => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/Alert`,
      method: "GET",
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(response => {
        console.log("Error getting alerts", response);
        reject();
      });
  });
};

const _markRead = async (alertId) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/Alert?alertId=${alertId}`,
      method: "POST",
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(response => {
        console.log("Error marking read", response);
        reject();
      });
  });
}