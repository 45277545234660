import { useReducer } from "react";
import paymentActions from "./paymentActions";
import paymentReducer from "./paymentReducer";

export default function usePayment() {
  const [payment, paymentDispatch] = useReducer(paymentReducer, {});
  const { updatePayment } = paymentActions(paymentDispatch);

  return {
    response: payment,
    updatePayment
  };
}
