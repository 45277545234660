import axios from 'axios';

export const getPendingForwards = async () => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/api/forward/GetPendingForwards`,
            method: 'GET',
        })
            .then((response) => {
                if (response?.data) {
                    resolve(response.data);
                }
            })
            .catch((response) => {
                console.log('Error fetching forwards mail', response);
                reject();
            });
    });
}

export const addToPendingForward = async (mailIds, forwardId) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/api/forward/AddToPendingForward`,
            method: 'POST',
            data: {mailIds, forwardId}
        })
            .then((response) => {
                if (response?.data) {
                    resolve(response.data);
                }
            })
            .catch((response) => {
                console.log('Error fetching forwards mail', response);
                reject();
            });
    });
}