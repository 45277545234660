import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AddressElement from './AddressElement';
import styles from './chooseAddress.module.css';

function AddressRow(props) {
	console.log("props", props);
	return (
		<Row>
			<Col xs={12} lg={2} className={styles.state}>
				<h1>{props.state}</h1>
			</Col>
			{props.locations.map((location, index) => (
                <AddressElement 
                key={location.locationId}
                id={location.locationId}
                city={location.city} 
                street={location.street} 
                suburb={location.suburb} 
                index={index}
				onSelect={props.onSelect}
				checked={props.selectedLocations.indexOf(location.locationId) > -1}
				note={location.note}

				/>
			))}
		</Row>
	);
}

export default AddressRow;
