import React, { useState } from "react";
import GlobalContext from "./globalContext";
import useAccount from "./accountContext/useAccount";
import useAlert from "./alertContext/useAlert";
import useDialog from "./dialogContext/useDialog";
import useAccountSettings from "./accountSettingsContext/useAccountSettings";
import useMail from "./mailContext/useMail";
import useFolders from "./foldersContext/useFolders";
import useAddress from "./addressContext/useAddress";
import useAudit from "./auditContext/useAudit";
import countryList from "./country.json";
import globalEvents from "./globalEvents";
import useForward from "./forwardContext/useForward";
import useVerification from "./verificationContext/useVerification";
import usePayment from "./paymentContext/usePayment";
import useLocation from "./locationContext/useLocation";
import usePlan from "./planContext/usePlan";
import useAdditionalNames from "./additionalNames/useAdditionalNames";
import useTransactions from "./transactionContext/useTransaction";
import useLogin from "./loginContext/useLogin";
import useStorage from "./storageMetrics/useStorage";

const GlobalState = props => {
    const [loading, setLoading] = useState(false);

    const stateHooks = {
        account: useAccount(),
        alerts: useAlert(),
        dialog: useDialog(),
        mail: useMail(),
        folders: useFolders(),
        accountSettings: useAccountSettings(),
        address: useAddress(),
        forward: useForward(),
        verification: useVerification(),
        payment: usePayment(),
        location: useLocation(),
        plan: usePlan(),
        audit: useAudit(),
        additionalNames: useAdditionalNames(),
        transactions: useTransactions(),
        login: useLogin(),
        storage: useStorage(),
        countryList: countryList,
        loading: loading,
        setLoading: setLoading,
        events: globalEvents
    }

    return (<GlobalContext.Provider value={stateHooks} > {props.children} 
    </GlobalContext.Provider>
    );
};

export default GlobalState;