import React, { useContext, useState, useEffect } from 'react';
import GlobalContext from 'context/globalContext';
import { Toast } from 'react-bootstrap';
import TopNav from 'components/members/topnav';
import Folders from './folders';
import Mail from './mail';
import mailService from 'services/mailService';
import mailActionUtility from 'utilities/mailActionUtility';
import Preview from './preview';
import systemFolders from 'utilities/systemFolderUtility';
import ForwardLog from './forwardLog/ForwardLog';
import Styles from './inbox.module.css';
import * as forwardService from 'services/forwardService';
import AddToForwardModal from './addToForwardModal/AddToForwardModal';

import axios from 'axios';

const Inbox = (props) => {
	const globalContext = useContext(GlobalContext);
	// eslint-disable-next-line
	const [selectedMail, setSelectedMail] = useState();
	const [pendingForwards, setPendingForwards] = useState();
	const [showAddToForwardModal, setShowAddToForwardModal] = useState(false);
	const [selectedMailList, setSelectedMailList] = useState([]);
	//activeMail is the mail item that is displayed in the preview
	//Its, the first item that is in selectedMailList or the item that is clicekd
	const [activeMail, setActiveMail] = useState();
	const [previewMailId, setPreviewMailId] = useState();
	const [selectedFolder, setSelectedFolderState] = useState(systemFolders[0]);
	const [showForwardShred, setShowForwardShred] = useState(false);
	const [showScan, setShowScan] = useState(false);
	const [showOpenScan] = useState(false);
	const [showCancel, setShowCancel] = useState(false);
	const [showDownload, setShowDownload] = useState(false);
	const [showVerificationWarning, setShowVerificationWarning] = useState(false);
	const [loadingMail, setLoadingMail] = useState(false);
	useEffect(() => {
		const getFolders = async () => {
			globalContext.folders.getFolders();
		};
		getFolders();
		// eslint-disable-next-line
	}, []);

	useEffect(
		() => {
			const getMail = async () => {
				globalContext.mail.getFolder(selectedFolder.name);
			};
			getMail();
			// eslint-disable-next-line
		},
		[selectedFolder]
	);


	useEffect(() => {
		//Create cancel token to cancel download incase page change
		let cancel;
		let cancelToken = new axios.CancelToken(function executor(c) {
			cancel = c;
		});

		const fetchMail = async () => {
			setLoadingMail(true);
			await mailService.fetchMail(activeMail.mailId, false, cancelToken).then((mail) => {
				setSelectedMail(mail);
				setPreviewMailId(activeMail.mailId);
				setLoadingMail(false);

			});
		};

		if (activeMail && activeMail.mailId !== previewMailId) {
			fetchMail();
		} else {
			setSelectedMail(null);
			setPreviewMailId(null);
		}

		return () => {
			cancel();
		}

	}, [activeMail])


	const updateMailList = (selectedMailList) => {
		//If not verified, dont show any buttons
		if (globalContext?.account?.response?.account?.idVerified ||
			globalContext?.account?.response?.account?.smsVerified) {
			if (!activeMail) {
				setActiveMail(selectedMailList[0]);
			}
			setSelectedMailList(selectedMailList);
			setShowForwardShred(mailActionUtility.showForwardShred(selectedMailList));
			setShowScan(mailActionUtility.showScan(selectedMailList));
			setShowCancel(mailActionUtility.showCancel(selectedMailList));
			setShowDownload(mailActionUtility.showDownload(selectedMailList));
		} else {
			setShowVerificationWarning(true);
		}
	};

	const setSelectedFolder = (folder) => {
		setSelectedMailList([]);
		setSelectedFolderState(folder);
	}

	const clearSelected = () => {
		setSelectedMailList([]);
		updateMailList([]);
	}

	const onSetActiveMail = (mailItem) => {
		setSelectedMailList([mailItem]);
		updateMailList([mailItem]);
		setActiveMail(mailItem);
	}

	const onEmail = async (email, subject, body) => {
		let mailIds = selectedMailList.map((mail) => mail.mailId);
		await globalContext.mail.email(email, subject, body, mailIds);
		clearSelected();
	}

	const onForward = async () => {
		var pendingForwards = await forwardService.getPendingForwards();
		if (pendingForwards.length === 0) {
			onCreatePendingForward();
		} else {
			setPendingForwards(pendingForwards);
			setShowAddToForwardModal(true);
		}
	};

	const onCreatePendingForward = () => {
		setShowAddToForwardModal(false);
		globalContext.forward.setMailIds(selectedMailList);
		props.history.push('/Members/Forward');
	}

	const onAppendForward = async (forwardId) => {
		var mailIds = selectedMailList.map((mail) => mail.mailId);;
		if (await forwardService.addToPendingForward(mailIds, forwardId)) {
			globalContext.mail.markPendingForward(mailIds);
		}
		setShowAddToForwardModal(false);
	}


	const renderMailSection = () => {
		return (
			<React.Fragment>
				<Mail
					selectedMailList={selectedMailList}
					setSelectedMailList={updateMailList}
					onForward={onForward}
					history={props.history}
					showForwardShred={showForwardShred}
					showOpenScan={showOpenScan}
					showScan={showScan}
					showCancel={showCancel}
					showDownload={showDownload}
					clearSelected={clearSelected}
					selectedFolder={selectedFolder}
					onEmail={onEmail}
					activeMail={activeMail}
					setActiveMail={onSetActiveMail}
				/>
				<Preview mail={selectedMail} loadingMail={loadingMail} />
			</React.Fragment>
		);
	};

	const renderForwardLog = () => {
		return <ForwardLog />;
	};

	const renderContent = (selectedFolder) => {
		switch (selectedFolder.folderId) {
			case -2:
				return renderForwardLog();
			default:
				return renderMailSection();
		}
	};

	if (!globalContext.account.response?.account) {
		return (<div></div>);
	}

	return (
		<React.Fragment>
			<Toast
				show={showVerificationWarning}
				onClose={() => setShowVerificationWarning(false)}
				delay={3000}
				autohide
				style={{ position: 'absolute', top: "10px", left: "10px", zIndex: "10000" }}>
				<Toast.Header>
					<strong className="mr-auto">Error</strong>
				</Toast.Header>
				<Toast.Body>Unable to view or action mail until verification is complete.</Toast.Body>
			</Toast>
			<TopNav history={props.history} />
			<div className={Styles.inboxWrapper}>
				<Folders selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} />
				{renderContent(selectedFolder)}
			</div>
			{showAddToForwardModal && <AddToForwardModal show={showAddToForwardModal} forwards={pendingForwards} onCreatePendingForward={onCreatePendingForward} onAppendForward={onAppendForward}></AddToForwardModal>}
		</React.Fragment>
	);
};
export default Inbox;
