import React, { useContext, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import GlobalContext from 'context/globalContext';
import ResetPasswordModal from './ResetPasswordModal';
import { useTranslation } from 'react-i18next';

// import Styles from "./security.module.css";

const Security = (props) => {
	const globalContext = useContext(GlobalContext);
	const [showReset, setShowReset ] = useState(false);
	const { t  } = useTranslation();
	
	
	const onReset = () => {
		setShowReset(true);
	}

	const onToggleMfa = async () => {
		var mfaEnabled = !globalContext.account.response.account.enableLoginGuard;
		globalContext.account.updateLoginGuard(mfaEnabled);
	};

	return (
		<div className="mt-2">
	  			<Row>
				<Col xs={12}>
					<h1>{t('Reset Password')}</h1>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<Button onClick={onReset}>
						{t('Reset')}
					</Button>
				</Col>
			</Row>

			<Row className="mt-4">
				<Col xs={12}>
					<h1>{t('Login Guard')}</h1>
					<small>{t("We'll email you a secuirty code if we notice a login from an unusual device")}</small>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<Button onClick={() => onToggleMfa()}>
						{globalContext.account.response.account.enableLoginGuard ? t('De-activate') : t('Activate')}
					</Button>
				</Col>
			</Row>

			<ResetPasswordModal show={showReset} onClose={(e) => setShowReset(false)}></ResetPasswordModal>
		</div>
	);
};
export default Security;
