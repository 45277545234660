import React from 'react';
import { Col } from "react-bootstrap";
import styles from './planElement.module.css';
import { useTranslation } from 'react-i18next';

function PlanElement(props) {
    const [ t ] = useTranslation();

    return (
        <Col xs={12} md={props.col} className={`${styles.planBox} pointer mt-3`} onClick={props.onClick}>
            <div className={`${(props.active ? styles.active : "")} ${styles.panel} panel panel-${props.variant}`}>
                {props.mostPopular &&
                    <div className={styles.cnrflash}>
                        <div className={styles.cnrflashInner}>
                            <span className={styles.cnrflashLabel} dangerouslySetInnerHTML={{__html: t("MOST<br />POPULAR")}}></span>
                        </div>
                    </div>
                }
                <div className={styles.panelHeading}>
                    <h3 className="panel-title">{t(props.title)}</h3>
                </div>
                <div className={styles.panelBody + " panel-body"}>
                    <div className={styles.thePrice}>
                        <b>{props.monthlyFee}</b>
                    </div>
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>{props.setupFee}</td>
                            </tr>
                            <tr className="active">
                                <td>{props.envelopeScan}</td>
                            </tr>
                            <tr>
                                <td><b>Open &amp; Scan</b><br></br>{props.contentScan}</td>
                            </tr>
                            <tr className="active">
                                <td>{props.forwardMail}</td>
                            </tr>
                            <tr>
                                <td>{props.forwardParcel}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Col>
    )
}

export default PlanElement;