import React, { useState, useContext, useEffect } from 'react';
import TopNav from 'components/members/topnav';
import { Row, Col, Container, Form, Button, Alert } from 'react-bootstrap';
import styles from './support.module.css';
import GlobalContext from 'context/globalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import supportService from 'services/supportService';
import { useTranslation } from 'react-i18next';

function Support(props) {
	const globalContext = useContext(GlobalContext);
	var [ email, setEmail ] = useState(globalContext.account.response.account.email);
	var [ subject, setSubject ] = useState('');
	var [ message, setMessage ] = useState('');
	var [ sending, setSending ] = useState(false);
	var [ sendSuccess, setSendSuccess ] = useState(false);
	var [ sendFail, setSendFail ] = useState(false);
	const [ t ] = useTranslation();


	useEffect(() => {
		const gotoMembers = () => props.history.push("/members");

		globalContext.events.subscribe('mobileMenuClick', gotoMembers );
		return () => {
			globalContext.events.unsubscribe('mobileMenuClick', gotoMembers );
			
		}
	}, []);
	

	const onSend = async () => {
		if (!sending) {
			try {
				setSendFail(false);
				setSendSuccess(false);
				setSending(true);
				await supportService.email(email, subject, message);
				setSendSuccess(true);
				setSubject("");
				setMessage("");
				setSending(false);
				setTimeout(() => {
					setSendSuccess(false);
				}, 3000);
			} catch (ex) {
				setSending(false);
				setSendFail(true);
			}
		}
	};

	return (
		<React.Fragment>
			<TopNav history={props.history} />
			<Container className={styles.supportWrapper}>
				<Row>
					<Col md={6} className="d-none d-lg-block">
						<img src={`/members/images/support.png`} alt="support" className={styles.supportIcon} />
					</Col>
					<Col lg={6} md={12}>
						<h2 className="text-center">{t('Get in touch')}</h2>
						<Form.Group controlId="contactEmail">
							<Form.Label>{t('Best contact')}</Form.Label>
							<Form.Control
								type="email"
								placeholder={t("Subject")}
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</Form.Group>

						<Form.Group controlId="subject">
							<Form.Label>{t('Subject')}</Form.Label>
							<Form.Control
								type="text"
								placeholder={t("Subject")}
								value={subject}
								onChange={(e) => setSubject(e.target.value)}
							/>
						</Form.Group>

						<Form.Group controlId="subject">
							<Form.Label>{t('Message')}</Form.Label>
							<Form.Control
								as="textarea"
								className={styles.message}
								value={message}
								onChange={(e) => setMessage(e.target.value)}
							/>
						</Form.Group>

						<Button variant="primary" onClick={onSend}>
							{sending ? <FontAwesomeIcon icon="spinner" spin /> : t('Send')}
						</Button>

						{sendSuccess && (
							<Alert variant="success" className="mt-2">
								{t('Email has been sent')}
							</Alert>
						)}

						{sendFail && (
							<Alert variant="danger" className="mt-2">
								{t('Unable to send email. Please send directly to')} {window.webpageDetails.email}
							</Alert>
						)}
					</Col>
				</Row>
				<Row className="mt-5 text-center">
				 	<Col>
							View our instructional video by clicking <a href="https://www.youtube.com/watch?v=2qj3cjk3P7k" target="_blank">here</a>
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	);
}

export default Support;
