import { useReducer } from "react";
import alertActions from "./alertActions";
import alertReducer from "./alertReducer";

export default function useAlert() {
  const [alerts, alertDispatch] = useReducer(alertReducer, []);
  const { fetch, markRead } = alertActions(alertDispatch)

  return {
      response: alerts,
      fetch,
      markRead
  };
}
