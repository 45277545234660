import React from 'react';
import { Col, Card } from 'react-bootstrap';
import guidUtility from 'utilities/guidUtility';
import styles from './chooseAddress.module.css';

function AddressElement(props) {
	var offSet = props.index !== 0 && props.index % 2 === 0 ? 2 : 0;
	var checkboxId = guidUtility.randomGuid();

	const onChange = (e) => {
		props.onSelect(props.id, e.target.checked);
	};

	return (
		<Col xs={12} lg={{ span: 5, offset: offSet }} className={offSet === 2 ? `mt-3 ${styles.addressElementWrapper}` : ` ${styles.addressElementWrapper}`}>
			<Card className={`${styles.addressCard} bg-light`} >
				<Card.Body>
					<input type="checkbox" id={checkboxId} onChange={onChange} checked={props.checked} />
					<label htmlFor={checkboxId} className={styles.addressLabel}>
						<div>
							<b>{props.city}</b>
							<br />
							<span>
								{props.street}, {props.suburb}
							</span>
							<br />

						</div>
					</label>
					<div class={styles.addressComment}>
						<b>Note: </b> {props.note}
					</div>

				</Card.Body>
			</Card>
		</Col>
	);
}

export default AddressElement;
