import React, { useEffect, useState } from "react";
import PublicTopNav from "components/public/publicTopNav/PublicTopNav.jsx";
import { Container, Alert } from "react-bootstrap";
import qs from "qs";
import axios from "axios";
import * as config from 'config.js';

function LoginGuard(props) {
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    var authCode = qs.parse(props.location.search, { ignoreQueryPrefix: true })
      .q;
    axios({
      url: `/api/Login/LoginGuardAuthorise?q=${authCode}`,
      method: "POST"
    })
      .then(response => {
        setSuccess(response.data);
        if (response.data === true){
          props.history.push("/login?message=Your%20browser%20is%20authenticated.%20You%20may%20now%20login."); 
        }
      })
      .catch(response => {
        console.log("Error getting account", response);
      });
  }, []);

  return (
    <React.Fragment>
      <PublicTopNav history={props.history} />
      <Container>
        {success === null && "Loading..."}
        {success === false && "Error. Authentication is invalid or has expired."}
        {success === true && <div>Redirecting browser. If your browser does not redirect automatically <a href="/members">click here</a></div>}
      </Container>
    </React.Fragment>
  );
}

export default LoginGuard;
