import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function RescanModel(props) {
	const [ reason, setReason ] = useState('');
	const [ saving, setSaving ] = useState(false);

	const save = async () => {
		if (!saving) {
            setSaving(true);
            await props.saveRescan(reason, props.show);
			setSaving(false);
			props.setShowRescan(false);
		}
    };
    
	return (
		<Modal show={!!props.show}>
			<Modal.Header>
				<Modal.Title>Rescan item</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>Why would you like the item rescanned?</p>
				<p>
					<textarea className="form-control" value={reason} onChange={(e) => setReason(e.target.value)} />
				</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={save}>
					{saving ? <FontAwesomeIcon icon="spinner" spin /> : 'Confirm'}
				</Button>
				<Button variant="primary" onClick={() => props.setShowRescan(false)}>
					Cancel
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default RescanModel;
