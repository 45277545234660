import React, { useContext, useState } from 'react';
import { Button, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import GlobalContext from 'context/globalContext';
import { useTranslation } from 'react-i18next';

const CloseAccount = (props) => {
	const globalContext = useContext(GlobalContext);
    const [ showConfirm, setShowConfirm ] = useState(false);
    const [ closeInput, setCloseInput ] = useState("");
	const { t  } = useTranslation();

    const onConfirm = () => {
        setShowConfirm(true);
    }

    const onClose = async () => {
        if (closeInput.toLowerCase() === t("close") || closeInput.toLowerCase() === "close"){
            await globalContext.account.closeAccount();
            window.location.href = "/login";
        }
    }

	return (
		<div className="mt-2">
			<Row>
				<Col xs={12}>
					{!showConfirm && <Button className="float-right" onClick={onConfirm}>{t("Yes I'm sure")}</Button>}
					{showConfirm && (
						<div className="float-right">
							<small>{t("Type 'close' to confirm")}</small>
							<br />
							<InputGroup>
								<FormControl value={closeInput} onChange={(e) => setCloseInput(e.target.value)} />
								<InputGroup.Append>
									<Button variant="outline-secondary" onClick={onClose}>{t('Confirm')}</Button>
								</InputGroup.Append>
							</InputGroup>
						</div>
					)}
					<h1>{t('Are you absolutely sure?')}</h1>
					<small>{t('Any held items will be shredded and future items will be returned to sender.')}</small>
				</Col>
			</Row>
		</div>
	);
};
export default CloseAccount;
