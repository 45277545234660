import React from 'react';
import { Container } from 'react-bootstrap';
import PaymentForm from 'components/members/accountSettings/elements/PaymentForm';
import styles from './paymentRequired.module.css';

const PaymentRequired = (props) => {

	const onAfterSave = (result) => {
		window.location.href = "/Members";
	};

	return (
		<Container className={styles.section}>
			<h2>Payment Required</h2>
			<div>You must update your credit card before continuing</div>
			<PaymentForm history={props.history} signup={false} afterSave={onAfterSave} />;
		</Container>
	);
};

export default PaymentRequired;
