import axios from "axios";
import {
  GET_ALL,
} from "./planActionTypes";

export default dispatch => {
  const getAll = async ()  => {
    var plans = await _getAll();
    dispatch({
      type: GET_ALL,
      payload: plans
    })
  };
  return { getAll };
};

const _getAll = async () => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/Plan/GetAll?webpage=${window.webpageDetails.webpageName}`,
      method: "GET",
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(response => {
        console.log("Error geting all", response);
        reject();
      });
  });
};

