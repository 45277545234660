import React, { useState, useEffect } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Styles from './preview.module.css';
import { relativeTimeRounding } from 'moment';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Inbox = (props) => {
	const [numberOfPages, setNumberOfPages] = useState(1);
	const [pageNumber, setPageNumber] = useState(1);
	const [loading, setLoading] = useState(0);

	useEffect(
		() => {
			setLoading(0);
			if (props.loadingMail) {
				window.counter = 0;
				window.internalCounter = 0;
				window.loader = setInterval(() => {
					window.internalCounter++;

					if (window.counter < 20) {
						setLoading(window.counter);
						window.counter++;
					} else if (window.counter <= 40) {
						if (window.internalCounter % 2 === 0) {
							setLoading(window.counter);
							window.counter++;
						}
					} else if (window.counter <= 60) {
						if (window.internalCounter % 5 === 0) {
							setLoading(window.counter);
							window.counter++;
						}
					} else if (window.counter <= 80) {
						if (window.internalCounter % 10 === 0) {
							setLoading(window.counter);
							window.counter++;
						}
					} else if (window.counter <= 100) {
						if (window.internalCounter % 20 === 0) {
							setLoading(window.counter);
							window.counter++;
						}
					}
				}, 100);
			} else {
				clearInterval(window.loader);
			}
			return () => {
				clearInterval(window.loader);
			};
		},
		[props.loadingMail]
	);

	const onDocumentLoadSuccess = ({ numPages }) => {
		setPageNumber(1);
		setNumberOfPages(numPages);
	};

	const onPrevPage = () => {
		setPageNumber(pageNumber - 1);
	};

	const onNextPage = () => {
		setPageNumber(pageNumber + 1);
	};

	if (props.loadingMail) {
		return (
			<div style={{ width: '100%', padding: '10px' }}>
				<ProgressBar now={loading} label={loading + '%'} />
			</div>
		);
	} else if (numberOfPages === 0) {
		return <div>Loading</div>;
	} else {
		if (props.mail) {
			return (
				<div className={Styles.previewWrapper}>
					<Document
						file={'data:application/pdf;base64,' + props.mail}
						onLoadSuccess={onDocumentLoadSuccess}
						className={Styles.pdfDocument}
						loading={
							<div style={{ width: '100%' }}>
								<ProgressBar now={100} label={'100%'} />
							</div>
						}
					>
						<Page object-fit="fill" className={Styles.pdfPage} pageNumber={pageNumber} />
					</Document>
					<div className={Styles.pagerSection}>
						{pageNumber > 1 ? (
							<FontAwesomeIcon icon="caret-square-left" onClick={onPrevPage} className="pointer" />
						) : (
							''
						)}
						&nbsp;&nbsp; Page {pageNumber} / {numberOfPages} &nbsp;&nbsp;
						{pageNumber !== numberOfPages ? (
							<FontAwesomeIcon icon="caret-square-right" onClick={onNextPage} className="pointer" />
						) : (
							''
						)}
					</div>
				</div>
			);
		} else {
			return <div className={Styles.loading}>
				<div className={Styles.instructionWrapper}>
					<div className={Styles.instructions}>
						<FontAwesomeIcon icon="mail-bulk" className={Styles.instructionsIcons} />
						<div className="text-center">
							Nothing is selected
						</div>
						<br></br><br></br>

						<div><b>Mail Actions (found in toolbar above mail):</b></div>
						<br></br>

						<table>
							<tbody>
								<tr>
									<td style={{ paddingRight: "20px" }}>
										<FontAwesomeIcon icon="check-circle" />
									</td>
									<td>
										Select all mail items
									</td>
								</tr>
								<tr>
									<td>
										<img src={`/members/images/document-scanning.png`} alt="scan" />
									</td>
									<td>
										Open and scan mail
									</td>
								</tr>
								<tr>
									<td>
										<FontAwesomeIcon icon="share" />
									</td>
									<td>
										Forward mail
									</td>
								</tr>
								<tr>
									<td>
										<FontAwesomeIcon icon="times" />
									</td>
									<td>
										Shred mail (keep digital copy)
									</td>
								</tr>
								<tr>
									<td>
										<FontAwesomeIcon icon='trash-alt' />
									</td>
									<td>
										Delete mail (shred physical &amp; delete digital copy)
									</td>
								</tr>
								<tr>
									<td>
										<FontAwesomeIcon icon="cloud-download-alt" />
									</td>
									<td>
										Download mail (view mail at a higher resolution)
									</td>
								</tr>
								<tr>
									<td>
										<FontAwesomeIcon icon="paper-plane"></FontAwesomeIcon>
									</td>
									<td>
										Forward mail via email
									</td>
								</tr>
								<tr>
									<td>
										<FontAwesomeIcon icon="undo-alt"></FontAwesomeIcon>
									</td>
									<td>
										Return mail to sender
									</td>
								</tr>
								<tr>
									<td>
										<FontAwesomeIcon icon="arrows-alt" />
									</td>
									<td>
										Move digital copy to folder
									</td>
								</tr>
							</tbody>
						</table>

					</div>
				</div>
			</div>
		}
	}
};
export default Inbox;
