import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Container, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import GlobalContext from 'context/globalContext';
import Styles from './forward.module.css';

const DOMESTIC_TAB = 'domestic';
const INTERNATIONAL_TAB = 'international';
const DEFAULT_COUNTRY = 'Australia';

const SelectAddress = (props) => {
	/*** HOOKS  */
	const globalContext = useContext(GlobalContext);
	const [ activeTab, setActiveTab ] = useState(DOMESTIC_TAB);
	const [ showNewAddress, setShowNewAddress ] = useState(false);
	const [ selectedAddress, setSelectedAddress ] = useState(null);
	const [ internationalEnabled, setInternationalEnabled ] = useState(false);

	const [ address, setAddress ] = useState({
		fullName: '',
		street: '',
		street2: '',
		country: '',
		city: '',
		state: '',
		postcode: ''
	});
	const [ country, setCountry ] = useState({
		label: DEFAULT_COUNTRY,
		value: DEFAULT_COUNTRY
	});

	/*** END HOOKS ***/
	
	/*** PROPERTIES  ***/
	
	const loaded = globalContext.address.response.loaded;
	const countryOptions = globalContext.countryList.map((country) => {
		return { value: country.name, label: country.name };
	});

	/*** END PROPERTIES ****/

	/**** USE EFFECTS  ****/

	useEffect(() => {
		const fetch = async () => {
			globalContext.address.fetch();
		};

		fetch();
	//eslint-disable-next-line
	}, []);

	useEffect(
		() => {
			if (activeTab === DOMESTIC_TAB && 
				globalContext.address.response.domestic &&
				globalContext.address.response.domestic.length > 0 && 
				!selectedAddress) {
				if (globalContext.address.response.domestic.length > 0) {
					setSelectedAddress(globalContext.address.response.domestic[0].addressId);
				}
			}
		},
		//eslint-disable-next-line
		[ globalContext.address.response.domestic ]
	);

	useEffect(
		() => {
			if (activeTab === INTERNATIONAL_TAB && 
				globalContext.address.response.international &&
				globalContext.address.response.international.lenght > 0 && 
				!selectedAddress) {

				setSelectedAddress(globalContext.address.response.international[0].addressId);
			}
    },
    //eslint-disable-next-line
		[ globalContext.address.response.international ]
	);

	useEffect(
		() => {
			if (activeTab === INTERNATIONAL_TAB) {
				if (
					globalContext.address.response.international &&
					globalContext.address.response.international.length > 0
				) {
					setSelectedAddress(globalContext.address.response.international[0].addressId);
				} else {
					setSelectedAddress(null);
				}
			} else if (activeTab === DOMESTIC_TAB) {
				if (globalContext.address.response.domestic && globalContext.address.response.domestic.length > 0) {
					setSelectedAddress(globalContext.address.response.domestic[0].addressId);
				} else {
					setSelectedAddress(null);
				}
			}
		},
			//eslint-disable-next-line
      [ activeTab ]
	);

	useEffect(
		() => {
			setInternationalEnabled(!!globalContext?.account?.response?.account?.idVerified);
    },
    //eslint-disable-next-line
	[ globalContext?.account?.response?.account?.idVerified ]
	);
  
	/**** END USE EFFECTS  ****/
	

	/**** EVENTS  ******/

	const onSaveAddress = async () => {
		let newAddress = address;
		if (activeTab === DOMESTIC_TAB) {
			address.domestic = true;
			newAddress = { ...address, country: 'Australia' };
		} else {
			address.domestic = false;
			newAddress = { ...address, country: country.label };
		}
		let savedAddress = await globalContext.address.add(newAddress);
		setShowNewAddress(false);
		setAddress({});
		setSelectedAddress(savedAddress.addressId);
	};

	const onShowEditAddress = () => {
		let address = getAddress(selectedAddress);
		console.log("address", address);
		let country = countryOptions.filter(a=> a.label === address.country)[0];
		setAddress(address);
		setCountry(country);
		setShowNewAddress(true);
	}

	const onRemoveAddress = async () => {
		var removeAddress = selectedAddress;
		setSelectedAddress(null);
		await globalContext.address.remove(removeAddress);
	}

	/*** END EVENTS ****/

	/*** HELPERS ****/

	const getAddress = (addressId) => {
		let selectedAddress;
		if (activeTab === DOMESTIC_TAB) {
			selectedAddress = globalContext.address.response.domestic.filter(a=> parseInt(a.addressId) === parseInt(addressId))[0];
		}else {
			selectedAddress = globalContext.address.response.international.filter(a=> parseInt(a.addressId) === parseInt(addressId))[0];
		}
		return selectedAddress;
	}

	/*** END HELPERS ***/


	/** RENDERS ***/

	const renderOptions = () => {
		if (activeTab === DOMESTIC_TAB) {
			if (globalContext.address.response.domestic.length === 0) {
				return <option>No saved domestic addresses...</option>;
			} else {
				return globalContext.address.response.domestic.map((address) => {
					return (
						<option value={address.addressId} key={address.addressId}>
							{address.fullName} - {address.street}
						</option>
					);
				});
			}
		} else {
			if (globalContext.address.response.international.length === 0) {
				return <option>No saved international addresses...</option>;
			} else {
				return globalContext.address.response.international.map((address) => {
					return (
						<option value={address.addressId} key={address.addressId}>
							{address.fullName} - {address.street} - {address.country}
						</option>
					);
				});
			}
		}
	};

	/*** END RENDERS ***/

	return (
		<React.Fragment>
			<section className={Styles.address + ' vh-80 vertical-center'}>
				{!loaded ? (
					<div>loading...</div>
				) : (
					<div className={Styles.tab}>
						<Row className={`${Styles.backSection} mx-15`}>
							<h2 className={Styles.forwardTitle}>
								<FontAwesomeIcon icon="arrow-left" onClick={props.onBack} className="pointer" />{' '}
							</h2>
						</Row>
						<nav>
							<ul>
								<li
									className={activeTab === DOMESTIC_TAB ? 'tab-current pointer' : 'pointer'}
									onClick={() => setActiveTab(DOMESTIC_TAB)}
								>
									<div>
										<img src={`/members/images/mailbox.png`} alt="domestic" />
										<span>Domestic</span>
									</div>
								</li>
								<li
                  className={activeTab === INTERNATIONAL_TAB ? 
                    `tab-current pointer ${!internationalEnabled && Styles.disabled}` : 
                    `pointer ${!internationalEnabled && Styles.disabled}`}
                  onClick={() => internationalEnabled && setActiveTab(INTERNATIONAL_TAB)}
								>
									<div>
										<img src={`/members/images/gps.png`} alt="international" />
										<span>International</span>
									</div>
								</li>
							</ul>
						</nav>
						<div className={Styles.forwardBody}>
							<section className={Styles.addressBody}>
								<Container>
									<Row>
										<Col sm={10}>
											<label>To</label>
											<select
												className="form-control"
												onChange={(e) => {
													setSelectedAddress(e.target.value);
												}}
												value={selectedAddress != null ? selectedAddress : 0}
											>
												{renderOptions()}
											</select>
										</Col>
										<Col sm={2} className="vertical-center">
											<Button
												variant="red"
												className="next mt-30 w-160"
												onClick={() => props.onNext(selectedAddress)}
												disabled={!selectedAddress}
											>
												Next
											</Button>
										</Col>
									</Row>
									<Row className="button-address">
										<Col sm={12} className="text-center">
											<Button
												variant="transparent"
												className={Styles.newAddress}
												onClick={() => setShowNewAddress(true)}
											>
												New Address
											</Button>
											
											{selectedAddress && 
											<React.Fragment>
											<Button
												variant="transparent"
												className={Styles.newAddress}
												onClick={onShowEditAddress}
												>
												Edit Address
											</Button>
											<Button
											variant="transparent"
											className={Styles.newAddress}
											onClick={onRemoveAddress}
										>
											Delete Address
										</Button>
										</React.Fragment>
											}
											
										</Col>
									</Row>
								</Container>
							</section>
						</div>
					</div>
				)}
			</section>

			<Modal show={showNewAddress} size="lg" className={Styles.addressModal}>
				<Modal.Header>
					<Modal.Title className={Styles.newAddressTitle}>Enter New Address</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form>
						<div className="form-group">
							<label htmlFor="fullname">Full Name</label>
							<input
								type="text"
								className="form-control"
								id="fullname"
								placeholder="Full Name"
								onChange={(e) => setAddress({ ...address, fullName: e.target.value })}
								value={address.fullName || ""}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="streetAddress">Street Address</label>
							<input
								type="text"
								className="form-control"
								id="streetAddress"
								placeholder="Street Address"
								onChange={(e) => setAddress({ ...address, street: e.target.value })}
								value={address.street || ""}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="streetAddress">Street Line 2</label>
							<input
								type="text"
								className="form-control"
								id="streetAddress"
								placeholder="Street Address"
								onChange={(e) => setAddress({ ...address, street2: e.target.value })}
								value={address.street2 || ""}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="city">City</label>
							<input
								type="text"
								className="form-control"
								id="city"
								placeholder="City"
								onChange={(e) => setAddress({ ...address, city: e.target.value })}
								value={address.city || ""}
							/>
						</div>
						<div className="form-group row">
							<div className="col-6">
								<label htmlFor="state">State</label>
								<input
									type="text"
									className="form-control"
									id="state"
									placeholder="State"
									onChange={(e) => setAddress({ ...address, state: e.target.value })}
									value={address.state || ""}
								/>
							</div>
							<div className="col-6">
								<label htmlFor="zipCode">Postcode/Zip Code</label>
								<input
									type="text"
									className="form-control"
									id="zipCode"
									placeholder="Zip Code"
									onChange={(e) => setAddress({ ...address, postcode: e.target.value })}
									value={address.postcode || ""}
								/>
							</div>
						</div>
						{activeTab === INTERNATIONAL_TAB && (
							<div className="row">
								<div className="col-12">
									<label htmlFor="country">Country</label>
									<Select
										id="country"
										onChange={(e) => {
											setCountry(e);
										}}
										value={country || ""}
										options={countryOptions}
									/>
								</div>
							</div>
						)}
						<div className="form-group">
							<label htmlFor="fullname">Delivery Address Phone Number</label>
							<input
								type="text"
								className="form-control"
								id="contactNumber"
								placeholder="Phone Number"
								onChange={(e) => setAddress({ ...address, contactNumber: e.target.value })}
								value={address.contactNumber || ""}
							/>
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => setShowNewAddress(false)} variant="secondary">
						Close
					</Button>
					<Button onClick={onSaveAddress} variant="purple">
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
		</React.Fragment>
	);
};

export default SelectAddress;
