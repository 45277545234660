import React, { useContext, useMemo, useEffect } from 'react';
import { Button, Row, Col, Alert } from 'react-bootstrap';
import GlobalContext from 'context/globalContext';
import { useTranslation } from 'react-i18next';


const PauseAccount = (props) => {
	const globalContext = useContext(GlobalContext);
	const { t  } = useTranslation();

	let accountPaused = useMemo(
		() => {
			return globalContext.account.response.account.status === 'On Hold';
		},
		[ globalContext.account.response.account.status ]
	);

	useEffect(() => {
		globalContext.mail.mailHeld();
	}, []);

	const onUnpause = () => {
		globalContext.account.unpause();
	};

	const onPause = () => {
		globalContext.account.pause();
	}

	return (
		<div className="mt-2">
			{accountPaused && (
				<Row>
					<Col xs={12}>
						<h1>{t('Reactivate your account?')}</h1>
						<Button className="mt-2" onClick={onUnpause}>
							{t('Unpause')}
						</Button>
					</Col>
				</Row>
			)}
			{!accountPaused && (
				<Row>
					<Col xs={12}>
						<h1>{t('Are you absolutely sure?')}</h1>
						<small>
							{t('No fees will be charged to paused accounts, however any mail recieved will be returned to sender. You may unpause your account at any time. Unused credit still expires after 12 months.')}
						</small>
					</Col>
					{globalContext.mail.response.mailHeld === false && (
					
						<Col xs={12}>
							<Button className="mt-2" onClick={onPause}>{t("Yes I'm sure")}</Button>
						</Col>
					)}

					{globalContext.mail.response.mailHeld === true && (
					<Col xs={12}>
						<Alert variant="danger" className="mt-2">
							{t('You can not put your account on pause while you have mail stored. Either shred or forward stored mail.')}
						</Alert>
					</Col>
					)}
				</Row>
			)}
		</div>
	);
};
export default PauseAccount;
