import axios from 'axios';
import { GET_TRANSACTION } from './transactionActionTypes';

export default (dispatch) => {

    const getTransactions = async(page, pageSize) => {
        var transactions = await _getTransaction(page, pageSize);
        dispatch({
            payload: { log: transactions, page: page },
            type: GET_TRANSACTION
        });
        return transactions.length > 0;
    };
    return { getTransactions };
};

const _getTransaction = async (page, pageSize) => {
    return new Promise((resolve, reject) => {
        axios({
                url: `/api/Transaction?page=${page}&pageSize=${pageSize}`,
                method: 'GET',
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((response) => {
                console.log('Error getting audit', response);
                reject();
            });
    });
}