import React, { useContext } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import Styles from "./forward.module.css";
import GlobalContext from "context/globalContext";

function Error(props) {
    const globalContext = useContext(GlobalContext);

    const onUpdatePaymentMethod = () => {
        globalContext.accountSettings.setNav("payment-method");
        props.history.push("/Members/Account");
    }

    const onMakeManualPayment = () => {
        globalContext.accountSettings.setNav("once-off-payment");
        props.history.push("/Members/Account");
    }

    return (
        <React.Fragment>
            <Container className={Styles.sentWrapper}>
                <div className={Styles.errorContainer}>
                    <div className={Styles.sentWrapper}>
                        <img className={Styles.sentImg} src={`/members/images/send.png`} alt="Sent" />
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <p>
                                        Your account has insufficient funds and there was an error charging your credit card.
                                        Manually top up your account, update your credit card or try again.
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Button
                                        className="mt-3 full-width"
                                        variant="warning"
                                        onClick={onUpdatePaymentMethod}
                                    >
                                        Update Payment Method
                                 </Button>
                                 <Button
                                        className="mt-3 full-width"
                                        variant="warning"
                                        onClick={onMakeManualPayment}
                                    >
                                        Make a manual payment
                                 </Button>

                                 <Button
                                        className="mt-3 full-width"
                                        variant="red"
                                        onClick={props.onTryAgain}
                                    >
                                        Try again
                                 </Button>
                                </Col>
                            </Row>

                        </Container>
                    </div>
                </div>
            </Container>
        </React.Fragment>

    )
}

export default Error;