const AUTH_KEY = 'hsAuth';

export default {
	saveToken: (account) => {
		if (!account) {
			window.location.href = '/login';
		} else {
			if (account === null) {
				localStorage.setItem(AUTH_KEY, null);
			} else {
				localStorage.setItem(AUTH_KEY, JSON.stringify(account));
			}
		}
	},
	getAccount: () => {
		var account = JSON.parse(localStorage.getItem(AUTH_KEY));
		return account;
	},
	// isAuthenticated: () => {
	// 	var jwtResponse = JSON.parse(localStorage.getItem(AUTH_KEY));
	// 	if (jwtResponse === null) {
	// 		return false;
	// 	}
	// 	if (new Date() > jwtResponse.expireDate) {
	// 		return false;
	// 	}
	// 	return true;
	// },
	logout: () => {
		localStorage.removeItem(AUTH_KEY);
	}
};
