import React, { useState, useContext, useEffect } from 'react';
import TopNav from 'components/members/topnav';
import { Button, Col, Row, Container, Form } from 'react-bootstrap';
import WizardNav from './WizardNav';
import PhotoVerificationUpload from './PhotoVerificationUpload';
import PhotoVerificationComplete from './PhotoVerificationComplete';
import GlobalContext from 'context/globalContext';
import Styles from './photoVerification.module.css';
import { useTranslation } from 'react-i18next';


const PhotoVerification = (props) => {
	const globalContext = useContext(GlobalContext);
	const [ navigationIndex, setNavigiationIndex ] = useState(1);
	const [ idType, setIdType ] = useState('Drivers License / Government ID Card');
	const [ idTypeChosen, setIdTypeChosen ] = useState(null);
	const { t  } = useTranslation();

	useEffect(() => {
		const getVerificationCode = async () => {
			globalContext.verification.getVerificationCode();
		};

		getVerificationCode();
		// eslint-disable-next-line
	}, []);

	const onDelete = (side) => {
		globalContext.verification.deleteId(side);
	}

	const onUpload = async (file, photoSide, onUploadProgress) => {
		await globalContext.verification.uploadId(file[0], photoSide, onUploadProgress);
	};

	const getNavigation = () => {
		var navigationArray = [];
		let counter = 1;
		navigationArray.push({
			title: 'Front',
			active: true,
			body: (
				<PhotoVerificationUpload
					photoSide="front"
					onUpload={onUpload}
					onDelete={() => onDelete("front")}
					base64={globalContext.verification.response ? globalContext.verification.response.front : null}
				/>
			),
			key: counter++
		});

		if (idTypeChosen === 'Drivers License / Government ID Card') {
			navigationArray.push({
				title: 'Back',
				active: false,
				body: (
					<PhotoVerificationUpload
						photoSide="back"
						onUpload={onUpload}
						onDelete={() => onDelete("back")}
						base64={globalContext.verification.response ? globalContext.verification.response.back : null}
					/>
				),
				key: counter++
			});
		}

		navigationArray.push({
			title: 'Selfie',
			active: false,
			body: (
				<PhotoVerificationUpload
					photoSide="selfie"
					onUpload={onUpload}
					onDelete={() => onDelete("selfie")}
					globalContext={globalContext}
					base64={globalContext.verification.response ? globalContext.verification.response.selfie : null}
				/>
			),
			key: counter++
		});

		navigationArray.push({
			title: 'Complete',
			active: false,
			body: <PhotoVerificationComplete />,
			key: counter++
		});

		return navigationArray;
	};

	const showNext = (verification) => {
		if (!verification) return false;
		switch (navigationIndex) {
			case 1: //Front side Image
				return verification.front;
			case 2: //Back side of image
				if (idTypeChosen === 'Passport') {
					return true;
				}
				return verification.back;
			case 3: //Selfie image
				return verification.selfie;
			case 4:
				return true;
			default:
				return false;
		}
	};

	const onNext = () => {
		var nextIndex = navigationIndex + 1;
		if (nextIndex <= getNavigation().length) {
			setNavigiationIndex(nextIndex);
		} else {
			props.history.push('/Members/Account/Verification');
		}
	};

	const onPrev = () => {
		var nextIndex = navigationIndex - 1;
		if (!idTypeChosen){
			props.history.push('/Members/Account/Verification');
		}
		else if (nextIndex > 0) {
			setNavigiationIndex(nextIndex);
		} else {
			setIdTypeChosen(false);
			setNavigiationIndex(1);
		}
	};

	const onIdChosen = () => {
		setIdTypeChosen(idType);
	};

	return (
		<React.Fragment>
			<TopNav history={props.history} />
			<Container className={Styles.responsiveContainer}>
				<Row>
					<Col lg={{ offset: 2, span: 8 }} sm={12} className={Styles.wizardContainer}>
						<div className={Styles.wizard + ' mt-4'}>
							<div className="header">
								<h3 dangerouslySetInnerHTML={{__html: t("<b>VERIFY</b> YOUR IDENTITY<br />")}}>									
								</h3>
							</div>

							{!idTypeChosen && (
								<React.Fragment>
									<h4 className="info-text">{t('Choose your government issued identification type')}</h4>
									<div className={Styles.selectContainer}>
										{idType === 'Passport' ? (
											<img
												src={`/members/images/passport.png`}
												alt="passport"
												className={Styles.licenseImage}
											/>
										) : (
											<img
												src={`/members/images/license-logo.png`}
					
												alt="license"
												className={Styles.licenseImage}
											/>
										)}
										<Form.Control
											as="select"
											onChange={(e) => setIdType(e.target.value)}
											value={idType}
										>
											<option value="Drivers License / Government ID Card">{t('Drivers License / Government ID Card')}</option>
											<option value="Passport">{t('Passport')}</option>
										</Form.Control>
									</div>
									<div className={Styles.idTypeChoiceButtons}>
										<Button className="float-left" onClick={onPrev}>
											{t('Back')}
										</Button>

										<Button className="float-right" onClick={onIdChosen}>
											{t('Next')}
										</Button>
									</div>
								</React.Fragment>
							)}

							{idTypeChosen && (
								<React.Fragment>
									<WizardNav navs={getNavigation()} activeIndex={navigationIndex} />
									{getNavigation().filter((nav) => nav.key === navigationIndex)[0].body}
									<div className={Styles.wizardFooter}>
										{navigationIndex !== 4 && (
											<Button className="float-left" onClick={onPrev}>
												{t('Back')}
											</Button>
										)}
										{showNext(globalContext.verification.response) && (
											<Button className="float-right" onClick={onNext}>
												{t('Next')}
											</Button>
										)}
									</div>
								</React.Fragment>
							)}
						</div>
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	);
};
export default PhotoVerification;
