import React, { useState, useContext, useEffect, useMemo } from 'react';
import GlobalContext from 'context/globalContext';
import { ButtonGroup, Button, Col, Row, Dropdown } from 'react-bootstrap';
import MailItem from './elements/MailItem';
import ReturnToSenderModal from './elements/ReturnToSenderModal';
import RescanModal from './elements/RescanModal';
import ConfirmModal from './elements/ConfirmModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectFolderModal from './elements/SelectFolderModal';
import EmailModal from './elements/EmailModal';
import './context-menu.css';
import Styles from './mail.module.css';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';


const Mail = (props) => {
	const globalContext = useContext(GlobalContext);
	const [ showReturnToSender, setShowReturnToSender ] = useState(false);
	const [ loadingForward, setLoadingForward] = useState(false);
	const [ showFolderSelect, setShowFolderSelect ] = useState(false);
	const [ confirm, setConfirm ] = useState({ show: false });
	const [ searchQuery, setSearchQuery ] = useState('');
	const [ downloading, setDownloading ] = useState(false);
	const [ showEmailModal, setShowEmailModal ] = useState(false);
	const [ showRescan, setShowRescan ] = useState(false);
	const [ t ] = useTranslation();

	useEffect(() => {
		if (!globalContext?.plan?.response?.plans) {
			globalContext.plan.getAll();
		}
	}, []);

	const currentPlan = useMemo(() => {
		if (globalContext?.plan?.response?.plans && globalContext?.account?.response?.account?.planId) {
			return globalContext?.plan?.response?.plans.filter(a => a.planId === globalContext?.account.response.account.planId)[0];

		}
	}, [globalContext?.plan?.response?.plans, globalContext?.account?.response?.account?.planId])

	const onFolderSelected = async (folderId) => {
		setShowFolderSelect(false);
		let currentSelectedMail = [ ...props.selectedMailList ];
		let selectedMailList = currentSelectedMail.map((mail) => mail.mailId);
		globalContext.mail.moveToFolder(selectedMailList, folderId);
		props.clearSelected();
	};

	const onMailClicked = (mailId) => {
		let currentSelectedMail = [ ...props.selectedMailList ];
		let index = currentSelectedMail.indexOf(mailId);
		if (index > -1) {
			currentSelectedMail.splice(index, 1);
		} else {
			currentSelectedMail.push(mailId);
		}
		props.setSelectedMailList(currentSelectedMail);
	};

	const saveReturnToSender = async (reason) => {
		let currentSelectedMail = [ ...props.selectedMailList ];
		var mailIds = currentSelectedMail.map((mail) => mail.mailId);
		await globalContext.mail.returnToSender(mailIds, reason);
		props.clearSelected();
	};

	const saveRescan = async (reason, mailId) => {
		await globalContext.mail.markPendingRescan(mailId, reason);
	};

	const onDownload = async () => {
		if (!downloading) {
			setDownloading(true);
			let currentSelectedMail = [ ...props.selectedMailList ];
			let selectedMailList = currentSelectedMail.map((mail) => mail.mailId);
			await globalContext.mail.downloadBulk(selectedMailList);
			setDownloading(false);
		}
	};

	const onDelete = async () => {
		var confirm = {
			show: true,
			title: t('Are you sure?'),
			body:
				t('Deleted documents will be shredded, and the digital version will be removed from the recycling bin after 30 days'),
			onConfirm: async () => {
				let currentSelectedMail = [ ...props.selectedMailList ];
				let selectedMailList = currentSelectedMail.map((mail) => mail.mailId);
				await globalContext.mail.recycle(selectedMailList);
				props.clearSelected();
				setConfirm({ ...confirm, show: false });
			},
			onReject: () => {
				setConfirm({ ...confirm, show: false });
			}
		};
		setConfirm(confirm);
	};

	const onShred = async () => {
		var confirm = {
			show: true,
			title: t('Are you sure?'),
			body: t('Are you sure you would like to shred the selected items?'),
			onConfirm: async () => {
				let currentSelectedMail = [ ...props.selectedMailList ];
				let selectedMailList = currentSelectedMail.map((mail) => mail.mailId);
				globalContext.mail.markPendingShred(selectedMailList);
				props.clearSelected();

				setConfirm({ ...confirm, show: false });
			},
			onReject: () => {
				setConfirm({ ...confirm, show: false });
			}
		};
		setConfirm(confirm);
	};

	const onScan = async () => {
		let currentSelectedMail = [ ...props.selectedMailList ];
		let selectedMailList = currentSelectedMail.map((mail) => mail.mailId);
		globalContext.mail.markPendingScan(selectedMailList);
		props.clearSelected();
	};

	const onRescan = (mailId) => {
		setShowRescan(mailId);

	}

	const onCancelByEllipse = async (mailId) => {
		globalContext.mail.cancelPending([ mailId ]);
	};

	const onUpdateName = async (mailId, name) => {
		await globalContext.mail.updateName(mailId, name);
	};

	const onSelectAll = () => {
		if (props.selectedMailList.length === globalContext.mail.response.list.length) {
			props.setSelectedMailList([]);
		} else {
			props.setSelectedMailList(globalContext.mail.response.list);
		}
	};

	const onForward = async () => {
		setLoadingForward(true);
		await props.onForward();
		setLoadingForward(false);

	}

	const searchKeyDown = async (e) => {
		if (e.key === 'Enter') {
			globalContext.mail.search(searchQuery);
		}
	};

	const renderMailItems = () => {
		if (!globalContext.mail.response.list) {
			return t('loading') + "...";
		}

		if (globalContext.mail.response.list.length === 0) {
			return <div className={Styles.noMail}>{t("We didn't find anything to show here")}</div>;
		}

		return globalContext.mail.response.list.map((mail) => {
			return (
				<MailItem
					key={mail.mailId}
					item={mail}
					isChecked={props.selectedMailList.indexOf(mail) > -1}
					onClick={onMailClicked}
					onCancel={onCancelByEllipse}
					onUpdateName={onUpdateName}
					onRescan={() => onRescan(mail.mailId)}
					active={props.activeMail?.mailId === mail.mailId}
					setActive={(activeItem) => props.setActiveMail(activeItem)}
				/>
			);
		});
	};

	return (
		<div className={Styles.emailList}>
			<div>
				<div className={`${Styles.emailActionContainer} actiontoolbar`}>
					<div className={Styles.emailAction}>
						<ButtonGroup>
							<Button onClick={onSelectAll} variant="mail-action" title={t("Select All")}>
								<FontAwesomeIcon icon="check-circle" color="rgba(0,0,0,.5)" />
							</Button>
							{<Button
								disabled={!props.showScan || currentPlan?.parcelForwardOnly}
								variant="mail-action"
								title={t("Open and Scan")}
								onClick={onScan}
							>
								<div>
									<img src={`/members/images/document-scanning.png`} alt="scan" />
								</div>
							</Button>}
							<Button
								disabled={!props.showForwardShred}
								variant="mail-action"
								title={t("Forward")}
								onClick={() => onForward()}
							>
								<div>
									{loadingForward ? <FontAwesomeIcon icon='spinner' spin></FontAwesomeIcon> : <FontAwesomeIcon icon='share'></FontAwesomeIcon>}
								</div>
							</Button>
							<Button
								disabled={!props.showForwardShred}
								variant="mail-action"
								title={t("Shred")}
								onClick={onShred}
							>
								<FontAwesomeIcon icon="times" />
							</Button>
							{props.showOpenScan && (
								<Button variant="mail-action" title={t("Open and Scan")} disabled={!props.showOpenScan}>
									<img src={`/members/images/document-scanning.png`} alt={t("Open and Scan")} />
								</Button>
							)}
							<Button
								variant="mail-action"
								title={t("Delete")}
								disabled={props.selectedMailList.length === 0}
								onClick={onDelete}
								className={Styles.hideOnMobile}
							>
								<FontAwesomeIcon icon='trash-alt' />
							</Button>
							<Button
								variant="mail-action"
								title={t("Download")}
								disabled={!props.showDownload}
								onClick={onDownload}
							>
								{!downloading ? (
									<FontAwesomeIcon icon="cloud-download-alt" color="rgba(0,0,0,.5)" />
								) : (
									<FontAwesomeIcon icon="spinner" spin color="rgba(0,0,0,.5)" />
								)}
							</Button>
							<Button
								variant="mail-action"
								onClick={() => setShowEmailModal(true)}
								disabled={!props.showDownload}
								title={t("Email")}
							>
								<FontAwesomeIcon icon="paper-plane" color="rgba(0,0,0,.5)" />
							</Button>
							<Button
								variant="mail-action"
								onClick={() => setShowReturnToSender(true)}
								disabled={!props.showScan}
								title={t("Return to sender")}
								className={Styles.hideOnMobile}
							>
								<FontAwesomeIcon icon="undo-alt"></FontAwesomeIcon>
							</Button>
							<Button
								variant="mail-action"
								title={t("Move To Folder")}
								onClick={() => setShowFolderSelect(true)}
								disabled={props.selectedMailList.length === 0}
								className={Styles.hideOnMobile}
							>
								<FontAwesomeIcon icon="arrows-alt" color="rgba(0,0,0,.5)" />
							</Button>
							<Dropdown className={`${Styles.dropdown} ${Styles.showOnMobile}`}>
								<Dropdown.Toggle
									as="button"
									className={`${Styles.toolbarButton} ${Styles.mobileActionButton} btn btn-mail-action`}
								>
									<FontAwesomeIcon icon="ellipsis-v" />
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item
										disabled={!props.showScan}
										onClick={() => setShowReturnToSender(true)}
									>
										<FontAwesomeIcon icon="undo-alt"></FontAwesomeIcon>
										{'  '}
										{t('Return to sender')}
									</Dropdown.Item>
									<Dropdown.Item
										disabled={props.selectedMailList.length === 0}
										onClick={() => setShowFolderSelect(true)}
									>
										<FontAwesomeIcon icon="arrows-alt" color="rgba(0,0,0,.5)" />
										{'  '}
										{t('Move to folder')}
									</Dropdown.Item>
									<Dropdown.Item disabled={!props.showForwardShred} onClick={onShred}>
									<FontAwesomeIcon icon="times" />
										{'  '}
										{t('Shred')}
									</Dropdown.Item>
									<Dropdown.Item disabled={props.selectedMailList.length === 0} onClick={onDelete}>
									<FontAwesomeIcon icon='trash-alt' />
										{'  '}
										{t('Delete')}
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</ButtonGroup>
					</div>
				</div>
			</div>

			<div className={Styles.searchContainer}>
				<div className={Styles.searchWrapper}>
					<span className={Styles.searchIcon}>
						<i className="icon ion-ios-search" />
					</span>
					<input
						type="text"
						className={Styles.searchInput + ' form-control'}
						placeholder={t("Search") + "..."}
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						onKeyDown={searchKeyDown}
					/>
				</div>
			</div>
			<Scrollbars style={{ height: 'calc(100VH - 166px)' }} autoHide autoHideTimeout={1000}>
				<div className={Styles.mailItemSection + ' nav bg-white'}>
					<Row className="full-width">
						<Col xs={12} className={Styles.mailCol}>
							{renderMailItems()}
						</Col>
					</Row>
				</div>
			</Scrollbars>
			<EmailModal show={showEmailModal} onClose={() => setShowEmailModal(false)} onEmail={props.onEmail} />
			<SelectFolderModal
				show={showFolderSelect}
				onFolderSelected={onFolderSelected}
				selectedFolder={props.selectedFolder}
				onClose={() => setShowFolderSelect(false)}
			/>

			<ReturnToSenderModal
				show={showReturnToSender}
				setShowReturnToSender={setShowReturnToSender}
				saveReturnToSender={saveReturnToSender}
			/>

			<RescanModal
				show={showRescan}
				setShowRescan={setShowRescan}
				saveRescan={saveRescan}
			/>

			<ConfirmModal
				show={confirm.show}
				title={confirm.title}
				body={confirm.body}
				onConfirm={confirm.onConfirm}
				onReject={confirm.onReject}
			/>
		</div>
	);
};
export default Mail;
