import React from 'react';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import Styles from '../forwardLog.module.css';

function ForwardedItem(props) {
	return (
		<Row>
			<Col xs={12} className={`${Styles.forwardItem} ml-3`}>
				<div className={Styles.dateSection}>
					<div className="d-flex justify-content-center align-items-center">
						<h1 className="mb-0 mr-2 text-primary font-weight-normal">{moment(props.forward.dateSent).format("DD")}</h1>
						<div>
							<p className="font-weight-bold mb-0 text-dark">{moment(props.forward.dateSent).format("MMM")}</p>
							<p className="mb-0">{moment(props.forward.dateSent).format("YYYY")}</p>
						</div>
					</div>
				</div>
				<div className={Styles.detailSection}>
					<p className="text-dark font-weight-bold mb-0">{props.forward.address.street}</p>
					<p className="mb-0">
						{props.forward.address.city} {props.forward.address.postcode}
					</p>
					<p className="mb-0">{props.forward.address.state}</p>
					{props.forward.address.country &&
					props.forward.address.country.toLowerCase() !== 'australia' && (
						<p className="mb-0">{props.forward.address.country}</p>
					)}
				</div>
				<div className={Styles.forwardDetails}>
					<p className="text-dark font-weight-bold mb-0">{props.forward.service}</p>
					<p className="mb-0">{props.forward.numberOfItems} Items</p>
                    {props.forward.trackingNumber && <p className="mb-0">Tracking: {props.forward.trackingNumber}</p>}
				</div>
				<div className={Styles.forwardDetails}>
					<p className="text-dark font-weight-bold mb-0">Courier Service Fee: ${props.forward.ausPostFee.toFixed(2)}</p>
					{window.webpageDetails.showOurFee && <p className="mb-0">{window.webpageDetails.webpageName} Fee: ${props.forward.hotsnailFee.toFixed(2)}</p>}
					{window.webpageDetails.showOurFee && <p className="mb-0">Total: ${(props.forward.ausPostFee + props.forward.hotsnailFee).toFixed(2)}</p>}
				</div>
			</Col>
		</Row>
	);
}

export default ForwardedItem;
