import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import securityHelper from "security/securityHelper";
import { Route, withRouter } from 'react-router-dom';
import GlobalContext from 'context/globalContext';
// import Registration from 'components/registration/Registration.jsx';
import PaymentRequired from 'components/members/paymentRequired/PaymentRequired';
import TermsAndConditions from 'components/public/TermsAndConditions';
// import LoadingOverlay from 'react-loading-overlay';
// import * as registrationStatus from 'enums/registrationStatus';

const PrivateRoute = ({ component: Component, path, location, componentProps, ...rest }) => {	
	// const [ accountLoaded, setAccountLoaded ] = useState(false);
	const globalContext = useContext(GlobalContext);

	useEffect(
		() => {
			const fn = async () => {
				// if (!securityHelper.isAuthenticated()) {
				// 	window.location.href = "/Login";			
				// }
				// else {
				if (!globalContext?.account?.response?.account){
						await globalContext.account.getAccount();
					}
				// }
			};
			fn();
		},
		[ path, location ]
	);

	const render = (props) => {
		return globalContext?.account?.response?.account ? 
			<Component {...props} {...componentProps} /> : 
			null;
	};

// 	//Loading Account
// 	if (!globalContext.account.response.account || !globalContext.plan.response.plans) {
// 		return <LoadingOverlay active={true} spinner text="Getting account" />;
// 	} else if (globalContext.account.response.account.registrationStatus !== registrationStatus.Registered) {
// 		//Send user to Registration pages
// 		return <Route path="/Register" component={Registration} {...rest} />;
// 	} 	


	if (globalContext?.account?.response?.account?.activePayment === false){
		return <Route path="/PaymentRequired" component={PaymentRequired} {...rest} />;
	}else if (globalContext?.account?.response?.account?.termsAndConditionsSigned === false){
		return <Route path="/TermsAndConditions" component={TermsAndConditions} {...rest} />;
	}
	//Send user to private route
	return <Route path={path} render={render} {...rest} />;
};

PrivateRoute.propTypes = {
	component: PropTypes.oneOfType([ PropTypes.element, PropTypes.func ]).isRequired,
	path: PropTypes.oneOfType([ PropTypes.string, PropTypes.arrayOf(PropTypes.string) ]).isRequired
};

export default withRouter(PrivateRoute);
