const mailStatusUtility = {
  getFriendlyName: (mailStatus, mailAction) => {
    if (mailAction === 2){
      return "Shredded";
    }
    else if (mailAction === 3){
      return "Returned to sender";
    }
    switch (mailStatus) {
      case 2:
        return "Unopened";
      case 3:
        return "Opened and scanned";
      case 4:
        return "Forwarded";
      case 5:
      case 6:
        return "Shredded"
      case 7:
        return "Returned to sender";
      default:
        return "";
    }
  }
};

export default mailStatusUtility;
