export const LOGIN = "LOGIN";
export const GET_ACCOUNT = "GET_ACCOUNT";
export const CHOOSE_PLAN = "CHOOSE_PLAN";
export const UPDATE_LOGIN_GUARD = "UPDATE_LOGIN_GUARD";
export const VERIFY_SMS = "VERIFY_SMS";
export const REGISTRATION_COMPLETE = "REGISTRATION_COMPLETE";
export const UPDATE_AUTO_ACTION = "UPDATE_AUTO_ACTION";
export const CLOSE_ACCOUNT = "CLOSE_ACCOUNT";
export const UPDATE_AUTO_TOPUP = "UPDATE_AUTO_TOPUP";
export const UPDATE_TERMS_AND_CONDITIONS = "UPDATE_TERMS_AND_CONDITIONS";
export const UPDATE_EMAIL_NOTIFICATION = "UPDATE_EMAIL_NOTIFICATION";
export const UPDATE_INCLUDE_PDF = "UPDATE_INCLUDE_PDF";
export const UPDATE_SEND_SEPERATE = "UPDATE_SEND_SEPERATE";
export const UNPAUSE_ACCOUNT = "UNPAUSE_ACCOUNT";
export const PAUSE_ACCOUNT = "PAUSE_ACCOUNT";
