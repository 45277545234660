import React, { useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Container } from 'react-bootstrap';
import Service from './elements/Service';
import GlobalContext from 'context/globalContext';
import Styles from './serviceSelect.module.css';

const ServiceSelect = (props) => {
	const globalContext = useContext(GlobalContext);

	useEffect(() => {
		const calculate = async () => {
			let mailIds = globalContext.forward.response.mailIds.map((mail) => mail.mailId);
			globalContext.forward.calculate(mailIds, props.addressId);
		};
		calculate();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderServices = () => {
		if (!globalContext.forward.response.list) {
			return (
				<div className="text-center m-4">
					<FontAwesomeIcon icon="spinner" spin /> loading...
				</div>
			);
		}

		let serviceList = [];

		for (let i = 0; i < globalContext.forward.response.list.service.length; i++) {
			var services = globalContext.forward.response.list.service[i].services.sort((a, b) => a.price - b.price);

			var forwardingCompany = globalContext.forward.response.list.service[i].forwardingCompany;

			for (let i = 0; i < services.length; i++) {
				serviceList.push({ ...services[i], forwardingCompany: forwardingCompany });
			}
		}

		return (
			<React.Fragment>
				{serviceList.map((element) => (
					<Service
						key={element.code}
						name={element.name}
						price={element.price}
						hotsnailFee={element.forwardFee}
						deliveryTimes={element.deliveryTimes}
						options={element.options}
						code={element.code}
						onNext={props.onNext}
						forwardingCompany={element.forwardingCompany}
					/>
				))}
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<section className={Styles.mailSection}>
				<Container className={`${Styles.sericeContainer} pd-0`}>
					<Row className="mx-15">
						<h2 className={Styles.forwardTitle}>
							<FontAwesomeIcon icon="arrow-left" onClick={props.onBack} className="pointer" /> {'   '}
							Choose a service
						</h2>
					</Row>
					<Col xs={12}>
						<small style={{color: "red"}}><FontAwesomeIcon icon="exclamation-circle"></FontAwesomeIcon> Prices are indicative only and subject to change before postage</small>
					</Col>
					{renderServices()}
				</Container>
			</section>
			<div className="container notes">
				<div className="row mx-15">
					<small>
						Shipping times are only indicative. for more information view{' '}
						<a href="domestic-parcel.html" className="purple">
							australia post webpage
						</a>
					</small>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ServiceSelect;
