export const GET_FOLDER = "GET_FOLDER";
export const MARK_PENDING_SHRED = "MARK_PENDING_SHRED";
export const MARK_PENDING_SCAN = "MARK_PENDING_SCAN";
export const CANCEL_PENDING = "CANCEL_PENDING";
export const MARK_RETURN_TO_SENDER = "MARK_RETURN_TO_SENDER";
export const MAIL_MOVED_FOLDER = "MAIL_MOVED_FOLDER";
export const RECYCLE = "RECYCLE";
export const MARK_PENDING_RESCAN = "MARK_PENDING_RESCAN";
export const DOWNLOAD_BULK = "DOWNLOAD_BULK";
export const MAIL_HELD = "MAIL_HELD";
export const MARK_PENDING_FORWARD = "MARK_PENDING_FORWARD";
