import React, { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import SelectFolderItem from './SelectFolderItem';
import GlobalContext from 'context/globalContext';
import styles from "./selectFolderModal.module.css";
import { useTranslation } from 'react-i18next';

function SelectFolderModal(props) {
	const globalContext = useContext(GlobalContext);
	const [ t ] = useTranslation();

	const renderFolders = () => {
		let folders = globalContext.folders?.response?.list;
		
		if (folders){
			folders = folders.filter(a=> a.name !== props.selectedFolder.name); 
				return folders.map((folder) => 
           		<SelectFolderItem 
 	        		key={folder.folderId}
   	   		      	name={folder.name}
   	   	      		folderId={folder.folderId}
 		  	   	    onClick={(e) => props.onFolderSelected(folder.folderId)}
   		         />);
		}
	}

	return (
		<Modal show={props.show}>
			<Modal.Header>
				<Modal.Title>{t('Select Folder')}</Modal.Title>
			</Modal.Header>
			<Modal.Body className={styles.modalBody}>
				
				{!globalContext.folders?.response?.list?.length ? "No folders created. To create a folder click Add/Delete Folder" : (
				<React.Fragment>
					{(props.selectedFolder.name !== "Inbox") && 
					<SelectFolderItem 
						key={-1}
						name="Inbox"
						folderId={-1}
						onClick={(e) => props.onFolderSelected(-1)}
            		/>}
				{renderFolders()}
				</React.Fragment>)}

				</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={props.onClose}>{t('Cancel')}</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default SelectFolderModal;
