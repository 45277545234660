import { useReducer } from "react";
import folderActions from "./foldersActions";
import folderReducer from "./foldersReducer";

export default function useMail() {
  const [folders, folderDispatch] = useReducer(folderReducer, {});
  const { getFolders, addFolder, deleteFolder } = folderActions(folderDispatch);

  return {
    response: folders,
    getFolders,
    addFolder,
    deleteFolder
  };
}
