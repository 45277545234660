import React, { useState, useEffect } from 'react';
import { Col, Row, ProgressBar, Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import Styles from './photoVerification.module.css';
import { useTranslation } from 'react-i18next';


const PhotoVerificationUpload = (props) => {
	const { t  } = useTranslation();

	const [ uploadProgress, setUploadProgress ] = useState(0);
	const [ uploading, setUploading ] = useState(false);

	useEffect(
		() => {
			if (!props.base64) {
				setUploading(false);
				setUploadProgress(0);
			}
			// eslint-disable-next-line
		},
		[ props.photoSide ]
	);

	const onDrop = async (acceptedFiles) => {
		setUploading(true);
    await props.onUpload(acceptedFiles, props.photoSide, (progressEvent) => {
			let progress = progressEvent.loaded / progressEvent.total * 100;
			if (progress > uploadProgress) {
				setUploadProgress(progress);
			}
    });
    setUploading(false);
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/jpeg, image/png' });

	return (
		<Row>
			<Col xs={12}>
				{(props.photoSide === 'front' || props.photoSide === 'back') && (
					<h4 className="info-text" dangerouslySetInnerHTML={{ __html: t('Upload the <b>' + props.photoSide + '</b> of a government issued identification<br />')}}>
					</h4>
				)}
				{props.photoSide === 'selfie' &&
					//If self, check to see that the verification code has downloaded
					(props.globalContext.verification.response &&
					props.globalContext.verification.response.verificationCode ? (
						<h4 className="info-text">
							{t('Upload selfie holding a handwritten note with')}{' '}
							{props.globalContext.verification.response.verificationCode}
							<br />
							<small>({t('Ensure both your face and the note is visible')})</small>
						</h4>
					) : (
						//verification hasnt downloaded, wait for it
						<h4>{t('Loading')}..</h4>
					))}
				{!uploading &&
				  !props.base64 && (
					<React.Fragment>
						<div {...getRootProps()} className={Styles.dropzone}>
							<input {...getInputProps()} />
							{isDragActive ? (
								<p>{t('Drop the files here')} ...</p>
							) : (
								<p className="dropzone-area mt-4" dangerouslySetInnerHTML={{__html: t('Drop files here or click to upload<br /><small>or click to choose file</small>') }}>
								</p>
							)}
						</div>
					</React.Fragment>
				)}
				{uploading &&
				uploadProgress < 100 && (
					<div className={Styles.progressbar}>
						<ProgressBar animated now={uploadProgress} />
					</div>
				)}
				{props.base64 && (
					<div>
						<img className={Styles.idPreview} src={props.base64} alt="uploaded-preview" />
            <Button 
                variant="danger" 
                size="sm" 
                className={Styles.deleteButton}
                onClick={props.onDelete}>
							{t('Delete')}
						</Button>
					</div>
				)}
			</Col>
		</Row>
	);
};

export default PhotoVerificationUpload;
