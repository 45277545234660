import axios from 'axios';
import { GET_LOCATIONS, GET_USER_LOCATIONS } from './locationActionTypes';

export default (dispatch) => {
	const getAll = async () => {
		let locations = await _getAll();
		dispatch({
			type: GET_LOCATIONS,
			payload: locations
		});
	};

	const getUserLocations = async () => {
		var locations = await _getUserLocations();
		dispatch({
			payload: locations,
			type: GET_USER_LOCATIONS
		})
	};


	const saveLocations = async (locationIds) => {
		await _saveLocations(locationIds);
	};

	return { getAll, saveLocations, getUserLocations };
};

const _getUserLocations = async () => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/Account/GetLocations`,
			method: 'GET',
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((response) => {
				console.log('Error getting account', response);
				reject();
			});
	});	
}


const _saveLocations = async (locationIds) => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/Account/AddLocations`,
			method: 'POST',
			data: locationIds,
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((response) => {
				console.log('Error adding Locations', response);
				reject();
			});
	});
};

const _getAll = async () => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/Location/GetAll?webpage=${window.webpageDetails.webpageName}`,
			method: 'GET',
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((response) => {
				console.log('Error getting location', response);
				reject();
			});
	});
};
