import React, { useContext, useEffect, useMemo } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import GlobalContext from 'context/globalContext';
import * as autoAction from 'enums/autoAction';
import styles from './actions.module.css';
import { useTranslation } from 'react-i18next';


const Actions = (props) => {
	const globalContext = useContext(GlobalContext);
	const { t } = useTranslation();

	useEffect(() => {
		if (!globalContext?.plan?.response?.plans) {
			globalContext.plan.getAll();
		}
	}, []);

	const currentPlan = useMemo(() => {
		if (globalContext?.plan?.response?.plans && globalContext?.account?.response?.account?.planId) {
			return globalContext?.plan?.response?.plans.filter(a => a.planId === globalContext?.account.response.account.planId)[0];

		}
	}, [globalContext?.plan?.response?.plans, globalContext?.account?.response?.account?.planId])

	const onToggleAction = async (action) => {
		if (globalContext.account.response.account.autoAction !== action) {
			globalContext.account.updateAutoAction(action);
		}
	};

	return (
		<div className="mt-2">
			<Row>
				<Col xs={12}>
					<h1>{t('Automatic Action')}</h1>
					<small>{t('When we receive your mail, we can either scan the envelope or open and scan the contents. If you choose to scan the envelope, we we will open and scan it by request')}</small>
				</Col>
			</Row>
			{currentPlan && <>
				<Row>
					<Col xs={12}>
						<Form.Group controlId="openAndScan" style={{ cursor: "pointer" }} >
							<Form.Check
								type="radio"
								label={t('Open and scan')}
								className={styles.checkboxLabel}
								checked={globalContext.account.response.account.autoAction === autoAction.OpenAndScan}
								onChange={(e) => onToggleAction(autoAction.OpenAndScan)}
								disabled={currentPlan.parcelForwardOnly}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<Form.Group controlId="ScanEnvelope" style={{ cursor: "pointer" }}>
							<Form.Check
								type="radio"
								label={t('Scan Envelope')}
								className={styles.checkboxLabel}
								checked={globalContext.account.response.account.autoAction !== autoAction.OpenAndScan}
								onChange={(e) => onToggleAction(autoAction.ScanEnvelope)}
							/>
						</Form.Group>
					</Col>
				</Row>
			</>}
		</div>
	);
};
export default Actions;
