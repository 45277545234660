import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Checkbox } from 'pretty-checkbox-react';
import Styles from '../forwardLog.module.css';

function PendingForwardItem(props) {
	const [ checked, setChecked ] = useState(false);

	const onChange = (e) => {
		setChecked(e.target.checked);
		props.onChange(e.target.checked);
	};

	return (
		<Row className={Styles.forwardItem}>
			<Col xs={1}>
				<Checkbox
					shape="round"
					color="primary"
					icon={<i className="mdi mdi-check" />}
					animation="jelly"
					checked={checked}
					onChange={onChange}
				/>
			</Col>
			<Col xs={3} className={Styles.logRow}>
				<p className="text-dark font-weight-bold mb-0">{props.forward.address.street}</p>
				<p className="mb-0">
					{props.forward.address.city} {props.forward.address.postcode}
				</p>
				<p className="mb-0">{props.forward.address.state}</p>
				{props.forward.address.country &&
				props.forward.address.country.toLowerCase() !== 'australia' && (
					<p className="mb-0">{props.forward.address.country}</p>
				)}
			</Col>
			<Col xs={3} className={Styles.logRow}>
				<p className="text-dark font-weight-bold mb-0">{props.forward.service}</p>
				<p className="mb-0">{props.forward.numberOfItems} Item(s)</p>
			</Col>
			<Col xs={5} className={Styles.logRow}>

      {window.webpageDetails.showOurFee && 
      <>
      <p className="text-dark font-weight-bold mb-0">
					Courier Service Fee: ${props.forward.ausPostFee.toFixed(2)}
				</p>
			      <p className="mb-0">
					  	  {window.webpageDetails.webpageName} Fee: ${props.forward.hotsnailFee.toFixed(2)}
				  	</p>
        </>}
				    <p className="mb-0">
				  	  Total: $
  					  {(props.forward.ausPostFee + props.forward.hotsnailFee).toFixed(2)}
				  </p>
        
			</Col>
		</Row>
	);
}

export default PendingForwardItem;
