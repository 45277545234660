import {
	LOGIN
} from './loginActionTypes';

const LoginReducer = (state, action) => {
	switch (action.type) {
		default:
			return state;
	}
};

export default LoginReducer;