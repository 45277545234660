import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function ReturnToSenderModal(props) {
	const [ reason, setReason ] = useState('');
	const [ saving, setSaving ] = useState(false);

	const saveReturnToSender = async () => {
		if (!saving) {
            setSaving(true);
            await props.saveReturnToSender(reason);
			setSaving(false);
			props.setShowReturnToSender(false);
		}
    };
    
	return (
		<Modal show={props.show}>
			<Modal.Header>
				<Modal.Title>Return to sender</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>Why would you like this item returned to sender?</p>
				<p>
					<textarea className="form-control" value={reason} onChange={(e) => setReason(e.target.value)} />
				</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={saveReturnToSender}>
					{saving ? <FontAwesomeIcon icon="spinner" spin /> : 'Confirm'}
				</Button>
				<Button variant="primary" onClick={() => props.setShowReturnToSender(false)}>
					Cancel
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default ReturnToSenderModal;
