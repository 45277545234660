import React, { useContext, useEffect, useState } from 'react';
import PaymentForm from 'components/members/accountSettings/elements/PaymentForm';
import { Col, Row, Button } from 'react-bootstrap';
import GlobalContext from 'context/globalContext';
import styles from './chooseAddress.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function PaymentWrapper(props) {
	const [ showNext, setShowNext ] = useState(false);
	const globalContext = useContext(GlobalContext);
	const [ loading, setLoading ] = useState(false);
	const showNextButton = showNext || globalContext.account.response.account.activePayment;
	const [ t ] = useTranslation();

	useEffect(() => {
		if (!globalContext.plan.response.plans) {
			globalContext.plan.getAll();
		}
	}, []);

	const onNext = async () => {
		if (!loading) {
			setLoading(true);
			await globalContext.account.registrationComplete();
			props.onSaved();
			setLoading(false);
		}
	};

	if (!globalContext.plan.response.plans) {
		return (
			<div className="text-center">
				<FontAwesomeIcon icon="spinner" spin />
			</div>
		);
	}

	return (
		<React.Fragment>
			<PaymentForm
				afterSave={() => {
					setShowNext(true);
					onNext();
				}}
				signup={true}
			/>
			<Row className="mt-3">
				<Col xs={12}>
					<Button variant="dark" className={styles.backButton} onClick={props.onBack}>
						{t('Back')}
					</Button>
					{showNextButton && (
						<Button className={styles.saveButton} onClick={onNext}>
							{loading ? <FontAwesomeIcon icon="spinner" spin /> : t('Finish') }
						</Button>
					)}
				</Col>
			</Row>
		</React.Fragment>
	);
}

export default PaymentWrapper;
