import axios from "axios";
import {
  GET_SERVICES,
  SET_MAILIDS,
  GET_LOG,
  CANCEL_FORWARD
} from "./forwardActionTypes";

export default dispatch => {
  const setMailIds = mailIds => {
    dispatch({
      type: SET_MAILIDS,
      payload: mailIds
    });
  };

  const getLog = async () => {
    let log = await _getLog();
    dispatch({
      type: GET_LOG,
      payload: log
    });
  };

  const calculate = async (mailIds, addressId) => {
    let response = await _calculate(mailIds, addressId);
    dispatch({
      type: GET_SERVICES,
      payload: {
        service: response.data
      }
    });
  };

  const cancelForward = async (forwardIdList) => {
    await _cancelForward(forwardIdList);
    dispatch({
      type: CANCEL_FORWARD,
      payload: forwardIdList
    });
  };

  return { calculate, setMailIds, getLog, cancelForward };
};

const _cancelForward = async (forwardIdList) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/forward/cancel`,
      method: "DELETE",
      data: forwardIdList,
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(response => {
        console.log("Error getting folder list", response);
        reject();
      });
  });
};

const _getLog = async () => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/forward/log`,
      method: "GET",
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(response => {
        console.log("Error getting folder list", response);
        reject();
      });
  });
};

const _calculate = async (mailIds, addressId) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/forward/Calculate`,
      method: "POST",
      data: {
        addressId: parseInt(addressId),
        mailIds
      }
    })
      .then(response => {
        resolve(response);
      })
      .catch(response => {
        console.log("Error getting folder list", response);
        reject();
      });
  });
};
