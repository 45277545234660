import { useState } from "react";

export default function useDialog() {
  const [dialog, setDialog] = useState({visible: false, title: "", body: "", onConfirm: null, onCancel: null});

  return {
      response: dialog,
      setDialog
  };
}
