import React, { useContext, useState, useCallback } from 'react';
import GlobalContext from 'context/globalContext';
import TopNav from 'components/members/topnav';
import { Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Settings from './elements/Settings';
import Addresses from './elements/Addresses';
import Security from './elements/Security';
import CloseAccount from './elements/CloseAccount';
import PauseAccount from './elements/PauseAccount';
import Verification from './elements/Verification';
import PaymentForm from './elements/PaymentForm';
import OnceOffPayment from './elements/OnceOffPayment';
import ChoosePlanWrapper from './elements/ChoosePlanWrapper';
import AdditionalNames from './elements/AdditionalNames';
import Notifications from './elements/Notifications';
import AuditLog from './elements/AuditLog';
import Transactions from './elements/Transactions';
import StorageMetrics from './elements/StorageMetrics';
import Actions from './elements/Actions';
import SideBarStyles from 'styles/sidebar.module.css';
import Styles from './accountSettings.module.css';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const AccountSettings = (props) => {
	const globalContext = useContext(GlobalContext);
	const [ showLeftNavInMobileView, setShowLeftNavInMobileView ] = useState(false);
	const [ navigation, setNavigation ] = useState(props.page || globalContext.accountSettings.response.navigation);
	globalContext.events.subscribe('mobileMenuClick', (e) => setShowLeftNavInMobileView(!showLeftNavInMobileView));
	const { t, i18n } = useTranslation();

	const onPageChange = (page) => {
		setNavigation(page);
		setShowLeftNavInMobileView(false);
	};

	const onPaymentUpdated = () => {
		alert('Payment updated');
	};

	const onLanguageChange = useCallback(
		(lng) => {
			Cookies.set('locale', lng);
			i18n.changeLanguage(lng);
		},
		[ i18n ]
	);


	const renderPage = () => {
		if (globalContext.accountSettings.response.navigation !== navigation) {
			globalContext.accountSettings.setNav(navigation);
		}

		switch (navigation) {
			case 'account':
				return (
					<Settings
						history={props.history}
						showClosed={true}
						setLanguage={onLanguageChange}
					/>
				);
			case 'actions':
				return <Actions history={props.history} />;
			case 'addresses':
				return <Addresses history={props.history} />;
			case 'security':
				return <Security history={props.history} />;
			case 'verification':
				return <Verification history={props.history} />;
			case 'notifications':
				return <Notifications history={props.history} />;
			case 'additional-names':
				return <AdditionalNames history={props.history} />;
			case 'payment-method':
				return <PaymentForm history={props.history} signup={false} afterSave={onPaymentUpdated} />;
			case 'choose-plan':
				return <ChoosePlanWrapper />;
			case 'once-off-payment':
				return <OnceOffPayment />;
			case 'transactions':
				return <Transactions />;
			case 'audit-log':
				return <AuditLog />;
			case 'storage':
				return <StorageMetrics />;
			case 'pause-account':
				return <PauseAccount />;
			case 'close-account':
				return <CloseAccount />;
			default:
				break;
		}
	};

	return (
		<React.Fragment>
			<TopNav history={props.history} />
			<div className={Styles.settingsWrapper}>
				<div
					className={`${SideBarStyles.sidebarMenu +
						(showLeftNavInMobileView ? ' ' + SideBarStyles.mobileOpen : '')} sidebarMenu-bg`}
				>
					<Nav className="flex-column">
						<Nav.Link
							onClick={() => onPageChange('account')}
							className={navigation === 'account' && 'active'}
						>
							<FontAwesomeIcon icon="user" />{t('Account')}
						</Nav.Link>
						<Nav.Link
							onClick={() => onPageChange('addresses')}
							className={navigation === 'addresses' && 'active'}
						>
							<FontAwesomeIcon icon="map-marked-alt" />{t('Addresses')}
						</Nav.Link>

						{window.webpageDetails.includeAutoActions && (
							<Nav.Link
								onClick={() => onPageChange('actions')}
								className={navigation === 'actions' && 'active'}
							>
								<FontAwesomeIcon icon="magic" />{t('Auto Actions')}
							</Nav.Link>
						)}
						<Nav.Link
							onClick={() => onPageChange('notifications')}
							className={navigation === 'notifications' && 'active'}
						>
							<FontAwesomeIcon icon="bell" />
							{t('Notifications')}
						</Nav.Link>

						<Nav.Link
							onClick={() => onPageChange('security')}
							className={navigation === 'security' && 'active'}
						>
							<FontAwesomeIcon icon="lock" />
							{t('Security & Login')}
						</Nav.Link>
						<Nav.Link
							onClick={() => onPageChange('verification')}
							className={navigation === 'verification' && 'active'}
						>
							<FontAwesomeIcon icon="camera" />
							{t('Verification')}
						</Nav.Link>
						{window.webpageDetails.includeAdditionalNames && (
							<Nav.Link
								onClick={() => onPageChange('additional-names')}
								className={navigation === 'additional-names' && 'active'}
							>
								<FontAwesomeIcon icon="users" />{t('Additional Names')}
							</Nav.Link>
						)}
						<Nav.Link
							onClick={() => onPageChange('payment-method')}
							className={navigation === 'payment-method' && 'active'}
						>
							<FontAwesomeIcon icon="credit-card" />{t('Payment Method')}
						</Nav.Link>
						<Nav.Link
							onClick={() => onPageChange('once-off-payment')}
							className={navigation === 'once-off-payment' && 'active'}
						>
							<FontAwesomeIcon icon="money-bill-wave" />{t('Manual Payment')}
						</Nav.Link>
						{window.webpageDetails.includeChoosePayments && (
							<Nav.Link
								onClick={() => onPageChange('choose-plan')}
								className={navigation === 'choose-plan' && 'active'}
							>
								<FontAwesomeIcon icon="clipboard-list" />
								{t('Choose Plan')}
							</Nav.Link>
						)}
						<Nav.Link
							onClick={() => onPageChange('transactions')}
							className={navigation === 'transactions' && 'active'}
						>
							<FontAwesomeIcon icon="file-invoice-dollar" />
							{t('Transactions')}
						</Nav.Link>
						<Nav.Link
							onClick={() => onPageChange('storage')}
							className={navigation === 'storage' && 'active'}
						>
							<FontAwesomeIcon icon="chart-pie" />
							{t('Storage Metrics')}
						</Nav.Link>

						<Nav.Link
							onClick={() => onPageChange('audit-log')}
							className={navigation === 'audit-log' && 'active'}
						>
							<FontAwesomeIcon icon="list-alt" />
							{t('Audit Log')}
						</Nav.Link>
						<Nav.Link
							onClick={() => onPageChange('pause-account')}
							className={navigation === 'pause-account' && 'active'}
						>
							<FontAwesomeIcon icon="pause-circle" />
							{globalContext.account.response.account.status !== 'On Hold' ? (
								t('Pause Account')
							) : (
								t('Unpause Account')
							)}
						</Nav.Link>
						<Nav.Link
							onClick={() => onPageChange('close-account')}
							className={navigation === 'close-account' && 'active'}
						>
							<FontAwesomeIcon icon="times-circle" />
							{t('Close Account')}
						</Nav.Link>
					</Nav>
				</div>

				<div id="setting-wrapper" className={Styles.settings}>
					<div className={Styles.settingsInnerWrapper}>{renderPage()}</div>
				</div>
			</div>
		</React.Fragment>
	);
};
export default AccountSettings;
