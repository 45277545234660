import axios from 'axios';
import {
    GET_NAMES,
    ADD_NAME,
    DELETE_NAME
} from "./additionalNamesActionTypes";


export default dispatch => {
    const getNames = async() => {
        var names = await _getNames();
        dispatch({ type: GET_NAMES, payload: names });
    }

    const saveName = async(name) => {
        var response = await _saveName(name);
        if (response.success) {
            dispatch({ type: ADD_NAME, payload: response.object });
        }
        return response;
    }

    const deleteName = async(additionalId) => {
        var response = await _deleteName(additionalId);
        if (response.success) {
            dispatch({ type: DELETE_NAME, payload: additionalId });
        }
    }

    return { getNames, saveName, deleteName };
};

const _deleteName = async (additionalId) => {
    return new Promise((resolve, reject) => {
        axios({
                url: `/api/AdditionalName?additionalNameId=${additionalId}`,
                method: "DELETE",
            })
            .then(response => {
                resolve(response.data);
            })
            .catch(response => {
                console.log("Error getting address", response);
                reject();
            });
    });

}

const _saveName = async (name) => {
    return new Promise((resolve, reject) => {
        axios({
                url: `/api/AdditionalName?name=${encodeURIComponent(name)}`,
                method: "POST",
            })
            .then(response => {
                resolve(response.data);
            })
            .catch(response => {
                console.log("Error getting address", response);
                reject();
            });
    });
}

const _getNames = async () => {

    return new Promise((resolve, reject) => {
        axios({
                url: `/api/AdditionalName`,
                method: "GET",
            })
            .then(response => {
                resolve(response.data);
            })
            .catch(response => {
                console.log("Error getting address", response);
                reject();
            });
    });
}