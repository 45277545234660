export default {
	getFileNameFromContentDisposition: (contentDisposition) => {
		let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
		let matches = filenameRegex.exec(contentDisposition);
		if (matches != null && matches[1]) {
			let filename = matches[1].replace(/['"]/g, '');
			return filename;
		} else {
			return '';
		}
	}
};
