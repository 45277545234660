import { useReducer } from "react";
import storageActions from "./storageActions";
import storageReducer from "./storageReducer";

export default function useStorage() {
  const [storage, storageDispatch] = useReducer(storageReducer, {response: []});
  const { getMetrics } = storageActions(storageDispatch);

  return {
    response: storage,
    getMetrics
  };
}
