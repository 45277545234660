import mailStatusUtility from "./mailStatusUtility";

const mailActionUtility = {
  getFriendlyName: mailAction => {
    switch (mailAction) {
      case 0:
        return "Pending Scan";
      case 1:
        return "Pending Forward";
      case 4:
        return "Pending Rescan";
      default:
        return "";
    }
  },
  showScan: mailList => {
    for (let i = 0; i < mailList.length; i++) {
      let mailItem = mailList[i];
      let mailStatus = mailStatusUtility.getFriendlyName(mailItem.status);
        //Dont show scan if there is a pending action
        if (mailStatus === "Unopened" && !mailItem.mailActions){
          return true;
        }
    }
    return false;
  },
  showForwardShred: mailList => {
    let allowForward = false;
    for (let i = 0; i < mailList.length; i++) {
      let mailItem = mailList[i];
      let mailStatus = mailStatusUtility.getFriendlyName(mailItem.status);
      
      //Dont show forward or shred if there is a pending action
      if (mailItem.mailActions){
        return false;
      }

      switch (mailStatus) {
        case "Unopened":
        case "Opened and scanned":
          allowForward = true;
          break;
        default: 
          break;
      }
    }
    return allowForward;
  },
  showCancel: mailList => {
    for (let i = 0; i < mailList.length; i++) {
      if (mailList[i].mailActions) return true;
    }
    return false;
  },
  showOpenScan: mailList => {
    let allowForward = false;
    for (let i = 0; i < mailList.length; i++) {
      let mailItem = mailList[i];
      let mailStatus = mailStatusUtility.getFriendlyName(mailItem.status);
      switch (mailStatus) {
        case "Unopened":
        case "Opened and scanned":
          allowForward = true;
          break;
        default:
          allowForward = false;
      }
    }
    return allowForward;
  },
  showDownload: mailList => {
    return mailList.length > 0;
  }
};

export default mailActionUtility;
