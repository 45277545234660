import React from "react";
import PublicTopNav from "components/public/publicTopNav/PublicTopNav.jsx";
import { Container, Alert } from "react-bootstrap";

function LoginGuard(props) {
  return (
    <React.Fragment>
      <PublicTopNav history={props.history} />
      <Container>
        <Alert variant="secondary" className="mt-2">
          <p>Login Guard Verification Required</p>
          <p>
            We noticed that you're attempting to log in from an unauthenticated
            browser.
          </p>
          <p>
            Before you can access your account you will need to verify your
            browser. A link has been sent to your email, please open the link in
            this browser to complete browser verification.
          </p>
        </Alert>
      </Container>
    </React.Fragment>
  );
}

export default LoginGuard;
