import React from 'react';
import PublicTopNav from 'components/public/publicTopNav/PublicTopNav.jsx';
import { Container, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function RegistrationComplete(props) {
	const [ t ] = useTranslation();

	return (
		<React.Fragment>
			<PublicTopNav history={props.history} />
			<Container>
				<Alert variant="secondary" className="mt-2">
					<p>{t('Signup Complete')}</p>
					<p>
						{t('Thank you for registering. You will receive an email with your addresses, if you do not receive it shortly, check your junk folder.')}
					</p>
					<p>
						{t('While you can start sending us mail immediately, you will need to verify your identity through the simple automated process in your account before accessing or forwarding received mail.')}
					</p>
				</Alert>
			</Container>
		</React.Fragment>
	);
}

export default RegistrationComplete;
