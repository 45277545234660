import { useState, useEffect } from "react";

function useStripe(apiKey) {
    const [cardElements, setCardElements] = useState({});
    const [stripe, setStripe] = useState({});

    useEffect(() => {
        const initalize = () => {
            let stripe = global.Stripe(apiKey);
            setStripe(stripe);
            let elements = stripe.elements();
            let style = {
                base: {
                    fontFamily: 'Raleway, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '16px',
                    padding: '5px',
                    border: '1px solid black',
                    '::placeholder': {
                        color: '#aab7c4'
                    }
                },
                invalid: {
                    iconColor: '#fa755a'
                }
            };
            let cardNumber = elements.create('cardNumber', { style: style });
            cardNumber.on('focus', (e) => { setCardElements(cardElements => { return { ...cardElements, focused: 'number' } }) });
            cardNumber.on('change', (e, d) => setCardElements(cardElements => { return { ...cardElements, cardNumberChange: e } }));
            cardNumber.mount('#card-numbers');

            let cardExpiry = elements.create('cardExpiry', {
                style: style,
            });
            cardExpiry.on('focus', (e) => { setCardElements(cardElements => { return { ...cardElements, focused: 'expiry' } }) });
            cardExpiry.on('change', (e, d) => { setCardElements(cardElements => { return { ...cardElements, cardExpiryChange: e } }) });
            cardExpiry.mount('#card-expiry');

            let cardCvc = elements.create('cardCvc', {
                style: style,
            });
            cardCvc.on('focus', (e) => { setCardElements(cardElements => { return { ...cardElements, focused: 'cvc' } }) });
            cardCvc.mount('#card-cvc');

            setCardElements({ cardNumber, cardExpiry, cardCvc });
        }

        initalize();
        // eslint-disable-next-line 
    }, []);

    const unSubscribe = () => {
        
    }

    const createToken = async () => {
        return new Promise((resolve, reject) => {
            stripe.createToken(cardElements.cardNumber).then(function (result) {
                if (result.error) {
                    // Inform the user if there was an error.
                    // var errorElement = document.getElementById('card-errors');
                    // errorElement.textContent = result.error.message;
                    reject(result);
                } else {
                    // Send the token to your server.
                    resolve(result);
                }
            }
            )
        })
    };

    return [cardElements, createToken, unSubscribe];
}

export default useStripe;