import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfiniteScroll from 'react-infinite-scroller';
import Moment from "react-moment";
import {
    Table
} from "react-bootstrap";
import GlobalContext from 'context/globalContext';
import { useTranslation } from 'react-i18next';


const PAGE_SIZE = 20;

const AuditLog = (props) => {
    const globalContext = useContext(GlobalContext);
    const [hasMore, setHasMore] = useState(true);
    const { t  } = useTranslation();

    const renderAuditLog = () => {
        if (globalContext.audit.response?.auditLog) {
            return globalContext.audit.response?.auditLog.map(audit => (
                <tr key={audit.auditId}>
                    <td>
                        <Moment format="DD/MM/YYYY">{audit.dateTime}</Moment>
                    </td>
                    <td>
                        {audit.message}
                    </td>
                </tr>
            ));
        } else {
            return (
                <tr>
                    <td colSpan="2"><FontAwesomeIcon icon="spinner" spin /></td>
                </tr>
            );
        }
    }

    const onLoadMore = (newPage) => {
        const getAudit = async () => {
            setHasMore(false);
            var more = await globalContext.audit.getAudit(newPage, PAGE_SIZE);
            setHasMore(more);
        }
        getAudit();
    }

    return (
        <div className="mt-2 mb-2">
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>{t('Date')}</th>
                        <th>{t('Message')}</th>
                    </tr>
                </thead>
                <InfiniteScroll
                    pageStart={-1}
                    loadMore={onLoadMore}
                    hasMore={hasMore}
                    element="tbody"
                    loader={<tr key="loader"><td colSpan="2">Loading...</td></tr>}
                    getScrollParent={()=> document.getElementById("setting-wrapper")}
                    useWindow={false}
                >
                    {renderAuditLog()}
                </InfiniteScroll>
            </Table>
        </div>
    );
};

export default AuditLog;
