import IosListBoxOutline from "react-ionicons/lib/IosListBoxOutline";
import IosTrashOutline from "react-ionicons/lib/IosTrashOutline";
import IosSendOutline from "react-ionicons/lib/IosSendOutline";
import IosArchiveOutline from "react-ionicons/lib/IosArchiveOutline";

export default [
    { folderId: -4, name: "Inbox", icon: IosListBoxOutline },
    { folderId: -2, name: "Forward Log", icon: IosSendOutline },
    { folderId: -1, name: "Deleted Items", icon: IosTrashOutline },
    { folderId: -5, name: "All Stored Items", icon: IosArchiveOutline   }
  ];