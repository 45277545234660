import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';

function ConfirmModal(props) {
	const [ loading, setLoading ] = useState(false);
	const [ t ] = useTranslation();

	const onConfirm = async () => {
		setLoading(true);
		await props.onConfirm();
		setLoading(false);
	}
	return (
		<Modal show={props.show}>
			<Modal.Header>
				<Modal.Title>{props.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{props.body}</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={onConfirm}>
					{loading ? <FontAwesomeIcon icon="spinner" spin /> : t('Yes')}
				</Button>
				<Button variant="danger" onClick={props.onReject}>
					{t('No')}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default ConfirmModal;
