import axios from 'axios';
import { GET_FOLDERS, LOADING_FOLDERS, ADD_FOLDER, DELETE_FOLDER } from './foldersActionTypes';

export default (dispatch) => {
	const getFolders = async () => {
		dispatch({
			type: LOADING_FOLDERS
		});
		let response = await _getFolders();
		dispatch({
			type: GET_FOLDERS,
			payload: response.data
		});
	};

	const addFolder = async (folderName) => {
		return new Promise(async (resolve, reject) => {
			var folder = await _addFolder(folderName);
			dispatch({
				type: ADD_FOLDER,
				payload: folder
			});
			resolve();
		});
	};

	const deleteFolder = async (folderId) => {
		await _deleteFolder(folderId);
		dispatch({
			type: DELETE_FOLDER,
			payload: folderId
		});
	};

	return { getFolders, addFolder, deleteFolder };
};

const _deleteFolder = async (folderId) => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/Folder?folderId=${folderId}`,
			method: 'DELETE',
		})
			.then((response) => {
				resolve(response);
			})
			.catch((response) => {
				console.log('Error getting folder list', response);
				reject();
			});
	});
};

const _addFolder = async (name) => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/Folder?folderName=${name}`,
			method: 'POST',
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((response) => {
				console.log('Error getting folder list', response);
				reject();
			});
	});
};

const _getFolders = async () => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/Folder`,
			method: 'GET',
		})
			.then((response) => {
				resolve(response);
			})
			.catch((response) => {
				console.log('Error getting folder list', response);
				reject();
			});
	});
};
