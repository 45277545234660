import {
	GET_FOLDER,
	MARK_PENDING_SHRED,
	MARK_PENDING_SCAN,
	CANCEL_PENDING,
	MARK_RETURN_TO_SENDER,
	MAIL_MOVED_FOLDER,
	RECYCLE,
	MARK_PENDING_RESCAN,
	DOWNLOAD_BULK,
	MAIL_HELD,
	MARK_PENDING_FORWARD
} from './mailActionTypes';
import mailStatusUtility from 'utilities/mailStatusUtility';

const mailReducer = (state, action) => {
	switch (action.type) {
		case GET_FOLDER:
			var sortedList = action.payload.sort((a, b) => {
				return new Date(b.dateScanned) - new Date(a.dateScanned);
			});
			return { ...state, list: sortedList };
		case DOWNLOAD_BULK:
			return _downloadBulk(state, action);
		case MARK_PENDING_SHRED:
			return _markPendingShred(state, action);
		case MARK_PENDING_SCAN:
			return _markPendingScan(state, action);
		case CANCEL_PENDING:
			return _cancelPending(state, action);
		case MARK_RETURN_TO_SENDER:
			return _returnToSender(state, action);
		case MAIL_MOVED_FOLDER:
			return _mailMovedFolder(state, action);
		case RECYCLE:
			return _mailMovedFolder(state, action);
		case MARK_PENDING_RESCAN:
			return _pendingRescan(state, action);
		case MAIL_HELD:
			return {...state, mailHeld: action.payload}
		case MARK_PENDING_FORWARD:
			return _markPendingForward(state, action);
		default:
			return state;
	}
};

const _downloadBulk = (state, action) => {
	let mailList = [ ...state.list ];
	mailList = mailList.map((mail) => {
		if (action.payload.indexOf(mail.mailId) > -1) {
			mail.read = true;
		}
		return mail;
	});
	return { ...state, list: mailList };
}


const _pendingRescan = (state, action) => {
	let mailList = [ ...state.list ];
	mailList = mailList.map((mail) => {
		if (action.payload.mailId === mail.mailId) {
			return action.payload;
		}
		return mail;
	});
	return { ...state, list: mailList };
};

//Remove mail from the current folder
const _mailMovedFolder = (state, action) => {
	let mailList = [ ...state.list ];
	mailList = mailList.filter((mail) => action.payload.indexOf(mail.mailId) === -1);
	return { ...state, list: mailList };
};

const _returnToSender = (state, action) => {
	let mailList = [ ...state.list ];
	mailList = mailList.map((mail) => {
		let newItem = mail;
		if (action.payload.indexOf(mail.mailId) > -1) {
			newItem.mailActions = 3;
		}
		return newItem;
	});
	return { ...state, list: mailList };
};

const _cancelPending = (state, action) => {
	let mailList = [ ...state.list ];
	mailList = mailList.map((mail) => {
		let newItem = mail;
		if (action.payload.indexOf(mail.mailId) > -1) {
			newItem.mailActions = null;
		}
		return newItem;
	});
	return { ...state, list: mailList };
};

const _markPendingScan = (state, action) => {
	let mailList = [ ...state.list ];
	mailList = mailList.map((mail) => {
		let newItem = mail;
		if (action.payload.indexOf(mail.mailId) > -1) {
			newItem.mailActions = 0;
		}
		return newItem;
	});
	return { ...state, list: mailList };
};

const _markPendingForward = (state, action) => {
	let mailList = [ ...state.list ];
	mailList = mailList.map((mail) => {
		let newItem = mail;
		if (action.payload.indexOf(mail.mailId) > -1) {
			newItem.mailActions = 1;
		}
		return newItem;
	});
	return { ...state, list: mailList };
};

const _markPendingShred = (state, action) => {
	let mailList = [ ...state.list ];
	mailList = mailList.map((mail) => {
		let newItem = mail;
		if (action.payload.indexOf(mail.mailId) > -1) {
			newItem.status = 6;
		}
		return newItem;
	});
	return { ...state, list: mailList };
};

export default mailReducer;
