import {
    GET_FOLDERS,
    LOADING_FOLDERS,
    ADD_FOLDER,
    DELETE_FOLDER
} from "./foldersActionTypes";

const mailReducer = (state, action) => {
    switch (action.type) {
        case LOADING_FOLDERS:
            return { ...state, loading: true}
        case GET_FOLDERS:
            return { ...state, loading: false, list: action.payload } 
        case ADD_FOLDER:
            return addFolder(state, action);
        case DELETE_FOLDER:
            return deleteFolder(state, action);
        default:
            return state;
    }
};

const deleteFolder = (state, action) => {
    let list = [...state.list];
    list = list.filter(item => item.folderId !== action.payload);
    return {...state, list}
}

const addFolder = (state, action) => {
    let list = [...state.list];
    list.push(action.payload);
    return { ...state, list};
}

export default mailReducer;
